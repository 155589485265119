import React from "react";
import styled from 'styled-components';
import { SvgProps } from "../../../components/Svg/types";

const StyleDiv = styled.div`
  padding-right:5px;
`
const Icon: React.FC<SvgProps> = (props) => {
  return (<StyleDiv>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M5.766 20.226v.139a1.133 1.133 0 01-1.134 1.133h-1.7A1.133 1.133 0 011.8 20.365v-6.231A1.134 1.134 0 012.932 13h1.7a1.133 1.133 0 011.104.873c.75-.27 1.627-.593 1.895-.715.228-.104.49-.139.71-.152.225-.01.451-.007.677.012.252.02.503.052.752.095l.013.002.004.001h.002l.01.002 3.792.85.023.008.006.002.026.008c.164.048.329.09.495.127.326.07.737.137 1.12.137.353 0 .72.132.914.49.168.307.129.645.067.879-.08.29-.241.597-.447.857.278-.105.582-.229.908-.363l.07-.028c.92-.378 2.01-.826 2.973-.984 1.1-.182 1.899.217 2.262.925.323.63.248 1.457-.239 1.955a3.006 3.006 0 01-.494.377c-.2.13-.445.276-.717.43-.626.352-1.26.688-1.902 1.01-1.344.683-2.753 1.346-3.2 1.51-.52.19-1.077.207-1.613.18-.323-.02-.646-.048-.968-.083a30.971 30.971 0 00-.709-.065c-1.092-.083-1.931-.35-2.703-.595l-.094-.03c-.798-.253-1.54-.478-2.524-.517a16.07 16.07 0 00-1.08.027zM8.1 14.189a.988.988 0 01.305-.051c.16-.01.344-.004.524.01.215.017.429.044.641.081h.002l3.722.835.025.008c.192.056.385.106.58.148.332.073.776.149 1.23.162-.041.109-.096.212-.162.307-.091.132-.19.233-.272.292-.236.169-.426.239-.542.269a.753.753 0 01-.12.023h-.012l-3.039-.037-.108 1.125.101-.558-.1.558.03.005.086.016.315.055c.265.047.628.109 1.024.174.778.127 1.717.267 2.25.3.368.022.796-.091 1.195-.224.415-.14.885-.331 1.358-.525.973-.4 1.964-.805 2.796-.943.72-.12.977.144 1.069.323.132.258.06.544-.041.647a2.16 2.16 0 01-.3.219c-.173.111-.397.246-.66.395-.61.342-1.229.67-1.854.985-1.355.687-2.704 1.318-3.078 1.455-.303.111-.678.138-1.164.113a16.471 16.471 0 01-.859-.073 29.526 29.526 0 00-.791-.073c-.959-.072-1.688-.304-2.47-.552l-.07-.023c-.81-.257-1.675-.524-2.82-.57a15.99 15.99 0 00-1.126.025v-4.024c.796-.285 1.976-.713 2.335-.877zm1.584-.515l.104-.557-.104.557zm-5.053.46h-1.7v6.23h1.7v-6.23zM15 10.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm0 1.5a3 3 0 100-6 3 3 0 000 6zM9.5 6.5a1 1 0 100-2 1 1 0 000 2zm0 1.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z"
        clipRule="evenodd"
      />
    </svg>
     </StyleDiv>
  );
};

export default Icon;
