import styled from 'styled-components'

const Rows = styled.div<{
  margin?: string
}>`
  display: flex;
  margin: ${({ margin }) => margin};
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; 
  
`
export default Rows
