import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { ButtonMenu, ButtonMenuItem, NotificationDot, Text, Flex, Button } from '@pancakeswap/uikit'
import useToast from 'hooks/useToast'
import { useWeb3React } from '@web3-react/core'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'contexts/Localization'
import useExchange from 'hooks/useExchangeRate';
import { useMowaMinningNftContract } from 'hooks/useContract'
import { harvestMiningNft } from 'utils/calls'
import { sleep } from 'utils/utilsFun'
import userMiningInf from '../hooks/useMiningInf'
import useMowaEarn from '../hooks/useMowaEarn'

interface props {
  data?: any
  pid?: number,
  onRefresh?: () => void
}

const IS_CHECK_ENV = process.env.REACT_APP_ENV;

const TopContent: React.FC<props> = ({ data, pid, onRefresh }) => {

  const { t } = useTranslation()
  const { priceChange } = useExchange()
  const { toastSuccess, toastError } = useToast()
  const [loading, setLoading] = useState(false)
  const moniMinningNftContract = useMowaMinningNftContract()
  const [cookies] = useCookies(['auth']);
  const userLogin = cookies.auth
  const { account } = useWeb3React()
  const [valueHash, setValueHash] = useState(null)

  const { mowaEarn } = useMowaEarn(pid)
  const { valueTopInf } = userMiningInf(pid)

  // Total Staked Amount :poolInfoNFT.totalAmount
  // Total Mining Hashrate: poolInfoNFT.totalHasrate
  // My Staked Amount: userInfo.amount
  // My Mining Hashrate: userInfo.hashrate
  // Mowa Earned: pendingRewards
  // 1000 Mining Hashrate/24H to get: Calc

  const totalAmount = valueTopInf && (valueTopInf?.poolInfoNFT.totalAmount.toString() / 10 ** 18).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0;
  const myTotalHashrate = valueTopInf && valueTopInf?.poolInfoNFT?.totalHashRate.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0;
  const myStakeAmount = data && (data?.userInfo.amount.toString() / 10 ** 18).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0;
  const myHashrate = data && data?.userInfo.hashRate.toString() || 0;
  const mowaEarned = mowaEarn && (mowaEarn?.pendingRewards[0].toString() / 10 ** 18).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0;
  const calcTotal = valueTopInf && (valueTopInf?.calc[0].toString() / 10 ** 18).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0;
  const mowaEarnedChange = mowaEarn && ((mowaEarn?.pendingRewards[0].toString() / 10 ** 18) * priceChange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  const calcChange = valueTopInf && ((valueTopInf?.calc[0].toString() / 10 ** 18) * priceChange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  const GenesisFarming = valueTopInf && (valueTopInf?.poolInfoNFT?.totalReward.toString() / 10 ** 18).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  const GenesisFarmingEx = valueTopInf && (valueTopInf?.poolInfoNFT?.totalReward.toString() / 10 ** 18 * priceChange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');


  const onHarverstAll = async () => {
    if (!userLogin || !account) return
    setLoading(true)
    try {
      const isStatus = await harvestMiningNft(moniMinningNftContract, pid)
      if (isStatus) {
        toastSuccess('Success', 'Harvest minning success!')
        // onRefresh()
        setLoading(false)
        setValueHash(0)
      } else {
        toastError('Error', 'Harvest minning error!')
        setLoading(false)
      }
    } catch (error) {
      toastError('Error', 'Harvest minning error!')
      setLoading(false)
    }
  }


  return (
    <Wrapper>
      <div className="cell">
        <div className="cell__top">
          <Text color="#fff" fontSize="16px" bold>{t('MOWA Total Mining Pool')}</Text>
          <TextNumberPool>{IS_CHECK_ENV !== 'production' ? GenesisFarming : '70,000' || 0}</TextNumberPool>
          <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" fontWeight="400">≈ ${IS_CHECK_ENV !== 'production' ? GenesisFarmingEx : '40,848.72' || 0}</Text>
        </div>
        <FlexBottom>
          <div className="cell-bottom__left">
            <Text color="#fff" fontSize="24px" bold mb="5px">{totalAmount || 0}</Text>
            <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" fontWeight="400">Total Staked Amount</Text>
          </div>
          <div className="cell-bottom__right">
            <Text color="#fff" fontSize="24px" bold mb="5px">{myStakeAmount || 0}</Text>
            <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" fontWeight="400">My Staked Amount</Text>
          </div>
        </FlexBottom>
      </div>
      <div className="cell">
        <Flex justifyContent="space-between" alignItems="flex-end">
          <div className="cell__top">
            <Text color="#fff" fontSize="16px" fontWeight="400">{t('MOWA earned')}</Text>
            {
              IS_CHECK_ENV !== 'production' ? <>
                <Text color="#fff" fontSize="24px" bold mt="5px" mb="5px">{valueHash === 0 ? valueHash : mowaEarned}</Text>
                <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" fontWeight="400">≈ ${valueHash === 0 ? valueHash : mowaEarnedChange || 0}</Text>
              </> :
                <>
                  <Text color="#fff" fontSize="24px" bold mt="5px" mb="5px">0</Text>
                  <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" fontWeight="400">≈ $0</Text>
                </>
            }
          </div>
          {
            IS_CHECK_ENV !== 'production' ? <GrayButton disabled={valueHash === 0 || loading || mowaEarned <= 0} onClick={onHarverstAll} >
              {loading && <LoadingIcon src="/images/loading.gif" alt="loading" />}
              {t('Harvest all')}
            </GrayButton> :
              <GrayButton  >
                {loading && <LoadingIcon src="/images/loading.gif" alt="loading" />}
                {t('Harvest all')}
              </GrayButton>
          }
        </Flex>
        <FlexBottom>
          <div className="cell-bottom__left">
            <Text color="#fff" fontSize="24px" bold mb="5px">{myTotalHashrate || 0}</Text>
            <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" fontWeight="400">Total Mining Hashrate</Text>
          </div>
          <div className="cell-bottom__right">
            <Text className="icon-fire" color="#fff" fontSize="24px" bold mb="5px">{myHashrate || 0}</Text>
            <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" fontWeight="400">My Mining Hashrate</Text>
          </div>
        </FlexBottom>
      </div>
      <div className="cell">
        <Flex justifyContent="space-between" alignItems="flex-end" />
        <FlexBottom>
          <div className="cell-bottom__left">
            <Flex alignItems="baseline">
              <Text color="#fff" fontSize="24px" bold mb="5px">{calcTotal || 0}</Text>
              <Text ml="10px" color="rgba(255, 255, 255, 0.6)" fontSize="12px" fontWeight="400">≈ ${calcChange || 0}</Text>
            </Flex>
            <Text color="rgba(255, 255, 255, 0.6)" fontSize="12px" fontWeight="400">1000 Mining Hashrate/24H to get</Text>
          </div>
        </FlexBottom>
      </div>
    </Wrapper>
  )
}
export default TopContent

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);  
  grid-column-gap: 30px;
  background: #1B212F;
  padding: 30px;
  border-radius:20px;
  
  div.cell{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    &__top{
      margin-bottom: 20px;
    }
    .cell-bottom__left {
      margin-right:20px;
    }
    .icon-fire {
      background: url(/images/icon-fire.png) 0 7px no-repeat;
      padding-left: 22px;
    }
  }

  @media screen and (max-width: 425px){    
    grid-template-columns: repeat(1, 1fr);
    div.cell{
      margin-bottom:30px;
      &:nth-child(3){
        margin-bottom: 0;
      }
    }
  }
`
const TextNumberPool = styled(Text)`
  font-size:24px;
  font-weight:700;
  background: #BF893D;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(40%, #BF893D), to(#FFD493));
  background: linear-gradient(to top, #BF893D 40%, #FFD493 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`
const FlexBottom = styled(Flex)`
  padding:15px;
  align-items:center;
  background-color:#10131C;
  border-radius:10px;  
`

const GrayButton = styled(Button) <{ disabled: boolean }>`
  color: rgba(255, 255, 255);
  background-color: ${(disabled) => (!disabled ? '#2C3145!important' : 'linear-gradient(360deg,#BF893D 0%,#FFD493 137.5%);')};
  border:none;
  box-sizing: border-box;
  border-radius: 10px;
  width: 147px;
  height: 40px;
  font-size:14px;
  font-weight:bold;
  box-shadow:none;
  transition: all .2s linear;
  margin-bottom: 20px;
  position:relative;
  &:hover {  
    color:#fff;
    background-color: linear-gradient(360deg,#BF893D 0%,#FFD493 137.5%);
    cursor: pointer;
    transition: all .2s linear;
  }  
  &:focus {  
    margin-top:2px;
  }  
`
const LoadingIcon = styled('img')`
  width: 20px;
  height: 20px;
  position: absolute;
  left:10px;
`