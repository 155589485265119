import { Button, InjectedModalProps, Modal, Text } from '@pancakeswap/uikit'
import useCountdown from 'hooks/useCountdown'
import useToast from 'hooks/useToast'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'
import { postApi } from "../../../utils/apiHelper"


const ForgotModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const [cookies] = useCookies(['auth']);
  const userLogin = cookies.auth
  const [isShowPass, setIsShowPass] = useState(false)
  const [isShowRePass, setIsShowRePass] = useState(false)
  const [isShowError, setIsShowError] = useState(false)
  
  const [email, setEmail] = useState('')
  const [textError, setTextError] = useState(null)
  const [emailErr, setEmailErr] = useState('')
  const [isESuc, setIsESuc] = useState(false)
  const { toastSuccess } = useToast()
  const [isProcess, setIsProcess] = useState(false)
  // const [timer, setTimer] = useState(180)
  const { minutes, seconds, onReset } = useCountdown(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (userLogin) {
      setEmail(userLogin?.data.email)
    }
  }, [userLogin])

  const handleShowPass = () => {
    setIsShowPass(!isShowPass)
  }
  const handleShowRePass = () => {
    setIsShowRePass(!isShowRePass)
  }

  const onSubmitPassword = e => {
    setEmailErr('')
    e.preventDefault();
    const params = new FormData(e.target);
    const _email = params.get('email')
    const _otp = params.get('otp')
    const password = params.get('password')
    const rePassword = params.get('rePassword')

    const msgErr = []
    if (_email === '') {
      msgErr.push({ code: "The email field is required." })
    } if (_otp === '') {
      msgErr.push({ code: "The otp field is required." })
    } if (password === '') {
      msgErr.push({ code: "The password field is required." })
    } if (rePassword === '') {
      msgErr.push({ code: "The password confirmation field is required." })
    }

    if (msgErr.length === 0) {
      setLoading(true)
      const data = {
        "email": _email,
        "otp": _otp,
        "password": password,
        "password_confirmation": rePassword
      }
      postApi('/password/reset', data).then(resp => {
        setLoading(false)
        setIsShowError(false)
        toastSuccess('Success', 'Update Password success');
        onDismiss()
        // document.getElementById('loginModal').click();
      }).catch(err => {
        setLoading(false)
        if (err.response.status === 422) {
          setIsShowError(true)
          setTextError(err.response.data.errors)
        }
      })
    } else {
      setIsShowError(true)
      setTextError(msgErr)
    }
  }

  const onSendMail = () => {
    if (email === '') {
      setEmailErr('The email field is required.')
    } else {
      setIsProcess(true)
      const params = {
        email
      }
      postApi('/password/email', params).then((resp) => {
        setEmailErr('')
        onReset(180)
        setIsESuc(true)
      }).catch(e => {
        setEmailErr(e.response?.data?.message ? e.response?.data?.message : e.response.data?.errors[0].code)
        setIsESuc(false)
      }).finally(() => {
        setIsProcess(false)
      })
    }
  }

  const onShowError = () => {
    if (!isShowError) return <></>
    return (
      <ErrorDiv className='show'>
        {
          textError?.length > 0 ? textError?.map(text => {
            return (
              <Text mb="5px" as="p" fontSize="12px" color="red">{text.code}</Text>
            )
          }) :
            <Text mb="5px" as="p" fontSize="12px" color="red">{textError?.code}</Text>
        }
      </ErrorDiv>
    )
  }

  const onChagneEmail = (e) => {
    setIsESuc(false)
    setEmail(e.target.value.toLowerCase())
  }

  return (
    <ModalPopup
      title="Forgot password"
      headerBackground="#2C3145"
      onDismiss={onDismiss}
    >
      <ModalContainer>
        <form onSubmit={onSubmitPassword} autoComplete="off">
          <RowForm>
            <p>Email</p>
            <div className="form-control-position">
              <input type="text"
                className="form-control"
                id="email"
                name="email"
                autoComplete="off"
                placeholder="Enter your email"
                value={email} onChange={e => onChagneEmail(e)} />
              {
                ((seconds > 0 || minutes > 0) && !isProcess) ?
                  <Text as="span" className='disabled-otp' >Send OTP</Text> :
                  <Text as="span" onClick={isProcess ? null : onSendMail}>Send OTP</Text>
              }
            </div>


            {isESuc && <Text style={{ textAlign: 'center' }} as="div" fontWeight="400" fontSize="14px" color="#fff" mt="15px">0{minutes}:{seconds > 9 ? seconds : `0${seconds}`}</Text>}
            {isESuc && <Text fontSize="12px" color="green" mt="10px">Please check your email to enter the confirmation OTP code</Text>}
          </RowForm>
          <RowForm>
            <p>OTP</p>
            <div className="form-control-position">
              <input type="text" className="form-control" id="otp" name="otp"
                placeholder="Enter OTP" />
            </div>
          </RowForm>
          <RowForm>
            <p>New password</p>
            <div className="form-control-position">
              <input type={isShowPass ? 'text' : 'password'} className="form-control pr" id="password"
                name="password" placeholder="Enter password"
                autoComplete="off"
              />
              {isShowPass ? (
                <Fal onClick={handleShowPass}><i className="fal fa-eye-slash" /></Fal>
              ) : (
                <Fal onClick={handleShowPass}><i className="fal fa-eye" /></Fal>
              )}
            </div>
          </RowForm>
          <RowForm>
            <p>Repeat password</p>
            <div className="form-control-position">
              <input type={isShowRePass ? 'text' : 'password'} className="form-control pr" id="rePassword"
                name="rePassword" placeholder="Repeat Password"
                autoComplete="off"
              />
              {isShowRePass ? (
                <Fal onClick={handleShowRePass}><i className="fal fa-eye-slash" /></Fal>
              ) : (
                <Fal onClick={handleShowRePass}><i className="fal fa-eye" /></Fal>
              )}
            </div>
          </RowForm>
          {/* <RowForm> */}
          {/*    <ReCaptchaDiv> */}
          {/*        <ReCAPTCHA */}
          {/*            sitekey="6Le84lAcAAAAAGB65Fjube9oPpxu4hegCxMmQURu" */}
          {/*            onChange={onChange} */}
          {/*        /> */}
          {/*    </ReCaptchaDiv> */}
          {/* </RowForm> */}
          {/* <ErrorDiv className={isShowError ? 'show' : 'hide'}>{textError}</ErrorDiv> */}
          {onShowError()}
          <ErrorDiv className={emailErr !== '' && !isShowError ? 'show' : 'hide'}>{emailErr}</ErrorDiv>
          <Button color="primary" width="100%"
            style={{
              borderRadius: '10px',
              boxShadow: 'inherit',
              marginTop: 15,
              marginBottom: 10
            }}>
            {
              loading ? <>
                <LoadingIcon src="/images/loading.gif" alt="loading" />
                Processing...
              </> : 'Submit'
            }
          </Button>
        </form>
      </ModalContainer>
    </ModalPopup>
  )
}

export default ForgotModal

const LoadingIcon = styled('img')`
  width: 30px;
  height: 30px;
  position: absolute;
  left:15px;
`
const ModalPopup = styled(Modal)`
    max-width: calc(100% - 30px);
    overflow-y: 'auto';
    border-radius:20px;
    width: 380px;
    background:#1B212F;
    div:first-child{
       button svg {
            fill:#fff;
        }

    }
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0;
`

const RowForm = styled.div`
  margin-bottom: 20px;

  p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: 700;
  }

  .form-control-position{
    position: relative;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .form-control{
      height: 40px;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 0 20px;
      color: ${({ theme }) => theme.colors.textSubtle};
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      background:rgba(0, 0, 0, 0.5);;
      &:focus{
        outline: none;
        border: 1px solid #c4c4c4 !important;
        border-radius: 10px !important;
      }
      &.pr{
        padding-right: 50px;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
      background:none!important;
    }
    span {
        position: absolute;
        right: 10px;
        z-index: 2;
        color: #E5A95A;
        font-size: 12px;
        font-weight:bold;
      &:hover{
        cursor: pointer;
      }
    }
    span.disabled-otp{
      color:rgba(255, 255, 255, 0.5);
      &:hover{
        cursor: inherit;
      }
    }
  }
 

  .gotoRegister{
    text-align: center;
    color: #fff;

    span{
        color: ${({ theme }) => theme.colors.primary};
        cursor: pointer;

        &:hover{
            text-decoration: underline;
        }
    }
  }
`
const Fal = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  transform: translate(0,-50%);
  color:#333;
`

const ErrorDiv = styled.div`
  display: none;
  color: red;
  font-size: 12px;
  position: relative;
  top: -5px;  
  &.show{
    display: block;
  }
`

const ReCaptchaDiv = styled.div`
  transform:scale(0.97);
  transform-origin:0 0;

  ${({ theme }) => theme.mediaQueries.xs} {
    transform:scale(1);
  }
`
