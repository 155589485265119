import Cookies from 'js-cookie'

export const setCookiesAuth = (name, data) => {
    Cookies.set(
        name,
        data,
        { path: '/', secure: true, expires: 3000000000000000 },
    )
}

export const removeCookiesAuth = (name) => {
    Cookies.remove(name)
}