import React from "react";
import styled from 'styled-components';
import { SvgProps } from "../../../components/Svg/types";

const StyleDiv = styled.div`
  padding-right:5px;
`
const Icon: React.FC<SvgProps> = (_props) => {
  return (
    <StyleDiv>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.0259 12.1919C19.8775 12.4294 19.681 12.6331 19.4489 12.7899L13.3989 16.8739C12.9857 17.1529 12.4985 17.302 11.9999 17.302C11.5013 17.302 11.0141 17.1529 10.6009 16.8739L4.55092 12.7899C4.18883 12.5456 3.91666 12.1896 3.77599 11.776C3.63532 11.3625 3.63389 10.9143 3.77192 10.4999L10.6129 15.0599C10.997 15.3159 11.4444 15.4605 11.9056 15.4778C12.3668 15.4951 12.8238 15.3844 13.2259 15.1579L13.3859 15.0599L20.2269 10.4999C20.3204 10.7793 20.3508 11.0759 20.316 11.3684C20.2813 11.661 20.1822 11.9422 20.0259 12.1919ZM20.2269 13.7499C20.3657 14.1642 20.3648 14.6125 20.2243 15.0262C20.0837 15.4398 19.8113 15.796 19.4489 16.0399L13.3989 20.1239C12.9857 20.4029 12.4985 20.552 11.9999 20.552C11.5013 20.552 11.0141 20.4029 10.6009 20.1239L4.55092 16.0399C4.18883 15.7956 3.91666 15.4396 3.77599 15.026C3.63532 14.6125 3.63389 14.1643 3.77192 13.7499L10.6129 18.3099C10.997 18.5659 11.4444 18.7105 11.9056 18.7278C12.3668 18.7451 12.8238 18.6344 13.2259 18.4079L13.3859 18.3099L20.2269 13.7499ZM13.3869 3.42492L19.7519 7.66792C19.8889 7.75925 20.0012 7.88297 20.0788 8.02811C20.1565 8.17324 20.1971 8.33531 20.1971 8.49992C20.1971 8.66454 20.1565 8.8266 20.0788 8.97174C20.0012 9.11688 19.8889 9.2406 19.7519 9.33192L13.3869 13.5759C12.9762 13.8498 12.4936 13.996 11.9999 13.996C11.5063 13.996 11.0236 13.8498 10.6129 13.5759L4.24792 9.33192C4.11096 9.2406 3.99867 9.11688 3.921 8.97174C3.84333 8.8266 3.80269 8.66454 3.80269 8.49992C3.80269 8.33531 3.84333 8.17324 3.921 8.02811C3.99867 7.88297 4.11096 7.75925 4.24792 7.66792L10.6129 3.42492C11.0236 3.15104 11.5063 3.00488 11.9999 3.00488C12.4936 3.00488 12.9762 3.15104 13.3869 3.42492ZM11.5599 4.60592L11.4439 4.67292L5.70492 8.49992L11.4449 12.3279C11.5904 12.4248 11.7587 12.4819 11.9331 12.4935C12.1075 12.5051 12.2819 12.4708 12.4389 12.3939L12.5549 12.3279L18.2939 8.49992L12.5539 4.67292C12.4087 4.57603 12.2406 4.51887 12.0663 4.50711C11.8921 4.49536 11.7179 4.52943 11.5609 4.60592H11.5599Z" fill="white"/>
</svg>

  </StyleDiv>
  );
};

export default Icon;
