import axios from "axios";
import Cookies from 'js-cookie';

const BASE_URL = process.env.REACT_APP_BASE_URL
const URL_SERVICE = process.env.REACT_APP_URL_SERVICE;


let token = ""
let userLogin = Cookies.get("auth")

if (userLogin) {
  userLogin = JSON.parse(userLogin)
  token = userLogin.data.token
}

export const getApi = async (url) => {
  const baseUrl = BASE_URL + url
  const _options = {
    headers: undefined
  };
  if (token !== '') _options.headers = { "Authorization": `Bearer ${token}` };

  // eslint-disable-next-line no-return-await
  return await axios.get(baseUrl, _options);
}

export const postApi = async (url, params) => {
  const baseUrl = BASE_URL + url
  const _options = {
    headers: undefined
  };
  if (token !== '') _options.headers = { "Authorization": `Bearer ${token}` };

  // eslint-disable-next-line no-return-await
  return await axios.post(baseUrl, params, _options);
}


export const onGetSvApi = async (url: string, account?: string) => {
  const baseUrl = `${URL_SERVICE}/api/${url}`
  const _options = {
    headers: undefined
  };
  const tokenSv = await onGetTokenSV(account);
  _options.headers = { "Authorization": `Bearer ${tokenSv}` };

  // eslint-disable-next-line no-return-await

  const resPost = await  axios.get(baseUrl, _options).then(resp => {
    return { code: true, data: resp.data, msg: null }
  }).catch(error => {
    return { code: false, data: null, msg: error.response }
  })
  return  resPost;
}

export const onPostSvApi = async (url: string, params: any, account?: string) => {
  const baseUrl = `${URL_SERVICE}/api/${url}`
  const _options = {
    headers: undefined
  };
  const tokenSv = await onGetTokenSV(account);
  _options.headers = { "Authorization": `Bearer ${tokenSv}` };
  // eslint-disable-next-line no-return-await
  const resPost = await axios.post(baseUrl, params, _options).then((resp) => {
    return { code: true, data: resp?.data || resp, msg: null }
  }).catch(
    async (error) => {
      return { code: false, data: null, msg: error.response }
    })
  return resPost
}

export const onGetTokenSV = async (account: string) => {
  if (!account) {
    return null
  }
  const url = `${URL_SERVICE}/api/get-token?wallet=${account}&token=${token}`
  const newToken = await axios.get(url).then(respon => {
    return respon.data.data.token
  }).catch(e => { return null })
  return newToken
}

export const putApi = async (url, params) => {
  const baseUrl = BASE_URL + url
  const _options = {
    headers: undefined
  };
  if (token !== '') _options.headers = { "Authorization": `Bearer ${token}` };

  // eslint-disable-next-line no-return-await
  return await axios.put(baseUrl, params, _options);
}

export const patchApi = async (url, params) => {
  const baseUrl = BASE_URL + url
  const _options = {
    headers: undefined
  };
  if (token !== '') _options.headers = { "Authorization": `Bearer ${token}` };

  // eslint-disable-next-line no-return-await
  return await axios.patch(baseUrl, params, _options);
}

export const deleteApi = async (url, params = '') => {
  const baseUrl = BASE_URL + url
  const _options = {
    headers: undefined,
    data: undefined
  };

  if (token !== '') _options.headers = { "Authorization": `Bearer ${token}` };
  if (params !== '') _options.data = { ids: params };

  // eslint-disable-next-line no-return-await
  return await axios.delete(baseUrl, _options);
}