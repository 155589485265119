import React, { useState, useRef, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Skeleton } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import styled, { css } from 'styled-components'
import ButtonOutline from 'components/Layout/ButtonOutline'



const CardItemDiv = styled.div<{
  padding?: string
}>`
  padding: ${({ padding }) => padding};
  width: 100%;
  padding-bottom:15px;
  margin-bottom:15px;
  border-bottom: 1px solid rgba(255,255,255,30%) 
`
const CardItemInner = styled.div`
  display:flex;
  column-gap: 10px;
  font-size:16px;
  color:#2F2E41;
  align-items:center;
  justify-content: space-between;
  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
  ${({ theme }) => theme.mediaQueries.xs} {
    flex-wrap: wrap;
  }
  :hover{
    h4,h5{
      color: #E5A95A;
    }
  }
`
const CardItemImage = styled(Skeleton)` 
    max-width: 50px;
    width: 50px;
    height:65px;
    border-radius:5px;
    margin-right: 30px;
`
const CardHeader = styled.div`
  display: flex;
  align-items: center;    
`

const Loading: React.FunctionComponent = () => {
  const { t } = useTranslation()

  return (
    <CardItemDiv padding="0 15px">
      <CardItemInner>
        <CardHeader>
          <CardItemImage />
          <Skeleton
            animation="pulse"
            variant="rect"
            width="250px"
            height="20px"
          />
        </CardHeader>
        <Skeleton
          animation="pulse"
          variant="rect"
          width="100px"
          height="20px"
        />
        <Skeleton
          animation="pulse"
          variant="rect"
          width="100px"
          height="20px"
        />
        <Skeleton
          animation="pulse"
          variant="rect"
          width="100px"
          height="20px"
        />
        <Skeleton
          animation="pulse"
          variant="rect"
          width="90px"
          height="40px"
        />
      </CardItemInner>
    </CardItemDiv>
  )
}

export default Loading