import tokens from './tokens'
import { MiningNftConfig } from './types'

const minningNft: MiningNftConfig[] = [ 
  {
    pid: 0,
    earningToken: tokens.mowa,
    decimals:18,
    name: 'MOWA NFT',
  },
  
]

export default minningNft
