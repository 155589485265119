import { ButtonMenu, ButtonMenuItem, Flex, Text } from '@pancakeswap/uikit';
import { useWeb3React } from '@web3-react/core';
import Buttons from 'components/Layout/Buttons';
import MowaAbi from 'config/abi/Mowa.json';
import { useTranslation } from 'contexts/Localization';
import { ethers } from 'ethers';
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice';
import { useMowaContract, useMowaMinningNftContract } from 'hooks/useContract';
import useExchange from 'hooks/useExchangeRate';
import useToast from 'hooks/useToast';
import useTokenBalance from 'hooks/useTokenBalance';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import CardPopup from 'react-modal';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { getMowaAddress, getMowaMinningNFTAddress } from 'utils/addressHelpers';
import { upgradeMinningNFT } from 'utils/calls';
import multicall from 'utils/multicall';
import { formatDecimal, sleep } from 'utils/utilsFun';
import usePriceNft from 'views/NftMining/BNB/hooks/usePriceNft' 

interface BountyModalProps {
  data?: any,
  isOpen?: boolean,
  pid?: number,
  onRequestClose?: () => void,
  onAfterOpen?: () => void,
  onSuccess?: () => void,
}

const URL_SERVICE = process.env.REACT_APP_URL_SERVICE;
const PopupUpgrade: React.FC<BountyModalProps> = ({ pid, data, isOpen, onRequestClose, onAfterOpen, onSuccess }) => {
  const { t } = useTranslation()
  const [active, setIndex] = useState(0)
  const LinkTo = `/swap?outputCurrency=${getMowaAddress()}`;
  const [cookies] = useCookies(['auth']);
  const mowaContract = useMowaContract()
  const mowaMiningNftContract = useMowaMinningNftContract()
  const { account } = useWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()
  const { toastSuccess, toastError } = useToast()
  const [loading, setLoading] = useState(false)
  const { balance } = useTokenBalance(getMowaAddress())
  const activeRef = useRef('x2')
  const { priceChange } = useExchange()
  const dollarMowa = useRef(null)
  const choosePrice = useRef(null) // giá xử lý approve 



  const amountHashrate = usePriceNft(account, pid, data?.item_id - 1);  // giá gọi riêng khỏi goi riêng như này đc ko.

  const _balance: any = balance.toString() || 0;
  const amountBalance = formatDecimal((_balance / 10 ** 18), 4)
  const [optionValue, setOptionValue] = useState(null) // giá hiện thị giao diện 

  useEffect(() => {    // get lại giá khởi tạo ban đầu
    async function getPrice() {
      if (amountHashrate) {
        const _price = amountHashrate.toString() / 10 ** 18
        dollarMowa.current = (_price * priceChange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') || 0;
        const defaultX2 = (_price * 2 * 0.95).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        choosePrice.current = _price * 2 * 0.95;
        setOptionValue(defaultX2)
      }
    }
    getPrice()
  }, [amountHashrate, priceChange])


  const onPower = (value: string) => {
    const _price = amountHashrate.toString() / 10 ** 18;   
    switch (value) {
      case 'x2':
        activeRef.current = 'x2';
        dollarMowa.current = ((_price * 2 * 0.95) * priceChange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        choosePrice.current = _price * 2 * 0.95;
        setOptionValue((_price * 2 * 0.95).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'));
        break;
      case 'x5':
        activeRef.current = 'x5'
        dollarMowa.current = ((_price * 5 * 0.9) * priceChange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        choosePrice.current = _price * 5 * 0.9
        setOptionValue((_price * 5 * 0.9).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
        break;
      default:
        activeRef.current = 'x10'
        dollarMowa.current = ((_price * 10 * 0.75) * priceChange).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
        choosePrice.current = _price * 10 * 0.75
        setOptionValue((_price * 10 * 0.75).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,'))
        break;
    }
  }

  const onApporve = async () => {  
    setLoading(true)
    try {      
      const price = choosePrice.current * 10 ** 18;    
      const call = [
        {
          address: getMowaAddress(),
          name: 'allowance',
          params: [account, getMowaMinningNFTAddress()],
        },
        {
          address: getMowaAddress(),
          name: 'balanceOf',
          params: [account],
        },
      ];
      const [info,balanceOf] = await multicall(MowaAbi, call);

      if (parseInt(balanceOf.toString()) < price) {
        toastError('Error', 'Insufficient funds in the account');
        return;
      }

      const priceAllowance = info[0].toString();
      if (parseInt(priceAllowance) < price) {
        const resp = await callWithGasPrice(mowaContract, 'approve', [getMowaMinningNFTAddress(), ethers.constants.MaxUint256])
        const receipt = await resp.wait();
        if (receipt.status) {
          onUpgrade();
        }
      } else {
        onUpgrade();
      }
    } catch (e) {
      console.log('onApporve', e);
      toastError('Error', 'Approve Hashrate fail')
      setLoading(false)
    }
  }


  const onUpgrade = async () => {
    const _number = activeRef.current.slice(1)    
    try {
      const _resp = await upgradeMinningNFT(mowaMiningNftContract, pid, data?.item_id - 1, _number)
      if (_resp) {
        await sleep(2000)
        toastSuccess('Success', 'Upgrade Hashrate success!')
        setLoading(false);
        onSuccess()
      } else {
        toastError('Error', 'Upgrade Hashrate fail')
        setLoading(false)
      }
    } catch (error) {
      console.log('onUpgrade', error);
      toastError('Error', 'Upgrade Hashrate fail') // đang vào lỗi đây
      setLoading(false)
    }
  }

  return (
    <CardPopup
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={onAfterOpen}
      style={customStyles}
      closeTimeoutMS={300}
      shouldCloseOnOverlayClick={false}
    >
      <StyledModal activeIndex={active}>
        <div className="modal-header">
          {t('Upgrade Hashrate')}
          {!loading && <button type="button" className="close" onClick={onRequestClose}>
            <img src="/images/close.png" alt="close" />
          </button>}
        </div>
        <Content>
          <Flex className="list-button">
            <Text className={activeRef.current === 'x2' ? 'button-item active' : 'button-item'} onClick={() => onPower('x2')}>x2</Text>
            <Text className={activeRef.current === 'x5' ? 'button-item active' : 'button-item'} onClick={() => onPower('x5')}>x5</Text>
            <Text className={activeRef.current === 'x10' ? 'button-item active' : 'button-item'} onClick={() => onPower('x10')}>x10</Text>
          </Flex>
          <div className="boxCalc">
            <Text fontSize="12px" fontWeight="400" color="#fff">Stake needed (MOWA)</Text>
            <Text fontSize="24px" bold color="#E5A95A">{optionValue || 0}</Text>
            <Text fontSize="12px" fontWeight="400" color="rgba(255,255,255,0.6)">≈ {dollarMowa.current}</Text>
          </div>
          <Flex justifyContent="space-between">
            <Text as="p"> <Text as="span" fontSize="12px" fontWeight="400" color="rgba(255,255,255,0.6)">Balance:</Text><strong>{amountBalance} MOWA </strong></Text>
            <Text as={Link} to={LinkTo} fontSize="14px" fontWeight="400" color="#E5A95A">Buy $MOWA</Text>
          </Flex>
          <Buttons onClick={() => onApporve()} disabled={loading} color="#fff" height="56px" width="320px" fontSize="14px" style={{ marginTop: 20, position: 'relative' }} >
            {
              loading ? <>
                <LoadingIcon src="/images/loading.gif" alt="loading" />
                {t('Loading...')}
              </> : t('Approve')
            }
          </Buttons>
        </Content>
      </StyledModal>
    </CardPopup>
  )
}

export default PopupUpgrade


const LoadingIcon = styled('img')`
  width: 30px;
  height: 30px;
  position: absolute;
  left:15px;
`
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    backgroundColor: 'transparent',
    border: 'none',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
  },
};

CardPopup.setAppElement('#root');

const expandAnimation = keyframes`
  from {
    max-height: 580px;
  }
  to {
    max-height: 640px;
  }
`

const collapseAnimation = keyframes`
  from {
    max-height: 640px;
  }
  to {
    max-height: 580px;
  }
`

const StyledModal = styled.div <{ activeIndex: number }>`
 animation: ${({ activeIndex }) =>
    activeIndex
      ? css`
          ${expandAnimation} 300ms linear forwards
        `
      : css`
          ${collapseAnimation} 300ms linear forwards
        `};
  width: 380px;
  border:none;
  background:#1B212F;   
  border-radius:20px;

  div.modal-header {
    background: #2C3145;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position:relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-left:30px;
    color: #fff;
    font-size:16px;
    font-weight: bold;
    button.close {
      position: absolute;
      right: 15px;
      width: 50px;
      height: 50px;
      display: block;
      justify-items: center;
      text-align: center;
      padding-top: 7px;
      border:none;
      background: transparent;
      &:hover{
        cursor: pointer;
      }
    }
  }
  div.img-card {
    margin-top: 20px;
    width: 175px;
    height: 227px;
    margin: auto;
    background: linear-gradient(360deg,#BF893D 0%,#FFD493 137.5%);
    border-radius: 5px;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1px;
    img{
      width: 100%;
      height: auto;
    }
  }

  div.title{
    text-align: center;
    padding-top: 15px;
  }
  div.box-select
  {
    margin-top: 20px;
    > div {
        width: 100%;
        margin:0;   
      }
  } 
`


type isType = {
  activeIndex: number;
};

const GroupButton = styled(ButtonMenu)`
  border:none;
  background:transparent;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:40px;
  width: calc(100% - 114px);
`
const Wrapper = styled.div`
  display: flex;
  align-items: start;  
  flex-direction: column;
  width: 100%;
  a {
    padding-left: 12px;
    padding-right: 12px;
  }
`
const ButtonDeposit = styled(ButtonMenuItem) <isType>`
  font-size:18px;
  font-weight: ${(props: isType) => (props.activeIndex === 0 ? 'bold' : 400)};
  color:${(props: isType) => (props.activeIndex === 0 ? '#fff' : 'rgba(255, 255, 255, 0.6)')};
  background:transparent;
  border:none;
  border-radius: 8px;
  height:40px;
`
const ButtonWidthdraw = styled(ButtonMenuItem) <isType>`  
  font-size:18px;
  font-weight: ${(props: isType) => (props.activeIndex !== 0 ? 'bold' : 400)};;
  color:${(props: isType) => (props.activeIndex !== 0 ? '#fff' : 'rgba(255, 255, 255, 0.6)')};
  background:transparent;
  border-radius: 8px;
  height:40px;
`
const Content = styled.div`
  width: 100%;
  padding: 30px ;
  .list-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .button-item{     
      background: #2C3145;
      border-radius:10px;
      width:96px;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      align-items:center;
      justify-content: center;
      &:hover{
        cursor: pointer;
        background: linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%)
      }
    }
    .button-item.active{
      background: linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%);
    }
  }
  .boxCalc{
    background: #10131C;
    padding:20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
`
const TabPanel = styled.div`
  &.tab-panel-2{
    width: 100%;
    margin-left: 0;
    margin-top: 20px;   
  }
`

const GroupInput = styled.div`
  margin-top:15px;
  margin-bottom:10px;
  font-size:14px;
  display:flex;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  height: 40px;
  align-items:center;
  justify-content: center;
  padding-right:15px;
  .text-sale {   
    width: calc( 100% - 25px);
    input{
      border: 0;
    }
  }
`
const AmountInput = styled.input`  
  display:flex;  
  flex:1;
  border: transparent ;
  padding-left:0;  
  padding-right: 15px;
  margin-left: 15px;
  background:none;
  color:#fff;
  ::placeholder {
    color: #ADADAD;
  };

  &:focus {
    outline: none;
  }
`
const MoniText = styled(Text)`
  font-size:12px;
  color:rgba(255,255,255,0.6);
  font-weight:500;
  text-transform: uppercase;
`

const ReferenceElement = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  margin-bottom:30px;
`
const LineHeight = styled.div`
  height: 30px;
  width:1px;
  background: rgba(255,255,255,0.3);
`
const InputText = styled.input`
  width:calc( 100% - 25px);
  background: rgba(0, 0, 0, 0.5);
  border: 0;
  box-sizing: border-box;
  border-radius: 10px;
  padding:10px 15px;
  font-size: 14px;
  font-weight: 400;
  color:#fff;
  &:focus {
    outline: none;
  }
  ::placeholder {
    color:rgba(255, 255, 255, 0.5);
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:rgba(255, 255, 255, 0.5);
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
   color:rgba(255, 255, 255, 0.5);
  }
`