import React from 'react'
import styled from 'styled-components'
import { Box } from '@pancakeswap/uikit'
import Container from '../Layout/Container'

const Outer = styled(Box)<{ background?: string }>`
  height: 212px;
`

const Inner = styled(Container)`
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  height: 100%;

  h1{
    font-size: 60px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 5px;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 50px;
    }

    ${({ theme }) => theme.mediaQueries.md} {
      font-size: 60px;
    }
  }

  p{
    color: #716C80;
    font-size: 16px;
    line-height: 1.4;

    ${({ theme }) => theme.mediaQueries.sm} {
      font-size: 18px;
    }
  }
`

const PageHeader: React.FC<{ background?: string }> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    <Inner>{children}</Inner>
  </Outer>
)

export default PageHeader
