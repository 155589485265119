import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <img src="/images/onto.png" alt="bitkeep" style={{width:40, marginBottom:8, borderRadius:10}}/>
  );
};

export default Icon;
