import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import Page from '../Layout/Page'

interface Props {
  width?: string,
  height?:string
}

const Loader: React.FC<Props> = ({width, height}) => {
  return (<Wrapper>
    <LoaderView >
      <svg className="circular-loader" viewBox="25 25 50 50" >
        <circle className="loader-path" cx="50" cy="50" r="20" fill="none" stroke="#de972f" strokeWidth="3" />
      </svg>      
      <img className="icon-img" src="/images/M-icon.png" alt="M-icon.png" />
    </LoaderView>
    </Wrapper>
  )
}

export default Loader

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const rotateAnimation = keyframes`
  100% {
   transform: rotate(360deg);
  }
`
const dashAnimation = keyframes`
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
`
const colorAnimation = keyframes`
 0% {
    stroke: #de972f;
  }
  40% {
    stroke: #de972f;
  }
  66% {
    stroke: #de972f;
  }
  80%, 90% {
    stroke: #de972f;
  }
`
const frontAnimation = keyframes`
 0% {   
   opacity:1
   transform: rotateY(1deg);
  }
 100% {   
   opacity: 0.3;
   transform: rotateY(180deg);
  }
`

const LoaderView = styled.div`
  position: relative;
  margin: 0px auto;
  width: 200px;
  height:200px;
  display:flex;
  justify-content: center;
  align-items: center;
  .icon-img{
    position: absolute;
    animation: ${frontAnimation} 1.3s linear infinite;
  }
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
    }
    .circular-loader {
      animation: ${rotateAnimation} 2s linear infinite;
      height: 100%;
      transform-origin: center center;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
    }

    .loader-path {
        stroke-dasharray: 150,200;
        stroke-dashoffset: -10;
        animation: ${dashAnimation} 1.5s ease-in-out infinite,  ${colorAnimation} 6s ease-in-out infinite;
        stroke-linecap: round;
    }
 
  
`
