import React from 'react'
import styled, { DefaultTheme, keyframes } from 'styled-components'
import { MENU_ENTRY_HEIGHT } from '../config'

export interface Props {
  secondary?: boolean
  isActive?: boolean
  theme: DefaultTheme
  isPushed?: boolean
}

const rainbowAnimation = keyframes`
  0%,
  100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
`

const LinkLabel = styled.div<{ isPushed: boolean }>`
  color: ${({ isPushed, theme }) => !isPushed && 'transparent'};
  transition: color 0.4s;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.3px;
  flex-grow: 1;
`

const MenuEntry = styled.div<Props>`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${MENU_ENTRY_HEIGHT}px;
  /* padding: ${({ secondary }) => (secondary ? '0' : '0')}; */
  font-size: ${({ secondary }) => (secondary ? '14px' : '16px')};
  color: #fff;
   margin-left: ${({ isPushed }) => {
    return isPushed ? '0' : '0px'
  }};
  margin-right: ${({ isPushed }) => {
    return isPushed ? '0' : '0px'
  }};
  padding: 0 0 0 16px;
  position: relative;
  ::before {
    background: #4880ff;
    content: '';
    left: -20px;
    width: 6px;
    height: ${({ isActive }) => (isActive ? '100%' : '0%')};
    position: absolute;
    border-radius: 0 8px 8px 0;
  }
  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 16px 0 0;
    color: ${({ isActive, theme }) => {
      if (isActive) return '#fff'
      if (theme.isDark) return theme.colors.primary
      return '#202224'
    }};

     &.active {
      background-color: #000;
      color: #fff;

      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-color: #000;
        width: 17px;
        height: 100%;
      }

      &::after{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 8px;
        background: linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%);
      }
    }
  }

  svg {
    margin-right: 15px;
    width: 24px;
  }

  &:hover {
    background-color: #1B212F;
  }

  // Safari fix
  flex-shrink: 0;

  &.rainbow {
    background-clip: text;
    animation: ${rainbowAnimation} 3s ease-in-out infinite;
    background: ${({ theme }) => theme.colors.gradients.bubblegum};
    background-size: 400% 100%;
  }
`
MenuEntry.defaultProps = {
  secondary: false,
  isActive: false,
  role: 'button',
}

const LinkLabelMemo = React.memo(LinkLabel, (prev, next) => prev.isPushed === next.isPushed)

export { MenuEntry, LinkLabelMemo as LinkLabel }
