import { createSlice } from '@reduxjs/toolkit'


const PET_ITEM = { item_id: 1, item_status: 0, icon: '', name: '', type: '', qty: 0, token_id: 0, level: 0, star: 0, branch: '', pve_info: { rank: 0 } }

const initialState = {
  petItemUpgrade: PET_ITEM,
}

export const BagNftSlice = createSlice({
  name: 'BagNft',
  initialState,
  reducers: {
    onGetPetItemInfo: (state, action) => {
      state.petItemUpgrade = action.payload
    }
  },

})

// Actions
export const { onGetPetItemInfo } = BagNftSlice.actions

export default BagNftSlice.reducer
