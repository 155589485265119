import { Text } from '@pancakeswap/uikit';
import { useWeb3React } from "@web3-react/core";
import Buttons from 'components/Layout/Buttons';
import { useTranslation } from 'contexts/Localization';
import React, { useState } from 'react';
import CardPopup from 'react-modal';
import styled from 'styled-components';
import { postApi } from "utils/apiHelper";
import ButtonOutline from 'components/Layout/ButtonOutline';
import useToast from 'hooks/useToast'
import useCountdown from 'hooks/useCountdown';
import PopupChangeEmail from 'components/PopupChangeEmail'

interface BountyModalProps {
  data?: any,
  isOpen?: boolean,
  onRequestClose?: (value) => void,
  onAfterOpen?: () => void,
  type?: string,
  isUpdateEmail?: boolean
  msgErr?: string
}

const PopupConfirmEmail: React.FC<BountyModalProps> = ({ data, isOpen, isUpdateEmail, onRequestClose, onAfterOpen, msgErr }) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const [textCode, setTextCode] = useState('')
  const [textError, setTextError] = useState(msgErr?.length > 0 ? [{ code: msgErr }] : [])
  const [isShowError, setIsShowError] = useState(msgErr?.length > 0)
  const [isProcess, setIsProcess] = useState(false)
  const { toastSuccess } = useToast()
  const { minutes, seconds, onReset } = useCountdown(180)
  const [openChangeEmail, setOpenChangeEmail] = useState(false)
  const [email, setEmail] = useState(data)
  const [isChangeMail, setIsChangeMail] = useState(false)
  const [isResend, setIsResend] = useState(false)

  const onShowError = () => {
    if (!isShowError) return <></>
    return (
      <ErrorDiv className='show'>
        {
          textError?.length > 0 ? textError?.map(text => {
            return (
              <Text mt="10px" as="p" fontSize="12px" color="red">{text.code}</Text>
            )
          }) :
            <></>
        }
      </ErrorDiv>
    )
  }

  const onSubmit = () => {
    if (textCode === "") {
      setTextError([{ code: 'The Verification code field is required.' }])
      setIsShowError(true)
    } else {
      setIsProcess(true)
      let params
      let url
      if (isChangeMail) {
        params = {
          old_email: data,
          new_email: email,
          otp: textCode
        }
        url = '/users/verify_email'
      } else {
        params = {
          wallet: account,
          otp: textCode,
        }
        url = '/account/verify'
      }
      postApi(url, params).then(resp => {
        if (resp?.data?.success) {
          setIsShowError(false)
          toastSuccess('Success', 'Verification email success');
          onRequestClose('ok')
        } else {
          setTextError([{ code: 'OTP is invalid' }])
          setIsShowError(true)
        }
      }).catch(err => {
        if (err?.response?.status === 422) {
          setIsShowError(true)
          setTextError(err.response.data.errors)
        }
      }).finally(() => {
        setIsProcess(false)
      })

    }
  }

  // call lại api gửi resend otp 

  const _resendOtpRequest = () => {
    let params
    let url
    if (isChangeMail) {
      params = {
        old_email: data,
        new_email: email,
      }
      url = '/users/change_email'
    } else {
      params = {
        "wallet": account,
        "email": data
      }
      url = '/otp/resend'
    }
    setIsResend(true)
    postApi(url, params).then(resp => {
      onReset(180)
      setIsShowError(false)
    }).catch(err => {
      console.log(err.response);
      setTextError([{ code: err.response?.data?.message }])
      setIsShowError(true)
    }).finally(() => {
      setIsResend(false)
    })
  }

  const onChangEmail = () => setOpenChangeEmail(true)

  const onCloseChangeEmail = (value) => {
    if (value === 'ok') {
      setIsChangeMail(true)
      onReset(180)
      setOpenChangeEmail(false)
      return
    }
    // setIsChangeMail(false)
    setOpenChangeEmail(false)
  }

  const _renderResend = () => {
    if (minutes === 0 && seconds === 0) {
      return <Text onClick={() => isResend ? null : _resendOtpRequest()} as="p" bold mb="12px" fontWeight="400" fontSize="14px" color="#E5A95A">Resend OTP</Text>
    }
    return <Text bold mb="12px" fontWeight="400" fontSize="14px" className='disabled-otp'>Resend OTP</Text>
  }

  return (
    <CardPopup
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={onAfterOpen}
      closeTimeoutMS={300}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
    >
      <ContentModal>
        <div className="modal-header">
          <div className="title">
            <Text ml="30px" as="span" bold fontSize="18px" color="#fff">OTP Verification</Text>
          </div>
          {/* <button type="button" className="close" onClick={() => onRequestClose(null)}>
            <img src="/images/close.png" alt="close" />
          </button> */}
        </div>
        <div className="modal-content">
          <Text as="span" mb="10px" fontWeight="400" fontSize="18px" color="#fff">Check your email!</Text>
          <Text as="span" mb="10px" fontWeight="400" fontSize="14px" color="rgba(255,255,255,0.6)">We’ve sent a verification code to your email:</Text>
          <Text as="span" mb="10px" fontWeight="400" fontSize="14px" color="#E5A95A">{email}</Text>
          <RowForm>
            <Text as="p" bold fontSize="12px" color="#fff">Verification code</Text>
            <div className="form-control-position">
              <input type="text" className="form-control" style={{ paddingRight: 100 }}
                placeholder="Enter verification code" value={textCode} onChange={(e) => setTextCode(e.target.value)} />
              <div className="otp">
                {_renderResend()}
              </div>
            </div>
          </RowForm>
          <Text as="span" fontWeight="400" fontSize="14px" color="#fff" mt="15px">0{minutes}:{seconds > 9 ? seconds : `0${seconds}`}</Text>
          {onShowError()}
          <Buttons onClick={!isProcess && onSubmit} color="#fff" height="56px" width="100%" fontSize="14px" style={{ marginTop: 20 }} >Verify</Buttons>
          {isUpdateEmail ? null : <ButtonOutline
            onClick={!isProcess && onChangEmail}
            color="#fff"
            height="56px"
            width="100%" fontSize="14px" style={{ marginTop: 20 }} >
            Change email</ButtonOutline>}

        </div>
      </ContentModal>
      {openChangeEmail &&
        <PopupChangeEmail
          isOpen={openChangeEmail}
          onRequestClose={onCloseChangeEmail}
          data={data}
          newEmail={value => setEmail(value)}
        />
      }
    </CardPopup>
  )
}

export default PopupConfirmEmail
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    overflow: 'initial',
    position: 'initial'
  },
};


CardPopup.setAppElement('#root');

const ContentModal = styled.div`
  background: #1B212F;    
  width:380px;
  border-radius: 20px;
  div.img-card {
    margin-top: 20px;
    width: 278px;
    height: 362px;
    margin: auto;
    background: linear-gradient(360deg,#BF893D 0%,#FFD493 137.5%);
    border-radius: 5px;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1px;
    img{
      width: 100%;
      height: auto;
    }
  } 
  div.modal-header {
    background: #2C3145;
    height: 80px;
    display: flex;
    align-items: center;
    position:relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    button.close {
      position: absolute;
      right: 15px;
      width: 50px;
      height: 50px;
      display: block;
      justify-items: center;
      text-align: center;
      padding-top: 7px;
      border:none;
      background: transparent;
      &:hover{
        cursor: pointer;
      }
    }
  }
  div.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:30px
  }
`
const RowForm = styled.div`
  margin-top: 10px;
  width: 100%;
  p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: 700;
  }

  .form-control-position{
    margin-top: 10px;
    position: relative;
    .form-control{
      height: 40px;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 0 20px;
      color: ${({ theme }) => theme.colors.textSubtle};
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      background:rgba(0, 0, 0, 0.5);;
      &:focus{
        outline: none;
        border: 1px solid #c4c4c4 !important;
      }

      &.pr{
        padding-right: 50px
      }
    }   
    .otp{
      position: absolute;
      right: 10px;
      top: 0;
      z-index: 2;
      height: 40px;
      padding-top: 10px;
      z-index: 2;           
      &:hover p{
        cursor: pointer;
        color:#E5A95A;
      }
      div.disabled-otp{
        color:rgba(255, 255, 255, 0.5);
        font-weight: 700;
      }
    }
  }  
`
const ErrorDiv = styled.div`
  display: none;
  color: red;
  font-size: 12px;
  position: relative;
  &.show{
    display: block;
  }
`