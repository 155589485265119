import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="40px"
    height="40px"
    marginBottom="8px"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      cx="12"
      cy="12"
      r="12"
      fill="url(#paint0_linear_9098_109000)"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M14.5 4.5h-5v5.052h-5v5.053h5V19.5h5v-5.053h-5V9.553h5V4.5zm5 5.052h-5v5.053h5V9.552z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="paint0_linear_9098_109000"
        x1="24"
        x2="0"
        y1="0"
        y2="24"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.396" stopColor="#A9FFE0"/>
        <stop offset="1" stopColor="#86D5FF"/>
      </linearGradient>
    </defs>
  </Svg>
  );
};

export default Icon;
