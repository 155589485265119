import React, { useEffect, useState, useRef } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useMowaMinningNftContract, useMowaNFTContract } from 'hooks/useContract'
import { getMowaMinningNFTAddress, getMowaNFTAddress } from 'utils/addressHelpers'
import MowaMiningNftAbi from 'config/abi/MowaMinningNFTAbi.json'

import multicall from 'utils/multicall'

const useMinningInf = (pid) => {
  const [valueTopInf, setValueTopInf] = useState(null)
  useEffect(() => {
    async function onGetInfContract() {
      const call = [       
        {
          address: getMowaMinningNFTAddress(),
          name: 'mowaPoolInfoNFT',
          params: [pid],
        },      
        {
          address: getMowaMinningNFTAddress(),
          name: 'calculator',
          params: [pid, 1, 1000],
        },
      ]
      const [poolInfoNFT, calc] = await multicall(MowaMiningNftAbi, call)
      setValueTopInf({poolInfoNFT, calc })
    }
    onGetInfContract()

  }, [pid])

  return {valueTopInf}
}

export default useMinningInf
