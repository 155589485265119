import { InjectedConnector } from '@web3-react/injected-connector'
import { ChainId } from '@pancakeswap/sdk'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { ConnectorNames } from 'components/Pancake-uikit'
import { ethers } from 'ethers'
import { multichainConfig } from 'config/chains'


const POLLING_INTERVAL = 12000
const SUPPORTED_CHAIN_ID = []

Object.keys(multichainConfig).map((chain) => SUPPORTED_CHAIN_ID.push(parseInt(chain)))

export const injected = new InjectedConnector({ supportedChainIds: SUPPORTED_CHAIN_ID })

const walletconnect = new WalletConnectConnector({
  rpc: {
    [ChainId.MAINNET]: 'https://bsc-dataseed.binance.org',
    [ChainId.TESTNET]: 'https://data-seed-prebsc-2-s3.binance.org:8545',
  },
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
})

const bscConnector = new BscConnector({ supportedChainIds: SUPPORTED_CHAIN_ID })




export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
  [ConnectorNames.BSC]: bscConnector,
  [ConnectorNames.WalletLink]: async () => {
    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector')
    return new WalletLinkConnector({
      url: 'https://dapp.moniwar.io/dashboard',
      appName: 'Moniwar',
      appLogoUrl: 'https://dapp.moniwar.io/images/logo.png',
      supportedChainIds: SUPPORTED_CHAIN_ID,
    })
  },
}

export const getLibrary = (provider): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL
  return library
}

/**
 * BSC Wallet requires a different sign method
 * @see https://docs.binance.org/smart-chain/wallet/wallet_api.html#binancechainbnbsignaddress-string-message-string-promisepublickey-string-signature-string
 */
export const signMessage = async (provider: any, account: string, message: string): Promise<string> => {
  if (window.BinanceChain) {
    const { signature } = await window.BinanceChain.bnbSign(account, message)
    return signature
  }

  /**
   * Wallet Connect does not sign the message correctly unless you use their method
   * @see https://github.com/WalletConnect/walletconnect-monorepo/issues/462
   */
  if (provider.provider?.wc) {
    const wcMessage = ethers.utils.hexlify(ethers.utils.toUtf8Bytes(message))
    const signature = await provider.provider?.wc.signPersonalMessage([wcMessage, account])
    return signature
  }

  return provider.getSigner(account).signMessage(message)
}
