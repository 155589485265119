import React from 'react'
import { useModal } from '@pancakeswap/uikit'
// import { useWalletModal } from 'components/Pancake-uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import ConnectModal from "components/Pancake-uikit/widgets/WalletModal/ConnectModal";

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  // const { onPresentConnectModal } = useWalletModal(login, logout, t)
  const [onPresentConnectModal] = useModal(<ConnectModal login={login} />, false);

  return (
    <Button onClick={onPresentConnectModal} {...props} id="connect-wallet">
      {t('Connect Wallet')}
    </Button>
  )
}

export default ConnectWalletButton

const Button = styled.button <{ disabled: boolean }>`
  color: rgba(255, 255, 255, 1);;
  background: linear-gradient(360deg,#BF893D 0%,#FFD493 137.5%);
  border:none;
  box-sizing: border-box;
  border-radius: 10px;
  height: 40px;
  padding: 0 30px;
  font-size:16px;
  font-weight:bold;
  box-shadow:none;
  transition: all .2s linear;
  cursor: pointer;
  &:hover {
    opacity: .65;
  }
  &:focus {  
    margin-top:2px;
  }  

`