import { Flex } from '@pancakeswap/uikit'
import React from 'react'
import styled from 'styled-components'

export default function PetInfo({ branch, star, rank }) {   
    const getIconPetRank = (value) =>{
        switch (value) {
            case 0:
                return <img src='/images/type-nft/icon-bronze.png' alt="Hydros_thuy" />
            case 1:
                return <img src='/images/type-nft/icon-sliver.png' alt="Cthon_tho" />
            case 2:
                return <img src='/images/type-nft/icon-gold.png' alt="Dendra_moc" />
            case 3:
                return <img src='/images/type-nft/icon-platinum.png' alt="pyros-hoa" />
            case 4:
                return <img src='/images/type-nft/icon-diamond.png' alt="Axion_kim" />
            default:
                break;
        }
        return <></>
    }

    const getUrlPet = (value) => {
        switch (value) {
            case 'Water':
                return <img src='/images/type-nft/icon-hydro_thuy.png' alt="Hydro_thuy" />
            case 'Earth':
                return <img src='/images/type-nft/icon-cthon_tho.png' alt="Cthon_tho" />
            case 'Wood':
                return <img src='/images/type-nft/icon-dendra_moc.png' alt="Dendra_moc" />
            case 'Fire':
                return <img src='/images/type-nft/icon-pyros_hoa.png' alt="pyros-hoa" />
            case 'Metal':
                return <img src='/images/type-nft/icon-axion_kim.png' alt="Axion_kim" />
            default:
                break;
        }
        return <></>
    }

    const getStar = (value) => {
        switch (value) {
            case 1:
                return <div className="star-nft">
                    <img src='/images/type-nft/sao.png' alt="sao" />
                </div>
            case 2:
                return <div className="star-nft">
                    <img src='/images/type-nft/sao.png' alt="sao" />
                    <img src='/images/type-nft/sao.png' alt="sao" />
                </div>
            case 3:
                return <div className="star-nft">
                    <img src='/images/type-nft/sao.png' alt="sao" />
                    <img src='/images/type-nft/sao.png' alt="sao" />
                    <img src='/images/type-nft/sao.png' alt="sao" />
                </div>
            case 4:
                return <div className="star-nft">
                    <img src='/images/type-nft/sao.png' alt="sao" />
                    <img src='/images/type-nft/sao.png' alt="sao" />
                    <img src='/images/type-nft/sao.png' alt="sao" />
                    <img src='/images/type-nft/sao.png' alt="sao" />
                </div>
            default:
                break;
        }
        return <></>
    }

    return (<>
        {rank !== null && <Rating className='pet-rating'>{getIconPetRank(rank)}</Rating> }
        <Branch className='pet-type'>
            {getStar(star)}
            {getUrlPet(branch)}
        </Branch>
    </>

    )
}


const Rating = styled.div`
    position: absolute;
    top:8px;
    left:8px;
    img {
        border:none!important;
    }
  > img {
    position: relative;
    width: 30px!important;
    height: 30px!important;
    z-index: 2;   
  }
`

const Branch = styled.div`
  img {
    border:none!important;
  }

  > img {
    max-width: 35px;
    height: 35px;
    position: relative;
    z-index: 2;
    ${({ theme }) => theme.mediaQueries.sm} {
        max-width: 30px!important;
        height: 30px!important;
    } 
  }

  .star-nft {
    position: relative;
    background: #0000007a;
    left: 8px;
    z-index: 0;
    padding: 4px 14px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    ${({ theme }) => theme.mediaQueries.sm} {
        padding: 2px 14px;
    } 
    img {
        max-width: 16px;
        height: 16px;
        ${({ theme }) => theme.mediaQueries.sm} {
            max-width: 14px;
            height: 14px;
        } 
      }    
  }
  display:flex;
  align-items: center;
  position: absolute;
  top:8px;
  right:8px!important;
`