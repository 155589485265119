import React from 'react';
import { Button, Flex, useMatchBreakpoints, useModal } from '@pancakeswap/uikit';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getMowaAddress } from 'utils/addressHelpers';
import { onHowToUse } from 'utils/utilsFun';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';

const LoginDiv = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover{
    color: ${({ theme }) => theme.colors.primary};
  }
`
const RegisterDiv = styled.div`
  color: ${({ theme }) => theme.colors.text};
  font-size: 16px;
  margin-left: 10px;
  align-items: center;
  display: flex;
  cursor: pointer;

  &:hover{
    color: ${({ theme }) => theme.colors.primary};
  }
`
const ButtonBuy = styled.div`
  color: #fff;
  background: linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%);
  border-radius: 10px;
  box-shadow:none;
  width: 147px;
  max-width: 147px;
  height: 40px;
  font-size:14px;
  font-weight:bold;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left:20px ;
`

// let isOpenModalRegister = false
const IS_CHECK_ENV = process.env.REACT_APP_ENV;
const ProfileMenu = () => {
    const { isDesktop } = useMatchBreakpoints()
    const LinkTo = `https://pancakeswap.finance/swap?outputCurrency=${getMowaAddress()}`;       
    const [cookies] = useCookies(['auth']);
    const userLogin = cookies.auth     

    const [onLoginModal] = useModal(<LoginModal />, false)
    const [onRegisterModal] = useModal(<RegisterModal />, false)


    return (
        // <Flex mr="20px" className="hide" style={{visibility: 'hidden'}}>
        <Flex mr="20px">
            {isDesktop && !userLogin && <Button onClick={() => onHowToUse(`${process.env.REACT_APP_URL_GAME_PLAY}?user=trial`)} ml="20px" style={{ backgroundColor: 'transparent', height: 40, color: '#E5A95A', border: '1px solid #E5A95A', borderRadius: 10 }} >Try now</Button>}
            {isDesktop && <ButtonBuy onClick={()=> onHowToUse(LinkTo)} >Buy $MOWA</ButtonBuy>}
            <Flex style={{ display: 'none', marginLeft: 15 }}>
                <LoginDiv onClick={onLoginModal} id="loginModal">Login</LoginDiv>
                <Flex alignItems="center">/</Flex>
                <RegisterDiv onClick={onRegisterModal} id="RegisterModal">Register</RegisterDiv>
            </Flex>
        </Flex>
    )
}

export default ProfileMenu
