import MowaMiningNftAbi from 'config/abi/MowaMinningNFTAbi.json'
import { useEffect, useState } from 'react'
import { getMowaMinningNFTAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'

const usePriceNft = (account, pid, uid) => {
  const [priceNft, setPriceNft] = useState(null)  
  useEffect(() => {
    if (!account) return
    async function onCheckContract() {
      const call = [
        {
          address: getMowaMinningNFTAddress(),
          name: 'getPriceUpgrade',
          params: [pid, uid, account],
        },
      ]
      const [price] = await multicall(MowaMiningNftAbi, call)
      setPriceNft(price)
    }
    onCheckContract()
  }, [account, pid, uid])

  return priceNft
}

export default usePriceNft
