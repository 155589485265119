import BigNumber from 'bignumber.js'
import {DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL} from 'config'
import getGasPrice from 'utils/getGasPrice'
import masterChefABI from 'config/abi/masterchef.json'
import multicall from 'utils/multicall'
import {getMasterChefAddress} from 'utils/addressHelpers'

const options = {
    gasLimit: DEFAULT_GAS_LIMIT,
}

export const stakeFarm = async (masterChefContract, pid, amount, account) => {
    const gasPrice = getGasPrice()

    const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
    // if (pid === 0) {
    //   // const tx = await masterChefContract.enterStaking(value, { ...options, gasPrice })
    //   const tx = await masterChefContract.deposit(pid, value, { ...options, gasPrice })
    //   const receipt = await tx.wait()
    //   return receipt.status
    // }

    if (account) {
        const call = [
            {
                address: getMasterChefAddress(),
                name: 'pendingRewards',
                params: [pid, account],
            }
        ];
        const [info] = await multicall(masterChefABI, call)
        const earning = parseInt(info.toString());

        if (earning > 0) {
            const txWtdraw = await masterChefContract.withdraw(pid, '0', {...options, gasPrice})
            const receiptwdr = await txWtdraw.wait();
            if (receiptwdr.status) {
                const tx = await masterChefContract.deposit(pid, value, {...options, gasPrice})
                const receipt = await tx.wait();
                return receipt.status
            }
            return false;
        }
        const tx = await masterChefContract.deposit(pid, value, {...options, gasPrice})
        const receipt = await tx.wait();
        return receipt.status
    }

    const tx = await masterChefContract.deposit(pid, value, {...options, gasPrice})
    const receipt = await tx.wait();
    return receipt.status
}

export const unstakeFarm = async (masterChefContract, pid, amount) => {
    const gasPrice = getGasPrice()
    const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
    if (pid === 0) {
        // const tx = await masterChefContract.leaveStaking(value, { ...options, gasPrice })
        const tx = await masterChefContract.withdraw(pid, value, {...options, gasPrice})
        const receipt = await tx.wait()
        return receipt.status
    }

    const tx = await masterChefContract.withdraw(pid, value, {...options, gasPrice})
    const receipt = await tx.wait()
    return receipt.status
}

export const harvestFarm = async (masterChefContract, pid) => {
    const gasPrice = getGasPrice()
    if (pid === 0) {
        // const tx = await masterChefContract.leaveStaking('0', { ...options, gasPrice })
        const tx = await masterChefContract.withdraw(pid, '0', {...options, gasPrice})
        const receipt = await tx.wait()
        return receipt.status
    }

    // const tx = await masterChefContract.deposit(pid, '0', { ...options, gasPrice })
    const tx = await masterChefContract.withdraw(pid, '0', {...options, gasPrice})
    const receipt = await tx.wait()
    return receipt.status
}
