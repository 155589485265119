import tokens from './tokens'
import {NftFarmConfig} from './types'

const farms: NftFarmConfig[] = [  
    {
        pid: 0,
        lpSymbol: 'MOWA-BUSD',
        lpAddresses: {
            97: '0x603e2e17dCfe0e764614df5c63B14358988c16fb',
            56: '0x99943A4D752c8137D2ce75b4B6963EBAe2851f87',
        },
        token: tokens.mowa,
        quoteToken: tokens.busd,
    }   
]

export default farms
