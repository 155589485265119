import React from 'react'
import styled from 'styled-components'

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 375px;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width:100%;
  }

  ul.filterTabs{
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: auto;
    ${({ theme }) => theme.mediaQueries.sm} {
      width: auto;
      justify-content: flex-start;
    }
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;

      ${({ theme }) => theme.mediaQueries.lg} {
        width: 10px;
        height: 10px;
      }
    }
      ::-webkit-scrollbar-track {
      background: #14141B; 
    }
      ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius:10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555; 
    }
  }

  ul.filterTabs li{
    height: 40px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 0 20px;
    cursor: pointer;
    background: #2C3145;
    margin-bottom: 10px;
    margin-right: 15px;    
  }

  ul.filterTabs li:hover,ul.filterTabs li.active{
    background: linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%);
    border-radius: 10px;  
    color: #fff;
  }
`

const FilterControl: React.FC = ({children}) => (
  <Inner>{children}</Inner>
)

export default FilterControl
