import React from "react";
import styled from 'styled-components';
import { SvgProps } from "../../../components/Svg/types";

const StyleDiv = styled.div`
  padding-right:20px;
`
const Icon: React.FC<SvgProps> = () => {
  return (
    <StyleDiv>
		<img src="/images/multi-ring.png" alt="mowa-bridge-mowa"  style={{maxWidth:24}}/>
    </StyleDiv>
  );
};

export default Icon;
