import React, { useEffect, useCallback, useState, useMemo, useRef, memo } from 'react'
import { Route, useRouteMatch, useLocation, NavLink } from 'react-router-dom'
import { Heading, Text, Flex, useModal, Button, useMatchBreakpoints } from '@pancakeswap/uikit'
import styled, { keyframes, css } from 'styled-components'
import ButtonOutline from 'components/Layout/ButtonOutline'
import Buttons from 'components/Layout/Buttons'
import PetInfo from 'components/Layout/PetInfo'
import PopupNft from './Popup/PopupNft'



const IS_CHECK_ENV = process.env.REACT_APP_ENV;
interface props {
  itemId?: number
  data?: any,
  dataMyNft?: any
  onSelectMyNft?: (value, option) => void
  onStake?: (value) => void
  onUnstake?: (value) => void
  onUpgrade?: (value) => void
  defaultLoading?: boolean
  loadingStake?: boolean
  loadingUnStake?: boolean
  loadingUpgrade?: boolean
}

const CardItem: React.FC<props> = ({
  itemId,
  data,
  dataMyNft,
  onSelectMyNft,
  onStake,
  onUnstake,
  onUpgrade,
  defaultLoading,
  loadingStake,
  loadingUnStake,
  loadingUpgrade
}) => {
  const [dataItem, setDataItem] = useState(data)
  const [isOpen, setIsOpen] = useState(false)
  const [slotId, setSlotId] = useState(0)

  useEffect(() => {
    setDataItem(data)
  }, [data])

  useEffect(() => {
    if (slotId === dataItem?.item_key && !loadingUnStake)
      setSlotId(0)
  }, [loadingUnStake, dataItem?.item_key, slotId])

  const isLoadingStake = itemId === dataItem?.item_key ? loadingStake : null // xử lý loading khi stake 
  const isLoadingUpgrade = itemId === dataItem?.item_key ? loadingUpgrade : null // xử lý loading khi Upgrade
  const isLoadingUnstake = itemId === dataItem?.item_key ? loadingUnStake : null // xử lý loading khi Unstake 

  const _onSelectItem = value => {
    if (dataItem?.item_status === 0) {
      const _item = { ...dataItem, item_status: 1, ...value }
      onSelectMyNft(_item, 'add');
      setDataItem(_item)
    }
  }

  /**
   * 
   * @param click button 
   */
  const handleClickButton = (_status) => {   
    if (_status === 0) {
      setIsOpen(!isOpen)
    } else {
      onSelectMyNft(dataItem, 'remove');
      setDataItem(data)
    }
  }

  const _onClose = () => {
    setIsOpen(!isOpen)
  }

  const _onStake = value => {
    if (!isLoadingStake && dataItem.item_status) {
      onStake(value)
    }
  }

  const _onUnstake = value => {
    if (!isLoadingUnstake) {
      setSlotId(value?.item_key)
      onUnstake(value)
    }
  }

  const _renderHashrate = () => {
    if (dataItem?.hashRate) {
      return dataItem?.hashRate
    }
    return dataItem?.hashrate
  }

  const _onRenderButton = () => {
    if (IS_CHECK_ENV !== 'production') {
      return (
        dataItem?.tokenId ?
          <Buttons onClick={() => !loadingUnStake && !loadingStake ? _onUnstake(dataItem) : null} width="100%" color="#fff" height="40px" font-size="14px"
            style={{ marginTop: 20, marginBottom: 20, position: 'relative', background: slotId ? null : loadingUnStake || loadingStake ? '#686868' : null }}>
            {slotId ? <>
              <LoadingIcon src="/images/loading.gif" alt="loading" />
              Processing...
            </> : 'Remove'
            }
          </Buttons> :
          <Buttons onClick={() => !loadingStake && !loadingUnStake ? _onStake(dataItem) : null} width="100%" color="#fff" height="40px" font-size="14px"
            style={{ marginTop: 20, marginBottom: 20, position: 'relative', background: isLoadingStake ? null : loadingStake || loadingUnStake ? '#686868' : null }}>
            {isLoadingStake ? <>
              <LoadingIcon src="/images/loading.gif" alt="loading" />
              Processing...
            </> : 'Stake'
            }
          </Buttons>
      )
    }

    return (
      dataItem?.tokenId ?
        <Buttons onClick={() => !loadingUnStake && !loadingStake ? _onUnstake(dataItem) : null} width="100%" color="#fff" height="40px" font-size="14px"
          style={{ marginTop: 20, marginBottom: 20, position: 'relative', background: slotId ? null : loadingUnStake || loadingStake ? '#686868' : null }}>
          {slotId ? <>
            <LoadingIcon src="/images/loading.gif" alt="loading" />
            Processing...
          </> : 'Remove'
          }
        </Buttons> :
        <Buttons width="100%" color="#fff" height="40px" font-size="14px" onClick={() => _onStake(dataItem)}
          style={{ marginTop: 20, marginBottom: 20, position: 'relative', background: isLoadingStake ? null : loadingStake || loadingUnStake ? '#686868' : null }}>
          {isLoadingStake ? <>
            <LoadingIcon src="/images/loading.gif" alt="loading" />
            Processing...
          </> : 'Stake'
          }
        </Buttons>
    )
  }

  const _renderCard = () => {
    return (
      <div className="col">
        <div className="ctn">
          <Text as="div" className="addCard" /* onClick={() => !isLoadingStake ? handleClickButton(dataItem?.item_status) : null} */ >
            <div className="img">
              <img src={dataItem?.icon ? dataItem?.icon : '/images/icon-plus.png'}
                alt={dataItem?.icon ? dataItem?.icon : "plus"}
                className={defaultLoading ? 'default-loading' : dataItem?.icon ? 'img-fluid' : 'plus'}
                style={isLoadingStake && { filter: 'contrast(0.5)' }}
              />
              {dataItem?.branch && <PetInfo branch={dataItem?.branch} star={dataItem?.star} rank={dataItem?.pve_info?.rank} />}
            </div>
          </Text>
          <Text as="div" className={dataItem?.name ? 'title white' : 'title'}>
            {dataItem?.name || 'No NFT'} {dataItem?.token_list && `#${dataItem?.token_list[0]}` || dataItem?.tokenId > 0 && `#${dataItem?.tokenId}`}
          </Text>
          <Text as="p" textAlign="center" className="hashrate">
            <img src="/images/icon-fire.png" alt="icon-fire" />
            <Text as="span" color='#FF5400' bold>Hashrate: {_renderHashrate()}</Text>
          </Text>
          {/* {
            dataItem?.tokenId ?
              <Buttons onClick={() => !loadingUnStake && !loadingStake ? _onUnstake(dataItem) : null} width="100%" color="#fff" height="40px" font-size="14px"
               style={{ marginTop: 20, marginBottom: 20, position: 'relative', background: slotId ? null : loadingUnStake || loadingStake ? '#686868' : null }}>
                {slotId ? <>
                  <LoadingIcon src="/images/loading.gif" alt="loading" />
                  Processing...
                </> : 'Remove'
                }
              </Buttons> :
              <Buttons onClick={() => !loadingStake && !loadingUnStake  ? _onStake(dataItem) : null} width="100%" color="#fff" height="40px" font-size="14px"
               style={{ marginTop: 20, marginBottom: 20, position: 'relative', background: isLoadingStake ? null : loadingStake || loadingUnStake? '#686868' : null }}>
                {isLoadingStake ? <>
                  <LoadingIcon src="/images/loading.gif" alt="loading" />
                  Processing...
                </> : 'Stake'
                }
              </Buttons>
          }
          <ButtonOutline onClick={dataItem?.tokenId && !isLoadingUpgrade ? () => onUpgrade(dataItem) : null} width="100%" fontSize="14px" height="40px" color="#fff" style={!dataItem?.tokenId ? { color: '#606060', border: '1px solid #606060' } : null}>
            {isLoadingUpgrade ? <>
              <LoadingIcon src="/images/loading.gif" alt="loading" />
              Processing...
            </> : 'Upgrade Hashrate'
            }
          </ButtonOutline> */}
          {_onRenderButton()}
          {/* {IS_CHECK_ENV !== 'production' ? <ButtonOutline onClick={dataItem?.tokenId && !isLoadingUpgrade ? () => onUpgrade(dataItem) : null} width="100%" fontSize="14px" height="40px" color="#fff" style={!dataItem?.tokenId ? { color: '#606060', border: '1px solid #606060' } : null}>
            {isLoadingUpgrade ? <>
              <LoadingIcon src="/images/loading.gif" alt="loading" />
              Processing...
            </> : 'Upgrade Hashrate'
            }
          </ButtonOutline> :
          } */}
          <ButtonOutline width="100%" fontSize="14px" height="40px" color="#fff" style={!dataItem?.tokenId ? { color: '#606060', border: '1px solid #606060' } : null}>
            {isLoadingUpgrade ? <>
              <LoadingIcon src="/images/loading.gif" alt="loading" />
              Processing...
            </> : 'Upgrade Hashrate'
            }
          </ButtonOutline>
        </div>
      </div>
    )
  }

  return (
    <>
      <CardBody>
        {_renderCard()}
      </CardBody>
      {
        isOpen && <PopupNft
          isOpen={isOpen}
          dataDefault={dataMyNft}
          onRequestClose={_onClose}
          onSelectItem={_onSelectItem} />
      }

    </>
  )
}

export default memo(CardItem)


const spinningCircle = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`

const CardBody = styled.div`
  .addCard{
    background:rgba(0,0,0,0.8);
    div.img{
      border:1px solid rgba(196,196,196,0.5);
      overflow: hidden;
      border-radius: 10px;
      display:flex;
      align-items: center;
      justify-content: center;
      height: 230px; 
      position: relative;
      ${({ theme }) => theme.mediaQueries.sm} {
          height: 282px; 
      }
      &:hover{
        cursor: pointer;
        border:1px solid #E5A95A;
      }
      .pet-rating{
        > img {
          width: 35px!important ;
          height: 35px!important ;
        }
      }
      .pet-type, .pet-rating {
        right:0;
      .star-nft {
          position: relative;
          background: #0000007a;
          z-index: 0;
          padding: 2px 12px;
          display: inline-flex;
          ${({ theme }) => theme.mediaQueries.sm} {
            padding: 3px 14px;
            } 
          img {
              width: 14px!important;
              height: 14px!important;
            }    
        }
      }
    }
    
    img.img-fluid{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 9px;
    }
    img.loading{
      filter: contrast(0.5);
    }
    img.default-loading{
      animation:  ${spinningCircle} 2s infinite linear
    }
  }
  .title {
    font-size: 16px;
    color:rgba(255,255,255,0.6);
    margin:15px 0;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    &.white{
      color: #fff;
      &:hover{
        cursor: pointer;
        color:#E5A95A;
      }
    }
  }
  .hashrate{
    color: #FF5400;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const LoadingIcon = styled('img')`
  width: 20px;
  height: 20px;
  position: absolute;
  left:10px;
`
