import { Button, Text, Flex } from '@pancakeswap/uikit';
import { useHistory } from 'react-router'
import { useTranslation } from 'contexts/Localization';
import React, { useState } from 'react';
import CardPopup from 'react-modal';
import styled from 'styled-components';
import { onHowToUse } from 'utils/utilsFun';
import OutsideAlerter from 'utils/OutsideEventAlerter';

interface BountyModalProps {
  data?: any,
  isOpen?: boolean,
  title?: string,
  onRequestClose?: (value) => void,
  onAfterOpen?: () => void,
  content?: any,
  typeAlert?: string
}

const PopupComfirm: React.FC<BountyModalProps> = ({ data, isOpen, content, typeAlert, onRequestClose, onAfterOpen, title }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [tooltip, setTooltip] = useState(false)
  const [isErr, setIsErr] = useState(false)

  const onOK = () => {
    if (typeAlert === 'Rename-1') {
      onRequestClose('Rename-1')
      return
    }
    if (typeAlert === 'Rename') {
      if (data === 0) {
        setIsErr(true)
        return
      }
      onRequestClose('Rename')
      return
    }

    onRequestClose('ok')
    history.push('/profile')
  }



  const renderHeader = () => {
    if (typeAlert) {
      return <div className="modal-header bg-alert">
        <div className="title">
          <Text ml="30px" as="span" bold fontSize="18px" color="#fff">Alert</Text>
        </div>
        <button type="button" className="close" onClick={() => onRequestClose(null)}>
          <img src="/images/close.png" alt="close" />
        </button>
      </div>
    }

    return <div className="modal-header">
      <div className="title">
        <Text ml="30px" as="span" bold fontSize="18px" color="#fff">Warning</Text>
      </div>
      <button type="button" className="close" onClick={() => onRequestClose(null)}>
        <img src="/images/close.png" alt="close" />
      </button>
    </div>
  }

  const renderContent = () => {
    switch (typeAlert) {
      case 'Forgot-pass':
        return <div className="modal-content">
          <Text fontSize='18px' mb="30px">Please update your true email to change the password.</Text>
          <Button onClick={onOK}
            style={{
              width: '100%',
              height: 56,
              borderRadius: 10,
              background: 'linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%)'
            }}>Update Email</Button>
        </div>
      case 'Rename-1':
        return <div className="modal-content">
          <Text fontSize='18px'>Are you sure to change your name to:</Text>
          <Text fontSize='18px' mb="30px" color='#E5A95A'>{content}</Text>
          <Button onClick={onOK}
            style={{
              width: '100%',
              height: 56,
              borderRadius: 10,
              background: 'linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%)'
            }}>Ok</Button>
        </div>
      case 'Rename':
        return <div className="modal-content">
          <Text fontSize='18px' mb="3px">New name:<Text as="span" fontSize='18px' color='#E5A95A' ml="5px">{content}</Text></Text>
          <Flex mb={!isErr ? 30 : 0} alignItems="center"><Text pr="5px">Fee: 1</Text><Text as="span" className="text-gem" onClick={() => setTooltip(!tooltip)} >Amber</Text></Flex>
          {isErr && <Text mb="14px" color="red">You don&apos;t have enough Amber</Text>}
          <Button onClick={onOK}
            style={{
              width: '100%',
              height: 56,
              borderRadius: 10,
              background: 'linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%)'
            }}>OK</Button>
          {tooltip ? <>
            <Text className='tooltips'>
              Create at <Text
                as="span"
                bold
                onClick={() => onHowToUse('/gem-upgrade')}>
                Upgrade</Text>, or buy it at <Text as="span" bold onClick={() => onHowToUse('/nft/market')}>Market</Text>
              <Text className='close-tooltip' onClick={() => setTooltip(!tooltip)}><img src="/images/close.png" alt="close" /></Text>
            </Text>
            <img src='/images/icon-arrow-down.png' alt="icon-arrow-down" className='arrow-down' />
          </> : <></>
          }
        </div>
      default:
        return <div className="modal-content">
          <Text fontSize='18px' mb="30px">Your email hasn&apos;t been verified, please go to “My Account” to verify it.</Text>
          <Button onClick={onOK}
            style={{
              width: '100%',
              height: 56,
              borderRadius: 10,
              background: 'linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%)'
            }}>OK
          </Button>
        </div>
    }

  }


  return (
    <CardPopup
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={onAfterOpen}
      closeTimeoutMS={300}
      style={typeAlert ? customStyles2 : customStyles}
      shouldCloseOnOverlayClick={false}
    >
      <ContentModal>
        {renderHeader()}
        {renderContent()}
      </ContentModal>
    </CardPopup>
  )
}

export default PopupComfirm
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 21,
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    overflow: 'initial',
    position: 'initial'
  },
};

const customStyles2 = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 21,
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    overflow: 'initial',
    position: 'initial'
  },
};


CardPopup.setAppElement('#root');

const ContentModal = styled.div`
  background: #1B212F;    
  width:380px;
  border-radius: 20px;
  div.img-card {
    margin-top: 20px;
    width: 278px;
    height: 362px;
    margin: auto;
    background: linear-gradient(360deg,#BF893D 0%,#FFD493 137.5%);
    border-radius: 5px;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1px;
    img{
      width: 100%;
      height: auto;
    }
  } 
  div.modal-header {
    background: #2C3145;
    height: 80px;
    display: flex;
    align-items: center;
    position:relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    button.close {
      position: absolute;
      right: 15px;
      width: 50px;
      height: 50px;
      display: block;
      justify-items: center;
      text-align: center;
      padding-top: 7px;
      border:none;
      background: transparent;
      &:hover{
        cursor: pointer;
      }
    }
    &.bg-alert{
      background: #2C3145;
    }
  }

  div.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:30px;
    position: relative;
    .text-gem {
      color:#E5A95A;
      text-decoration: underline;
      &:hover{
        cursor: pointer;
      }
      position: relative;
      z-index: 10 ;
    }
    .tooltips {
      position: absolute;
      top: -12px;
      background: rgb(0, 0, 0);
      padding: 22px 20px;
      border-radius: 15px;
      span {
        text-decoration: underline;
        &:hover{
          cursor: pointer;
        }
      }
      .close-tooltip{
        position:absolute ;
        top:5px;
        right:10px;
        &:hover{
          cursor: pointer;
        }
      }
    }
    .arrow-down {
      position: absolute;
      top: 56px;
      width: 16px;
      right: 158px;
    }
  }
`