import { Button, Text } from '@pancakeswap/uikit';
import React from 'react';
import CardPopup from 'react-modal';
import styled from 'styled-components';

interface BountyModalProps {
  data?: any,
  isOpen?: boolean,
  title?: string,
  onRequestClose?: (value) => void,
  onAfterOpen?: () => void,
  content?: any,
  typeAlert?: string
}

const PopupGiftBox: React.FC<BountyModalProps> = ({ isOpen, onRequestClose, onAfterOpen }) => {

  const onOK = () => {
    onRequestClose('Gift-ok')
  }

  const renderHeader = () => {
    return <div className="modal-header bg-alert">
      <div className="title">
        <Text ml="30px" as="span" bold fontSize="18px" color="#fff">Beginner&apos;s gift</Text>
      </div>
      <button type="button" className="close" onClick={() => onRequestClose(null)}>
        <img src="/images/close.png" alt="close" />
      </button>
    </div>

  }

  const renderContent = () => {
    return <div className="modal-content">
      <img src='/images/box-gift.png' alt='box-gift' />
      <Text fontSize='18px' bold mt='20px' mb="20px">Airdrop Box</Text>
      <Button onClick={onOK}
        style={{
          width: '100%',
          height: 56,
          borderRadius: 10,
          background: 'linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%)'
        }}>Ok</Button>
    </div>

  }


  return (
    <CardPopup
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={onAfterOpen}
      closeTimeoutMS={300}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
    >
      <ContentModal>
        {renderHeader()}
        {renderContent()}
      </ContentModal>
    </CardPopup>
  )
}

export default PopupGiftBox
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 101,
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    overflow: 'initial',
    position: 'initial'
  },
};

CardPopup.setAppElement('#root');

const ContentModal = styled.div`
  background: #1B212F;    
  width:380px;
  border-radius: 20px;
  div.img-card {
    margin-top: 20px;
    width: 278px;
    height: 362px;
    margin: auto;
    background: linear-gradient(360deg,#BF893D 0%,#FFD493 137.5%);
    border-radius: 5px;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1px;
    img{
      width: 100%;
      height: auto;
    }
  } 
  div.modal-header {
    background: #ED4B9E;
    height: 80px;
    display: flex;
    align-items: center;
    position:relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    button.close {
      position: absolute;
      right: 15px;
      width: 50px;
      height: 50px;
      display: block;
      justify-items: center;
      text-align: center;
      padding-top: 7px;
      border:none;
      background: transparent;
      &:hover{
        cursor: pointer;
      }
    }
    &.bg-alert{
      background: #2C3145;
    }
  }

  div.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:30px;  
  }
`