import { Flex, Text } from '@pancakeswap/uikit';
import React from 'react';
import styled from 'styled-components';
import { convertTodate } from "utils/utilsFun";

interface Props {
  data?: any,
  onPress?: (value) => void
}


const ItemNofify: React.FC<Props> = ({ data, onPress }) => {   
  return (
    <WapperItem onClick={()=>onPress(data)}>
      <div className="notify-icon">
        <img src="/images/icon-success.png" alt="icon-success" />
      </div>
      <div className="notify-content">
        <Text fontSize="14px" bold mb="5px">Success Message</Text>
        <Text fontSize="12px" fontWeight={400} style={(data?.function === 'sendPet' || data?.function === 'fillBuyItems') ? {wordBreak: 'break-all'} : null } >{data?.msg}</Text>
        <Text fontSize="10px" color="rgba(255,255,255,0.5)" textAlign='right'>{convertTodate(data?.timeCreate)}</Text>
      </div>
    </WapperItem>
  )
}

export default ItemNofify

const WapperItem = styled(Flex)`      
    justify-content: center;
    padding: 20px;
    gap: 16px;
    border-bottom:1px solid rgba(255,255,255,0.1);
    .notify-icon {
      padding-top:3px;
      img {
        max-width:24px;
        height:auto;
      }
    }
    background-color:#1B212F;
    &:hover{
      cursor: pointer;
      background-color:#2C3145
    }
`
