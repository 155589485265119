const nftsContract = {
  artShark: {
    symbol: 'AKS',
    address: {
      56: '0xFf23efb219f41847c4e5b1e1BE35aDEBB48d65D1',
      97: ''
    }
  },
}

export default nftsContract