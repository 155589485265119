import { NftFarm } from 'state/types'
import fetchPublicFarmData from './fetchPublicFarmData'



const fetchFarm = async (farm: NftFarm): Promise<NftFarm> => {
  const farmPublicData = await fetchPublicFarmData(farm)

  return { ...farm, ...farmPublicData }
}

export default fetchFarm
