import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

import {
    getBep20Contract,
    getCakeContract,
    getBunnyFactoryContract,
    getBunnySpecialContract,
    getPancakeRabbitContract,
    getProfileContract,
    getIfoV1Contract,
    getIfoV2Contract,
    getMasterchefContract,
    getPointCenterIfoContract,
    getSouschefContract,
    getClaimRefundContract,
    getTradingCompetitionContract,
    getEasterNftContract,
    getErc721Contract,
    getCakeVaultContract,
    getPredictionsContract,
    getChainlinkOracleContract,
    getSouschefV2Contract,
    getLotteryV2Contract,
    getBunnySpecialCakeVaultContract,
    getBunnySpecialPredictionContract,
    getFarmAuctionContract,
    getBunnySpecialLotteryContract,
    getAirdropContract,
    getBabysharkContract,
    getPoolNftContract,
    getMemberOfKSharkContract,
    getMowaContract,
    getManagerContract,
    getBlindBoxContract,
    getMarketContract,
    getMowaNFTContract,
    getMasterchefNftContract,
    getClaimNFTContract,
    getMowaMiningNFTContract,
    getClaimMultiNFTContract,
    getMowaTestContract,
    getClaimTokenContract,
    getMowaMiningNFTNewContract,
    getMarketNewContract,
    getMowaNFTItemsContract,
    getMowaOpenBoxContract,
    getMowaOpenBoxV2Contract,
    getMowaNFTUpgradeGemContract,
    getMowaClaimTokenEventRewardContract,
    getMowaClaimTokenPresaleContract,
    getMowaClaimTokenPresaleV2Contract,
    getMowaNFTV2Contract,
    getMowaGiftNFTContract,
    getMarketV2Contract,
    getMarketV3Contract,
    getMarketV4Contract,
    getMarketV5Contract,
    getMarketV6Contract,
    getPetCombineContract,
    getPetUpgradeLevelContract,
    getMasterchefNftV2Contract,
    getWheelLuckyContract,
    getMowaAuctionContract,
    getMowaTokenAirdropContract,
    getMowaSpinContract,
    getPetSkillUpgradeContract,
    getShopNFTItemsContract,
    getRenameUserContract,
    getWealthyGameContract,
    getOpenAirdropBoxContract,
    getBridgeMowaToCscContract,
    getMowaContractByChain,
    getBlindboxCscContract,
    getMowaNFTByChainContract,
    getMowaNFTItemsByChainContract,
    getMowaMarketCscContract,
    getGiftNFTCscContract,
    getMowaMarketV7Contract,
    getMagicBoxCscContract,
    getAirdropBoxCscContract,    
    getLockedTokenContract,// eslint-disable-next-line import/named
    getWIDIContract,// eslint-disable-next-line import/named
    getZUKIContract,// eslint-disable-next-line import/named
    getREALContract,// eslint-disable-next-line import/named
    getHTDContract,// eslint-disable-next-line import/named
    getMYRAContract,// eslint-disable-next-line import/named
    getMILKYContract,// eslint-disable-next-line import/named
    getBCOINContract,// eslint-disable-next-line import/named
    getCATOContract,// eslint-disable-next-line import/named
    getNINOContract,// eslint-disable-next-line import/named
    getFAMContract,// eslint-disable-next-line import/named
    getMRSContract,// eslint-disable-next-line import/named
    getCOMICContract,// eslint-disable-next-line import/named
    getBELLYContract,// eslint-disable-next-line import/named
    getTBLContract,// eslint-disable-next-line import/named
    getCALOContract,// eslint-disable-next-line import/named
    getQZAContract,// eslint-disable-next-line import/named
    getCOWSContract,// eslint-disable-next-line import/named
    getROFIContract,// eslint-disable-next-line import/named
    getGWTContract,// eslint-disable-next-line import/named
    getLUSContract,// eslint-disable-next-line import/named
    getMXYContract,// eslint-disable-next-line import/named
    getGENIContract
} from 'utils/contractHelpers'

import { getMulticallAddress } from 'utils/addressHelpers'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@pancakeswap/sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import multiCallAbi from '../config/abi/Multicall.json'
import { getContract } from '../utils'


/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useIfoV1Contract = (address: string) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getIfoV1Contract(address, library.getSigner()), [address, library])
}

export const useIfoV2Contract = (address: string) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getIfoV2Contract(address, library.getSigner()), [address, library])
}

export const useERC20 = (address: string) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBep20Contract(address, library.getSigner()), [address, library])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getErc721Contract(address, library.getSigner()), [address, library])
}

export const useCake = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getCakeContract(library.getSigner()), [library])
}

export const useBabyShark = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBabysharkContract(library.getSigner()), [library])
}

export const useBunnyFactory = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnyFactoryContract(library.getSigner()), [library])
}

export const usePancakeRabbits = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPancakeRabbitContract(library.getSigner()), [library])
}

export const useProfile = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getProfileContract(library.getSigner()), [library])
}

export const useLotteryV2Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getLotteryV2Contract(library.getSigner()), [library])
}

export const useMasterchef = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMasterchefContract(library.getSigner()), [library])
}
export const useMasterchefNft = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMasterchefNftContract(library.getSigner()), [library])
}
export const useMasterchefNftV2 = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMasterchefNftV2Contract(library.getSigner()), [library])
}

export const useSousChef = (id) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getSouschefContract(id, library.getSigner()), [id, library])
}

export const usePoolNft = (id) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPoolNftContract(id, library.getSigner()), [id, library])
}

export const useSousChefV2 = (id) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getSouschefV2Contract(id, library.getSigner()), [id, library])
}

export const usePointCenterIfoContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPointCenterIfoContract(library.getSigner()), [library])
}

export const useBunnySpecialContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnySpecialContract(library.getSigner()), [library])
}

export const useClaimRefundContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getClaimRefundContract(library.getSigner()), [library])
}

export const useTradingCompetitionContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getTradingCompetitionContract(library.getSigner()), [library])
}

export const useEasterNftContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getEasterNftContract(library.getSigner()), [library])
}

export const useCakeVaultContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getCakeVaultContract(library.getSigner()), [library])
}

export const usePredictionsContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPredictionsContract(library.getSigner()), [library])
}

export const useChainlinkOracleContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getChainlinkOracleContract(library.getSigner()), [library])
}

export const useSpecialBunnyCakeVaultContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnySpecialCakeVaultContract(library.getSigner()), [library])
}

export const useSpecialBunnyPredictionContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnySpecialPredictionContract(library.getSigner()), [library])
}

export const useBunnySpecialLotteryContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnySpecialLotteryContract(library.getSigner()), [library])
}

export const useAirdrop = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getAirdropContract(library.getSigner()), [library])
}

export const useMemberOfKShark = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMemberOfKSharkContract(library.getSigner()), [library])
}

export const useFarmAuctionContract = () => {
    const { account, library } = useActiveWeb3React()
    // This hook is slightly different from others
    // Calls were failing if unconnected user goes to farm auction page
    // Using library instead of library.getSigner() fixes the problem for unconnected users
    // However, this fix is not ideal, it currently has following behavior:
    // - If you visit Farm Auction page coming from some other page there are no errors in console (unconnected or connected)
    // - If you go directly to Farm Auction page
    //   - as unconnected user you don't see any console errors
    //   - as connected user you see `unknown account #0 (operation="getAddress", code=UNSUPPORTED_OPERATION, ...` errors
    //     the functionality of the page is not affected, data is loading fine and you can interact with the contract
    //
    // Similar behavior was also noticed on Trading Competition page.
    return useMemo(() => getFarmAuctionContract(account ? library.getSigner() : library), [library, account])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
    const { library, account } = useActiveWeb3React()

    return useMemo(() => {
        if (!address || !ABI || !library) return null
        try {
            return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useActiveWeb3React()
    return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useActiveWeb3React()
    let address: string | undefined
    if (chainId) {
        // eslint-disable-next-line default-case
        switch (chainId) {
            case ChainId.MAINNET:
            case ChainId.TESTNET:
                address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
                break
        }
    }
    return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
    return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
    return useContract(getMulticallAddress(), multiCallAbi, false)
}

export const useMowaContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaContract(library.getSigner()), [library])
}

export const useManagerContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getManagerContract(library.getSigner()), [library])
}

export const useBlindBoxContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBlindBoxContract(library.getSigner()), [library])
}
export const useMarketContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMarketContract(library.getSigner()), [library])
}
export const useMowaNFTContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaNFTContract(library.getSigner()), [library])
}
export const useMowaNFTV2Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaNFTV2Contract(library.getSigner()), [library])
}
export const useClaimNftContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getClaimNFTContract(library.getSigner()), [library])
}
export const useMowaMinningNftContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaMiningNFTContract(library.getSigner()), [library])
}
export const useMowaMinningNftNewContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaMiningNFTNewContract(library.getSigner()), [library])
}
export const useClaimMultiNFTContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getClaimMultiNFTContract(library.getSigner()), [library])
}
export const useMowaTestContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaTestContract(library.getSigner()), [library])
}
export const useClaimTokenContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getClaimTokenContract(library.getSigner()), [library])
}
export const useMarketNewContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMarketNewContract(library.getSigner()), [library])
}
export const useMomwaNFTItemsContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaNFTItemsContract(library.getSigner()), [library])
}
export const useMowaOpenBoxContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaOpenBoxContract(library.getSigner()), [library])
}
export const useMowaOpenBoxV2Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaOpenBoxV2Contract(library.getSigner()), [library])
}
export const useMowaNFTUpgradeGemContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaNFTUpgradeGemContract(library.getSigner()), [library])
}
export const useMowaClaimTokenEventRewardContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaClaimTokenEventRewardContract(library.getSigner()), [library])
}
export const useMowaClaimTokenPresaleContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaClaimTokenPresaleContract(library.getSigner()), [library])
}
export const useMowaClaimTokenPresaleV2Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaClaimTokenPresaleV2Contract(library.getSigner()), [library])
}
export const useMowaGiftNFTContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaGiftNFTContract(library.getSigner()), [library])
}
export const useMarketV2Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMarketV2Contract(library.getSigner()), [library])
}
export const useMarketV3Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMarketV3Contract(library.getSigner()), [library])
}
export const useMarketV4Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMarketV4Contract(library.getSigner()), [library])
}
export const useMarketV5Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMarketV5Contract(library.getSigner()), [library])
}
export const useMarketV6Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMarketV6Contract(library.getSigner()), [library])
}
export const usePetCombineContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPetCombineContract(library.getSigner()), [library])
}
export const usePetUpgradeLevelContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPetUpgradeLevelContract(library.getSigner()), [library])
}
export const useWheelLuckyContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getWheelLuckyContract(library.getSigner()), [library])
}
export const useLockedTokenContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getLockedTokenContract(library.getSigner()), [library])
}
export const useMowaAuctionContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaAuctionContract(library.getSigner()), [library])
}

export const useMowaTokenAirdropContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaTokenAirdropContract(library.getSigner()), [library])
}
export const useMowaSpinContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaSpinContract(library.getSigner()), [library])
}
export const usePetSkillUpgradeContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPetSkillUpgradeContract(library.getSigner()), [library])
}
export const useShopNFTItemsContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getShopNFTItemsContract(library.getSigner()), [library])
}
export const useRenameUserContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getRenameUserContract(library.getSigner()), [library])
}
export const useWealthyGameContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getWealthyGameContract(library.getSigner()), [library])
}
export const useOpenAirdropBoxContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getOpenAirdropBoxContract(library.getSigner()), [library])
}

export const useMowaContractByChain = () => {
    const { library, chainId } = useActiveWeb3React() // get mowa by chain
    return useMemo(() => getMowaContractByChain(library.getSigner(), chainId), [library, chainId])
}

export const useBridgeMowaToCscContract = () => {
    const { library, chainId } = useActiveWeb3React() // getBridgeMowaToCscContract
    return useMemo(() => getBridgeMowaToCscContract(library.getSigner(), chainId), [library, chainId])
}

export const useBlindboxCscContract = () => {
    const { library, chainId } = useActiveWeb3React()
    return useMemo(() => getBlindboxCscContract(library.getSigner(), chainId), [library, chainId])
}

export const useMowaNftByChainContract = () => {
    const { library, chainId } = useActiveWeb3React()
    return useMemo(() => getMowaNFTByChainContract(library.getSigner(), chainId), [library, chainId])
}

export const useMowaNftItemsByChainContract = () => {
    const { library, chainId } = useActiveWeb3React()
    return useMemo(() => getMowaNFTItemsByChainContract(library.getSigner(), chainId), [library, chainId])
}
export const useMowaMarketCscContract = () => {
    const { library, chainId } = useActiveWeb3React()
    return useMemo(() => getMowaMarketCscContract(library.getSigner(), chainId), [library, chainId])
}
export const useGiftNFTCscContract = () => {
    const { library, chainId } = useActiveWeb3React()
    return useMemo(() => getGiftNFTCscContract(library.getSigner(), chainId), [library, chainId])
}

export const useMarketV7Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMowaMarketV7Contract(library.getSigner()), [library])
}

export const useMagicBoxCscContract = () => {
    const { library, chainId } = useActiveWeb3React()
    return useMemo(() => getMagicBoxCscContract(library.getSigner(), chainId), [library, chainId])
}

export const useAirdropBoxCscContract = () => {
    const { library, chainId } = useActiveWeb3React()
    return useMemo(() => getAirdropBoxCscContract(library.getSigner(), chainId), [library, chainId])
}
export const useWIDIContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getWIDIContract(library.getSigner()), [library])
}
export const useZUKIContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getZUKIContract(library.getSigner()), [library])
}
export const useREALContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getREALContract(library.getSigner()), [library])
}
export const useHTDContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getHTDContract(library.getSigner()), [library])
}
export const useMYRAContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMYRAContract(library.getSigner()), [library])
}
export const useMILKYContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMILKYContract(library.getSigner()), [library])
}
export const useBCOINContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBCOINContract(library.getSigner()), [library])
}
export const useCATOContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getCATOContract(library.getSigner()), [library])
}
export const useNINOContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getNINOContract(library.getSigner()), [library])
}
export const useFAMContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getFAMContract(library.getSigner()), [library])
}
export const useMRSContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMRSContract(library.getSigner()), [library])
}
export const useCOMICContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getCOMICContract(library.getSigner()), [library])
}
export const useBELLYContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBELLYContract(library.getSigner()), [library])
}
export const useTBLContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getTBLContract(library.getSigner()), [library])
}
export const useCALOContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getCALOContract(library.getSigner()), [library])
}
export const useQZAContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getQZAContract(library.getSigner()), [library])
}
export const useCOWSContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getCOWSContract(library.getSigner()), [library])
}
export const useROFIContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getROFIContract(library.getSigner()), [library])
}
export const useGWTContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getGWTContract(library.getSigner()), [library])
}
export const useLUSContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getLUSContract(library.getSigner()), [library])
}
export const useMXYContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMXYContract(library.getSigner()), [library])
}
export const useGENIContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getGENIContract(library.getSigner()), [library])
}
