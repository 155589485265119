import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_NFT_LIMIT, DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from 'config'
import getGasPrice from 'utils/getGasPrice'
import masterChefABI from 'config/abi/masterchefNft.json'
import multicall from 'utils/multicall'
import { getMasterChefNftAddress } from 'utils/addressHelpers'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import erc20 from 'config/abi/erc20.json'

const optionsNFT = {
  gasLimit: 4000000,
}
const optionsToken = {
  gasLimit: DEFAULT_GAS_LIMIT,
}

const gasPrice = getGasPrice()

export const stakeMiningNFT = async (mowaMinningNftContract, pid, uid, _tokenId) => {
  const tx = await mowaMinningNftContract.depositNFT(pid, uid, _tokenId, { ...optionsNFT, gasPrice })
  const receipt = await tx.wait()
  return [receipt.status, tx.hash]
}

export const unstakeMinningNFT = async (mowaMinningNftContract, pid, uid) => {
  const tx = await mowaMinningNftContract.unStake(pid, uid, { ...optionsNFT, gasPrice })
  const receipt = await tx.wait()
  return [receipt.status, tx.hash]
}

export const upgradeMinningNFT = async (mowaMinningNftContract, pid, uid, number) => {
  const tx = await mowaMinningNftContract.upgrade(pid, uid, number, { ...optionsToken, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

// disposit farm nft
export const harvestMiningNft = async (mowaMinningNftContract, pid) => {
  const tx = await mowaMinningNftContract.harvest(pid,{ ...optionsToken, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}
