import axios from 'axios'
import BigNumber from 'bignumber.js'

export const getTotalSupplyPrices = async(address: any) => {
    try {
        const { data } = await axios.get(`https://api.pancakeswap.info/api/v2/tokens/${address}`).then(resp=>resp.data).catch(e=>e)
        const price = new BigNumber(data.price).toNumber()
        return price
    } catch {
        return 0
    }
}