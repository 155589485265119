import { ResetCSS, connectorLocalStorageKey } from '@pancakeswap/uikit';
import BigNumber from 'bignumber.js';
import { DatePickerPortal } from 'components/DatePicker';
import PopupHomeWheel from 'components/LuckyWheel/PopupHome';
import BtnMowaSpin from 'components/MowaSpinBtn';
import SigupComponent from 'components/SignUpCom';
import useEagerConnect from 'hooks/useEagerConnect';
import React, { lazy, useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, Redirect, Route, Router, Switch } from 'react-router-dom';
import styled from 'styled-components';
import NftMining from 'views/NftMining';
import Menu from './components/Menu';
import SuspenseWithChunkError from './components/SuspenseWithChunkError';
import { ToastListener } from './contexts/ToastsContext';
import GlobalStyle from './style/Global';
// import PageLoader from './components/Loader/PageLoader'
import EasterEgg from './components/EasterEgg';
import PageLoader from './components/Loading/index';
import history from './routerHistory';
// Views included in the main bundle
import MoniMining from './views/MoniMining';
import Pools from './views/Pools';
import useActiveWeb3React from './hooks/useActiveWeb3React'

import GlobalCheckClaimStatus from './views/Collectibles/components/GlobalCheckClaimStatus';


// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const NftMarket = lazy(() => import('./views/NftMarket'))
const NftMarketDetail = lazy(() => import('./views/NftMarket/NftMarketDetail'))
const NftAution = lazy(() => import('./views/NftAution'))
const NftAutionDetail = lazy(() => import('./views/NftAution/NftAutionDetail'))
const NftBlinkBox = lazy(() => import('./views/NftBlinkBox'))
const NftGemUpgrade = lazy(() => import('./views/NftGemUpgrade'))
const NFTPetCombine = lazy(() => import('./views/NFTPetCombine'))
const NftPetUpgrade = lazy(() => import('./views/NftPetUpgrade'))
const StarUpgrade = lazy(() => import('./views/StarUpgrade'))
const NftSkillUpgrade = lazy(() => import('./views/NftSkillUpgrade'))
const NftBlinkBoxDetail = lazy(() => import('./views/NftBlinkBox/Detail'))
const AirdropNFT = lazy(() => import('./views/AirdropNFT'))
const ClaimNFT = lazy(() => import('./views/ClaimNFT'))
const FarmAuction = lazy(() => import('./views/FarmAuction'))
const MowaTransaction = lazy(() => import('./views/MowaTransaction'))
// const Lottery = lazy(() => import('./views/Lottery'))
// const Ifos = lazy(() => import('./views/Ifos'))
const NotFound = lazy(() => import('./views/NotFound'))
const NftFarms = lazy(() => import('./views/NFTFarms'))
const Bag = lazy(() => import('./views/Bag'))
const QuestOpen = lazy(() => import('./views/QuestOpen'))
const MoniTokenAirdrop = lazy(() => import('./views/MoniTokenAridrop'))
const TestButton = lazy(() => import('./views/TestButton'))
const AddressToken = lazy(() => import('./views/AddressToken'))
const LockedToken = lazy(() => import('./views/LockedToken'))
const ComingSoon = lazy(() => import('./views/ComingSoon'))
const ProfileUser = lazy(() => import('./views/ProfileUser'))
const ClaimToken = lazy(() => import('./views/ClaimToken'))
const SurvivalLeaderBoard = lazy(() => import('./views/SurvivalLeaderBoad'))
const SurvivalReward = lazy(() => import('./views/SurvivalReward'))
const ClaimTokenV2 = lazy(() => import('./views/ClaimTokenV2'))
const MowaSpin = lazy(() => import('./views/MowaSpin'))
// const Collectibles = lazy(() => import('./views/Collectibles'))
// const Teams = lazy(() => import('./views/Teams'))
// const Team = lazy(() => import('./views/Teams/Team'))
// const Profile = lazy(() => import('./views/Profile'))
// const TradingCompetition = lazy(() => import('./views/TradingCompetition'))
// const Predictions = lazy(() => import('./views/Predictions'))
// const Voting = lazy(() => import('./views/Voting'))
// const Proposal = lazy(() => import('./views/Voting/Proposal'))
// const CreateProposal = lazy(() => import('./views/Voting/CreateProposal'))
// const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
// const Liquidity = lazy(() => import('./views/Pool'))
// const PoolFinder = lazy(() => import('./views/PoolFinder'))
// const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const MiningNFTNew = lazy(() => import('./views/NftMiningNew'))
const ContactForm = lazy(() => import('./views/ContactForm'))
const TopPvpOne = lazy(() => import('./views/TopPvpOne'))
const TopPvpTwo = lazy(() => import('./views/TopPvpTwo'))
const EnergyShop = lazy(() => import('./views/EnergyShop'))
// const EventReward = lazy(() => import('./views/EventReward'))
const SendPet = lazy(() => import('./views/SendPet'))
const WheelLucky = lazy(() => import('./views/WheelLucky'))
const PVPReward = lazy(() => import('./views/PvpReward'))
const PurchasingShop = lazy(() => import('./views/PurchasingShop'))
const NftPetUpgradeLevel = lazy(() => import('./views/NftPetUpgradeLevel'))
const EventOfflineClaim = lazy(() => import('./views/EventOfflineClaim'))
const TokenBridge = lazy(() => import('./views/MowaTokenBridge'))
const NftBridge = lazy(() => import('./views/MowaNftBridge'))
const ReportBridge = lazy(() => import('./views/MowaReportBridge'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})
const ButtonSpin = styled.div`
  background: url('/images/wheel/btn-wheel-lucky.png') center no-repeat;
  background-size: cover;  
  position: relative;
  width: 80px;
  height: 80px;
  position: fixed;
  bottom:15px;
  right:15px;
  z-index:10;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 120px;
    height: 120px;
  }
  &:hover{
    cursor: pointer;
  }
`

const App: React.FC = () => {
  // usePollBlockNumber()
  useEagerConnect()
  // useFetchProfile()
  // usePollCoreFarmData()
  const [cookies] = useCookies(['auth']);
  const userLogin = cookies.auth
  const LinkTo = '/wheel-lucky'
  const currentLocation = window.location.pathname;
  const [_isOpen, setIsOpen] = useState(false)
  const isShowRef = useRef(null)
  const DATE_NOW = Math.round(new Date().getTime() / 1000);
  const timeStamp = 1644998400; // 15:00 ngày 16/2
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (userLogin && currentLocation !== '/wheel-lucky' && !isShowRef.current && timeStamp > DATE_NOW) {
      setIsOpen(true)
    }
  }, [userLogin, currentLocation, DATE_NOW])

  useEffect(() => {
    window.addEventListener('message', function (e) {
      if (e?.data === 'No-spin') {
        const elm = document.querySelector<HTMLElement>('.btn-spin-home')!;
        if (elm) {
          elm.style.display = 'initial';
        }
      }
      if (!account) {
        if (!window?.localStorage?.getItem(connectorLocalStorageKey)) {
          document.getElementById('connect-wallet').click()
        }
      }
    }, false);
  }, [account])

  return (
    <Router history={history}>
      {userLogin && <BtnMowaSpin />}
      {(userLogin && currentLocation !== '/wheel-lucky' && timeStamp > DATE_NOW) && <ButtonSpin as={Link} to={LinkTo} />}
      <ResetCSS />
      <GlobalStyle />
      <GlobalCheckClaimStatus excludeLocations={['/collectibles']} />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/dashboard" exact>
              <Home />
            </Route>
            <Route path="/" exact>
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/farms/auction">
              <FarmAuction />
            </Route>
            <Route path="/nft/market">
              <NftMarket />
            </Route>
            <Route path="/nft/market-detail">
              <NftMarketDetail />
            </Route>
            <Route path="/nft/farms">
              <NftFarms />
            </Route>
            <Route path="/nft/aution">
              <NftAution />
            </Route>
            <Route path="/nft/aution-detail">
              <NftAutionDetail />
            </Route>
            <Route path="/nft/blind-box">
              <NftBlinkBox />
            </Route>
            <Route path="/gem-upgrade">
              <NftGemUpgrade />
            </Route>
            <Route path="/pet-combine">
              <NFTPetCombine />
            </Route>
            <Route path="/pet-level-upgrade">
              <NftPetUpgrade />
            </Route>
            <Route path="/pet-skill-upgrade">
              <NftSkillUpgrade />
            </Route>
            <Route path="/nft/blind-box-detail">
              <NftBlinkBoxDetail />
            </Route>
            <Route path="/airdrop-nft">
              <AirdropNFT />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Pools />
            </Route>
            <Route path="/mining">
              <MoniMining />
            </Route>
            <Route path="/nft/mining">
              <NftMining />
            </Route>
            <Route path="/nft/mining-new">
              <MiningNFTNew />
            </Route>
            <Route path="/bag">
              <Bag />
            </Route>
            <Route path="/quest">
              <QuestOpen />
            </Route>
            <Route path="/airdrop-token">
              <MoniTokenAirdrop />
            </Route>
            <Route path="/test-buton">
              <TestButton />
            </Route>
            <Route path="/address-token">
              <AddressToken />
            </Route>
            <Route path="/security-token">
              <LockedToken />
            </Route>
            <Route path="/coming-soon">
              <ComingSoon />
            </Route>
            <Route path="/claim-nft">
              <ClaimNFT />
            </Route>
            <Route path="/profile">
              <ProfileUser />
            </Route>
            <Route path="/claim-token-version-1">
              <ClaimToken />
            </Route>
            <Route path="/survival-leader-board">
              <SurvivalLeaderBoard />
            </Route>
            <Route path="/survival-reward">
              <SurvivalReward />
            </Route>
            <Route path="/claim-token-version-2">
              <ClaimTokenV2 />
            </Route>
            <Route path="/top-pvp-1-vs-1">
              <TopPvpOne />
            </Route>
            <Route path="/top-pvp-3-vs-3">
              <TopPvpTwo />
            </Route>
            <Route path="/energy-shop">
              <EnergyShop />
            </Route>
            {/* <Route path="/event-reward">
              <EventReward />
            </Route> */}
            <Route path="/send-pet">
              <SendPet />
            </Route>
            <Route path="/pet-star-upgrade">
              <StarUpgrade />
            </Route>
            <Route path="/wheel-lucky">
              <WheelLucky />
            </Route>
            <Route path="/mowa-transaction">
              <MowaTransaction />
            </Route>
            {/* <Route path="/lottery"> */}
            {/*   <Lottery /> */}
            {/* </Route> */}
            {/* <Route path="/ifo"> */}
            {/*   <Ifos /> */}
            {/* </Route> */}
            {/* <Route path="/collectibles"> */}
            {/*   <Collectibles /> */}
            {/* </Route> */}
            {/* <Route exact path="/teams"> */}
            {/*   <Teams /> */}
            {/* </Route> */}
            {/* <Route path="/teams/:id"> */}
            {/*   <Team /> */}
            {/* </Route> */}
            {/* <Route path="/competition"> */}
            {/*   <TradingCompetition /> */}
            {/* </Route> */}
            {/* <Route path="/prediction"> */}
            {/*   <Predictions /> */}
            {/* </Route> */}
            {/* <Route exact path="/voting"> */}
            {/*   <Voting /> */}
            {/* </Route> */}
            {/* <Route exact path="/voting/proposal/create"> */}
            {/*   <CreateProposal /> */}
            {/* </Route> */}
            {/* <Route path="/voting/proposal/:id"> */}
            {/*   <Proposal /> */}
            {/* </Route> */}

            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            {/* <Route exact strict path="/swap" component={Swap} /> */}
            {/* <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} /> */}
            {/* <Route exact strict path="/send" component={RedirectPathToSwapOnly} /> */}
            {/* <Route exact strict path="/find" component={PoolFinder} /> */}
            {/* <Route exact strict path="/liquidity" component={Liquidity} /> */}
            {/* <Route exact strict path="/create" component={RedirectToAddLiquidity} />
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact path="/create" component={AddLiquidity} />
            <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} /> */}

            {/* Redirect */}
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route>
            <Route path="/contact-form">
              <ContactForm />
            </Route>
            <Route path="/pvp-reward">
              <PVPReward />
            </Route>
            <Route path="/mowa-spin">
              <MowaSpin />
            </Route>
            <Route path="/purchasing-shop">
              <PurchasingShop />
            </Route>
            <Route path="/pet/upgrade-level">
              <NftPetUpgradeLevel />
            </Route>
            <Route path="/event-offline-claim">
              <EventOfflineClaim />
            </Route>
            <Route path="/token-bridge">
              <TokenBridge />
            </Route>
            <Route path="/nft-bridge">
              <NftBridge />
            </Route>
            <Route path="/report">
              <ReportBridge />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <SigupComponent />
      <EasterEgg iterations={2} />
      <ToastListener />
      <DatePickerPortal />
      <PopupHomeWheel isOpen={_isOpen}
        onRequestClose={(value) => {
          if (value === 'ok') {
            document.location.href = LinkTo
          }
          isShowRef.current = true
          setIsOpen(false)
        }}
      />

    </Router>
  )
}

export default React.memo(App)
