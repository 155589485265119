import tokens from './tokens'
import {FarmConfig} from './types'

const farms: FarmConfig[] = [
    /**
     * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
     */
    
    // {
    //     pid: 0,
    //     lpSymbol: 'TEST-WBNB',
    //     lpAddresses: {
    //         97: '',
    //         56: '0x7b687278c395829de6a6230f6935f1ea8ac14390',
    //     },
    //     token: tokens.mowa,
    //     quoteToken: tokens.wbnb,
    // },
    {
        pid: 0,
        lpSymbol: 'MOWA-BUSD',
        lpAddresses: {
            97: '',
            56: '0x99943A4D752c8137D2ce75b4B6963EBAe2851f87',
        },
        token: tokens.mowa,
        quoteToken: tokens.busd,
    }
    // {
    //     pid: 251,
    //     lpSymbol: 'CAKE-BNB LP',
    //     lpAddresses: {
    //         97: '0x3ed8936cAFDF85cfDBa29Fbe5940A5b0524824F4',
    //         56: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
    //     },
    //     token: tokens.cake,
    //     quoteToken: tokens.wbnb,
    // },
    // {
    //     pid: 252,
    //     lpSymbol: 'BUSD-BNB LP',
    //     lpAddresses: {
    //         97: '',
    //         56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    //     },
    //     token: tokens.busd,
    //     quoteToken: tokens.wbnb,
    // },
]

export default farms
