import { useWeb3React } from '@web3-react/core'
import axios from 'axios'
import Page from 'components/Layout/Page'
import PopupUpgrade from 'components/PopupUpgrade'
import configMining from 'config/constants/minningNft'
import { useTranslation } from 'contexts/Localization'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { useMowaContract, useMowaMinningNftContract, useMowaNFTContract } from 'hooks/useContract'
import useMyNFT from 'hooks/useMyNFT'
import useToast from 'hooks/useToast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'
import { getMowaNFTAddress } from 'utils/addressHelpers'
import { onPostSvApi } from 'utils/apiHelper'
import { stakeMiningNFT, unstakeMinningNFT } from 'utils/calls'
import CardItem from './components/CardItem'
import Header from './components/Header'
import TopContent from './components/TopContent'
import { ITEM_CONST } from './DataSource'
import userMining from './hooks/useMiningNft'

const BASE_URL = process.env.REACT_APP_BASE_URL
const URL_SERVICE = process.env.REACT_APP_URL_SERVICE;
const NftMining: React.FC = () => {
  const { t } = useTranslation()
  const [dataItems, setDataItems] = useState(ITEM_CONST)
  const [itemUpgrade, setItemUpgrade] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const { account, chainId } = useWeb3React()
  const [cookies] = useCookies(['auth']);
  const userLogin = cookies.auth
  const { callWithGasPrice } = useCallWithGasPrice()
  const { toastSuccess, toastError } = useToast()
  const [loading, setLoading] = useState(false)
  const [loading2, setLoading2] = useState(false)
  const mowaNFTContract = useMowaNFTContract()
  const mowaMiningNftContract = useMowaMinningNftContract()
  const mowaNFTAddress = getMowaNFTAddress()
  const { pid, earningToken, name } = configMining[0]
  const nftId = useRef(0)
  const [nftUns, setNftUn] = useState(0)
  const { listItem, onReload } = useMyNFT()
  const mowaContract = useMowaContract()

  const { minningInf, onRefesh } = userMining(pid)
  const [defaultLoading, setDefaultLoading] = useState(true)

  const [defaultMyNFT, setDefaultMyNFT] = useState(null)

  useEffect(() => {
    const _filter = listItem ? { ...listItem, boxs: [] } : {}
    setDefaultMyNFT(_filter)
  }, [listItem])

  useEffect(() => {
    if (!itemUpgrade) return;
    setIsOpen(true)
  }, [itemUpgrade])

  // Khởi tạo ban đầu
  //  Gọi check TokenId từ contract trả về
  //  Get thông tin pet từ api
  const loadFromContract = useCallback(async () => {
    if (minningInf) {
      const listNft = minningInf?.pooluserInfo[0].reduce((acc, cur) => {
        return [
          ...acc,
          {
            amountCalHashrate: cur.amountCalHashrate,
            hashRate: parseInt(cur.hashRate),
            tokenId: parseInt(cur.tokenId.toString()),
          }
        ]
      }, [])


      const _tokenIds = listNft?.map((_) => _.tokenId).filter(_ => _ !== 0).join()
      let respData = [];
      if (_tokenIds) {
        const url = `${BASE_URL}/nft/assets/get?token_ids=${_tokenIds}`
        respData = await axios.get(url).then(resp => {
          return resp.data;
        }).catch((error) => {
          console.log(error);
        })
      }
      const mergeNft = mergeArraysByToken(listNft, respData)
      const mergeItem = mergeArraysByUid(ITEM_CONST, mergeNft)
      setDataItems(mergeItem)
    }
  }, [minningInf])

  useEffect(() => {
    loadFromContract()
    setDefaultLoading(false)
  }, [loadFromContract])

  // merge array by token Id
  const mergeArraysByToken = (arr1 = [], arr2 = []) => {
    let res = [];
    res = arr1.map(obj => {
      const index = arr2.findIndex(el => el.token_id === obj.tokenId);
      if (index !== -1) {
        return {
          ...obj,
          ...arr2[index],
          item_status: 1
        };
      }
      return {
        ...obj
      };
    });
    return res;
  };

  // merge array by token Uid
  const mergeArraysByUid = (arr1 = [], arr2 = []) => {
    return arr1.map((obj, index) => {
      return {
        ...obj,
        ...arr2[index]
      };
    });
  };

  const _onStake = async () => {
    toastError('Error', 'Pool has ended')
  }

  // const _onStake = async (data) => {
  //   if (!account || !userLogin) return;
  //   const tokenID = data?.token_list ? data?.token_list[0] : 0;
  //   nftId.current = data.item_id
  //   setNftUn(data.item_id)
  //   const uid = data.item_id - 1;
  //   if (tokenID > 0) {
  //     setLoading(true)
  //     try {
  //       const call = [
  //         {
  //           address: getMowaAddress(),
  //           name: 'allowance',
  //           params: [account, getMowaMinningNFTAddress()],
  //         },
  //         {
  //           address: getMowaAddress(),
  //           name: 'balanceOf',
  //           params: [account],
  //         },
  //       ];

  //       const callNFT = [
  //         {
  //           address: mowaNFTAddress,
  //           name: 'getApproved',
  //           params: [tokenID]
  //         }];
  //       const [info, balanceOf] = await multicall(MowaAbi, call);
  //       const [infoNFT] = await multicall(MowaNFTAbi, callNFT)

  //       const priceAllowance = parseInt(info[0].toString());
  //       const amoutStake = 1 * 10 ** 18;
  //       const addrApprove = infoNFT[0].toString();

  //       if (parseInt(balanceOf.toString()) < amoutStake) {
  //         toastError('Error', 'Insufficient funds in the account');
  //         return;
  //       }
  //       if (priceAllowance < amoutStake) {
  //         const resp = await callWithGasPrice(mowaContract, 'approve', [getMowaMinningNFTAddress(), ethers.constants.MaxUint256])
  //         const receipt = await resp.wait();
  //         if (receipt.status) {
  //           if (addrApprove !== getMowaMinningNFTAddress()) {
  //             const res = await callWithGasPrice(mowaNFTContract, "approve", [getMowaMinningNFTAddress(), tokenID])
  //             const receiptRes = await res.wait();
  //             if (receiptRes.status) {
  //               onStakeWithUser(uid, tokenID)
  //             }
  //           } else {
  //             onStakeWithUser(uid, tokenID);
  //           }
  //         }
  //       } else if (addrApprove !== getMowaMinningNFTAddress()) {
  //         const res = await callWithGasPrice(mowaNFTContract, "approve", [getMowaMinningNFTAddress(), tokenID])
  //         const receiptRes = await res.wait();
  //         if (receiptRes.status) {
  //           onStakeWithUser(uid, tokenID)
  //         }
  //       } else {
  //         onStakeWithUser(uid, tokenID);
  //       }

  //     } catch (error) {
  //       console.log('_onStake', error);
  //       toastError('Error', error?.data?.message || error?.message)
  //       nftId.current = 0
  //       setLoading(false)
  //     }
  //   }
  // }


  // Thực hiện Stake NFT từ user
  const onStakeWithUser = async (uid, _tokenId) => {
    try {
      const [resp, hash] = await stakeMiningNFT(mowaMiningNftContract, pid, uid, _tokenId);
      if (resp) {
        const params = {
          hash,
          token_id: _tokenId,
          uid,
          pid,
          account
        }
        const url = `${URL_SERVICE}/stakemining`
        await axios.post(url, params).then(res => {
          nftId.current = 0
          setLoading(false)
          onReload() // reload lại túi đồ
          onRefesh() // reload lại contract
          toastSuccess('Stake NFT success');
        }).catch((e) => {
          nftId.current = 0
          setLoading(false)
          onReload() // reload lại túi đồ
          onRefesh() // reload lại contract
          console.log("sv onStakeWithUser", e)
        })
      }
      return
    } catch (error) {
      // thất bại lỗi gì đó
      toastError('Error', 'Stake NFT Error');
      nftId.current = 0;
      setLoading(false)
    }
  }

  const _onUnstake = async (data) => {
    if (!userLogin || !account) return
    const uid = data.item_key - 1
    // nftId.current = uid + 1      
    setLoading2(true)
    try {
      const [resp, hash] = await unstakeMinningNFT(mowaMiningNftContract, pid, uid);
      if (resp) {
        const params = {
          transaction_hash: hash,
          chain_id: chainId
        }
        onPostSvApi('nft/receive', params, account).then(respt => {
          if (respt.code) {
            setLoading2(false)
            onReload() // reload lại túi đồ
            onRefesh() // refresh lại contract
            toastSuccess('Remove NFT success');
            return
          }
          setLoading2(false)
          onReload() // reload lại túi đồ
          onRefesh() // refresh lại contract
          toastError('Error', respt.msg?.data?.data.msg)
        })
      }
      return
    } catch (error) {
      // nftId.current = 0
      // thất bại lỗi gì đó
      console.log('onUnStake', error);
      toastError('Error', 'Remove NFT Error');
      setLoading2(false)
    }
  }

  const _onUpgrade = async (value) => {
    setItemUpgrade(value)
  }

  const _onClose = () => {
    setIsOpen(!isOpen)
    setItemUpgrade(null)
  }

  const _onSuccess = async () => {
    onRefesh()
    setIsOpen(!isOpen)
  }

  const _onSelectMyNft = async (value, option) => {
    const _addNft = option === 'add' ? await addNft(value, option) : await removeNft(value, option)
    setDefaultMyNFT({ ...defaultMyNFT, ..._addNft })
  }

  // add NFT
  const addNft = (value, option) => {
    let newObj = {};
    if (option === 'add') {
      if (defaultMyNFT.pets?.length > 0) {
        const _arrPets = defaultMyNFT?.pets.map(_ => {
          if (value.item_id === _.item_id && _.qty > 0) {
            return { ..._, qty: _.qty - 1, token_list: _.token_list.slice(1) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, pets: _arrPets }
      }
      if (defaultMyNFT.cards?.length > 0) {
        const _arrCards = defaultMyNFT?.cards.map(_ => {
          if (value.item_id === _.item_id && _.qty > 0) {
            return { ..._, qty: _.qty - 1, token_list: _.token_list.slice(1) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, cards: _arrCards }
      }
      if (defaultMyNFT.gems?.length > 0) {
        const _arrGems = defaultMyNFT?.gems.map(_ => {
          if (value.item_id === _.item_id && _.qty > 0) {
            return { ..._, qty: _.qty - 1, token_list: _.token_list.slice(1) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, gems: _arrGems }
      }
      if (defaultMyNFT.tickets?.length > 0) {
        const _arrTickets = defaultMyNFT?.tickets.map(_ => {
          if (value.item_id === _.item_id && _.qty > 0) {
            return { ..._, qty: _.qty - 1, token_list: _.token_list.slice(1) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, tickets: _arrTickets }
      }
      if (defaultMyNFT.charms?.length > 0) {
        const _arrCharms = defaultMyNFT?.charms.map(_ => {
          if (value.item_id === _.item_id && _.qty > 0) {
            return { ..._, qty: _.qty - 1, token_list: _.token_list.slice(1) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, charms: _arrCharms }
      }
      if (defaultMyNFT.pet_pieces?.length > 0) {
        const _arrPetPieces = defaultMyNFT?.pet_pieces.map(_ => {
          if (value.item_id === _.item_id && _.qty > 0) {
            return { ..._, qty: _.qty - 1, token_list: _.token_list.slice(1) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, pet_pieces: _arrPetPieces }
      }
      if (defaultMyNFT.pet_souls?.length > 0) {
        const _arrPetSouls = defaultMyNFT?.pet_souls.map(_ => {
          if (value.item_id === _.item_id && _.qty > 0) {
            return { ..._, qty: _.qty - 1, token_list: _.token_list.slice(1) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, pet_souls: _arrPetSouls }
      }
    }
    return newObj
  }

  // remove NFT
  const removeNft = (value, option) => {
    let newObj = {};
    if (option === 'remove') {
      if (defaultMyNFT.pets?.length > 0) {
        const _arrPets = defaultMyNFT?.pets.map(_ => {
          if (value.item_id === _.item_id) {
            return { ..._, qty: _.qty + 1, token_list: _.token_list.concat(value.token_list[0]) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, pets: _arrPets }
      }
      if (defaultMyNFT.cards?.length > 0) {
        const _arrCards = defaultMyNFT?.cards.map(_ => {
          if (value.item_id === _.item_id) {
            return { ..._, qty: _.qty + 1, token_list: _.token_list.concat(value.token_list[0]) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, cards: _arrCards }
      }
      if (defaultMyNFT.gems?.length > 0) {
        const _arrGems = defaultMyNFT?.gems.map(_ => {
          if (value.item_id === _.item_id) {
            return { ..._, qty: _.qty + 1, token_list: _.token_list.concat(value.token_list[0]) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, gems: _arrGems }
      }
      if (defaultMyNFT.tickets?.length > 0) {
        const _arrTickets = defaultMyNFT?.tickets.map(_ => {
          if (value.item_id === _.item_id) {
            return { ..._, qty: _.qty + 1, token_list: _.token_list.concat(value.token_list[0]) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, tickets: _arrTickets }
      }
      if (defaultMyNFT.charms?.length > 0) {
        const _arrCharms = defaultMyNFT?.charms.map(_ => {
          if (value.item_id === _.item_id) {
            return { ..._, qty: _.qty + 1, token_list: _.token_list.concat(value.token_list[0]) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, charms: _arrCharms }
      }
      if (defaultMyNFT.pet_pieces?.length > 0) {
        const _arrPetPieces = defaultMyNFT?.pet_pieces.map(_ => {
          if (value.item_id === _.item_id) {
            return { ..._, qty: _.qty + 1, token_list: _.token_list.concat(value.token_list[0]) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, pet_pieces: _arrPetPieces }
      }
      if (defaultMyNFT.pet_souls?.length > 0) {
        const _arrPetSouls = defaultMyNFT?.pet_souls.map(_ => {
          if (value.item_id === _.item_id) {
            return { ..._, qty: _.qty + 1, token_list: _.token_list.concat(value.token_list[0]) }
          }
          return { ..._ }
        })
        newObj = { ...newObj, pet_souls: _arrPetSouls }
      }
    }
    return newObj
  }

  return (
    <BodyContent>
      <Page>
        <Header />
        <TopContent data={minningInf} pid={pid} />
        <SectionContent className="cols">
          {dataItems.map(item => {
            return <CardItem
              defaultLoading={defaultLoading}
              onSelectMyNft={_onSelectMyNft}
              itemId={nftId.current}
              loadingStake={loading}
              loadingUnStake={loading2}
              data={item}
              onStake={_onStake}
              onUnstake={_onUnstake}
              onUpgrade={_onUpgrade}
              dataMyNft={defaultMyNFT}
              key={item.item_key}
            />
          })}
        </SectionContent>
      </Page>
      {
        isOpen && <PopupUpgrade
          isOpen={isOpen}
          onRequestClose={_onClose}
          data={itemUpgrade}
          pid={pid}
          onSuccess={_onSuccess}
        />
      }

    </BodyContent>
  )
}

export default NftMining

const BodyContent = styled.div`
  background: #14141B url('/images/banner-mining.png') 0 0 no-repeat;    
 
`
const SectionContent = styled.div`
  margin-top: 30px;
  display: grid;
  column-gap:30px;
  row-gap:60px;
  grid-template-columns:repeat(2, 1fr);
  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns:repeat(3, 1fr); 
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    grid-template-columns:repeat(4, 1fr); 
  }
  ${({ theme }) => theme.mediaQueries.xl} {
    grid-template-columns:repeat(5, 1fr); 
  }

`