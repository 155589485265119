import React from 'react';
import ComingSoon from './ComingSoon'
import MoniMining from './MoniMining'


const IS_COMING_SOON = process.env.REACT_APP_COMING_SOOM;

const InitLayout: React.FC = () => {
    return (IS_COMING_SOON === "true") ? <ComingSoon /> : <MoniMining />
}
export default InitLayout

