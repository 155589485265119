import React from "react";
import styled from 'styled-components';
import { SvgProps } from "../../../components/Svg/types";

const StyleDiv = styled.div`
  padding-right:5px
`
const Icon: React.FC<SvgProps> = (props) => {
  return (<StyleDiv>   
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="transparent"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 15l-2.968 2.968A2.362 2.362 0 012 16.298V15l1.357-6.784A4 4 0 017.279 5h9.442a4 4 0 013.922 3.216L22 15v1.297a2.362 2.362 0 01-4.032 1.67L15 15H9z"
     />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 5l1 2h4l1-2"
      />
    </svg> </StyleDiv>
  );
};

export default Icon;
