import React from 'react'
import styled from 'styled-components'
import { SvgProps } from '../../../components/Svg/types'

const StyleDiv = styled.div`
  padding-right: 5px;
`
const Icon: React.FC<SvgProps> = (_props) => {
  return (
    <StyleDiv>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.7 7.2C9.858 7.2 10.8 6.258 10.8 5.1C10.8 3.942 9.858 3 8.7 3C7.542 3 6.6 3.942 6.6 5.1C6.6 6.258 7.542 7.2 8.7 7.2ZM8.7 3.6C9.5268 3.6 10.2 4.2732 10.2 5.1C10.2 5.9268 9.5268 6.6 8.7 6.6C7.8732 6.6 7.2 5.9268 7.2 5.1C7.2 4.2732 7.8732 3.6 8.7 3.6ZM11.4 12.6H10.8V8.1C10.8 7.9344 10.6656 7.8 10.5 7.8H7.2C6.5382 7.8 6 8.3382 6 9C6 9.6618 6.5382 10.2 7.2 10.2H7.8V12.6H7.2C6.5382 12.6 6 13.1382 6 13.8C6 14.4618 6.5382 15 7.2 15H11.4C12.0618 15 12.6 14.4618 12.6 13.8C12.6 13.1382 12.0618 12.6 11.4 12.6ZM11.4 14.4H7.2C6.8694 14.4 6.6 14.1306 6.6 13.8C6.6 13.4694 6.8694 13.2 7.2 13.2H8.1C8.2656 13.2 8.4 13.0656 8.4 12.9V9.9C8.4 9.7344 8.2656 9.6 8.1 9.6H7.2C6.8694 9.6 6.6 9.3306 6.6 9C6.6 8.6694 6.8694 8.4 7.2 8.4H10.2V12.9C10.2 13.0656 10.3344 13.2 10.5 13.2H11.4C11.7306 13.2 12 13.4694 12 13.8C12 14.1306 11.7306 14.4 11.4 14.4ZM9 0C4.0374 0 0 4.0374 0 9C0 13.9626 4.0374 18 9 18C13.9626 18 18 13.9626 18 9C18 4.0374 13.9626 0 9 0ZM9 17.4C4.368 17.4 0.6 13.632 0.6 9C0.6 4.368 4.368 0.6 9 0.6C13.632 0.6 17.4 4.368 17.4 9C17.4 13.632 13.632 17.4 9 17.4Z" fill="black"/>
</svg>
    </StyleDiv>
  )
}

export default Icon
