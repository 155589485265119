import { MenuEntry } from 'components/Pancake-uikit'
import { ContextApi } from 'contexts/Localization/types'

const urlGamePlay = process.env.REACT_APP_URL_GAME_PLAY

const linkBuyMowa = process.env.REACT_APP_ENV === 'production' ?
  'https://pancakeswap.finance/swap?outputCurrency=0x411Ec510c85C9e56271bF4E10364Ffa909E685D9' :
  'https://pancakeswap.finance/swap?outputCurrency=0x603e2e17dCfe0e764614df5c63B14358988c16fb'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/dashboard',
  },
  {
    label: t('Play Game'),
    icon: 'PlayGameIcon',
    href: urlGamePlay,
    target: "_blank",
  },
  {
    label: t('Leaderboard'),
    icon: 'EventStarIcon',
    items: [
      {
        label: t('Top PVP 1 vs 1'),
        href: '/top-pvp-1-vs-1',
      },
      {
        label: t('Top PVP 3 vs 3'),
        href: '/top-pvp-3-vs-3',
      },
      {
        label: t('PVP reward'),
        href: '/pvp-reward',
      },
    ],
  },
  {
    label: t('Buy MOWA'),
    icon: 'TradeIcon',
    href: linkBuyMowa,
    target: "_blank",
  },
  // {
  //   label: t('Buy MOWA'),
  //   icon: 'TradeIcon',
  //   items: [
  //     {
  //       label: t('Exchange'),
  //       href: '/swap',
  //     },
  //     {
  //       label: t('Liquidity'),
  //       href: '/pool',
  //     },
  //   ],
  // },
  {
    label: t('Bridge MOWA'),
    icon: 'MowaBridgeIcon',
    items: [
      {
        label: t('Token Bridge'),
        href: '/token-bridge',
      },
      {
        label: t('NFT Bridge'),
        href: '/nft-bridge',
      },
      {
        label: t('Report'),
        href: '/report',
      },
    ],
  },

  {
    label: t('NFT Market'),
    icon: 'MarketIcon',
    items: [
      {
        label: t('Market'),
        href: '/nft/market',
      },
      {
        label: t('Purchasing Shop'),
        href: '/purchasing-shop',
      },
      {
        label: t('Auction'),
        href: '/nft/aution',
      },
      {
        label: t('Blind Box'),
        href: '/nft/blind-box',
      },
    ],
  },
  {
    label: t('Energy Shop'),
    icon: 'EnergyShopIcon',
    href: '/energy-shop'
  },
  {
    label: t('Pool Token'),
    icon: 'PoolIcon',
    items: [
      {
        label: t('Mining'),
        href: '/mining',
      },
      {
        label: t('Farm'),
        href: '/farms',
      },
    ],
  },
  {
    label: t('Pool NFT'),
    icon: 'PoolIcon',
    items: [
      {
        label: t('NFT Mining V1'),
        href: '/nft/mining',
      },
      {
        label: t('NFT Mining V2'),
        href: '/nft/mining-new',
      },
      {
        label: t('Farm'),
        href: '/nft/farms',
      },
    ],
  },
  {
    label: t('Mint & Bag'),
    icon: 'BlindIcon',
    items: [
      {
        label: t('Bag'),
        href: '/bag',
      },
      {
        label: t('Gem Upgrade'),
        href: '/gem-upgrade',
      },
      {
        label: t('Pet Combine'),
        href: '/pet-combine',
      },
      {
        label: t('Pet Star Upgrade'),
        href: '/pet-star-upgrade',
      },
      {
        label: t('Pet Level Upgrade'),
        href: '/pet/upgrade-level',
      },
      {
        label: t('Pet Skill Upgrade'),
        href: '/pet-skill-upgrade',
      },
    ],
  },
  {
    label: t('Send Pet'),
    icon: 'SendPetIcon',
    href: '/send-pet',
  },
  {
    label: t('Claim Token'),
    icon: 'ClaimToken',
    items: [
      {
        label: t('version 1'),
        href: '/claim-token-version-1',
      },
      {
        label: t('version 2'),
        href: '/claim-token-version-2',
      },
    ]
  },

  {
    label: t('Bounty'),
    icon: 'FarmIcon',
    items: [
      {
        label: t('Event offline'),
        href: '/event-offline-claim',
      },
      {
        label: t('Airdrop Token'),
        href: '/airdrop-token',
      },
      {
        label: t('Airdrop NFT'),
        // href: '/airdrop-nft',
        href: '/claim-nft',
      },
      {
        label: t('Survival Leaderboard'),
        href: '/survival-leader-board',
      },
      {
        label: t('Survival Reward'),
        href: '/survival-reward',
      },
      // {
      //   label: 'Event Reward',
      //   href: '/event-reward',
      // },      
      {
        label: t('Transaction'),
        href: '/mowa-transaction',
      },
      // {
      //   label: t('Quest'),
      //   href: '/quest',
      // },
    ],
  },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Github'),
        href: 'https://github.com/moniwar-mowa',
        target: '_blank',
      },
      {
        label: t('Whitepaper'),
        href: 'https://docs.moniwar.io',
        target: '_blank',
      },
      {
        label: t('Blog'),
        href: 'https://moniwar.medium.com/',
        target: '_blank',
      },
      // {
      //   label: t('Contact form'),
      //   href: '/contact-form',
      // },
    ],
  },
]

export default config
