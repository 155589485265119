import { useEffect, useState } from 'react'
import { useMowaContract } from 'hooks/useContract'
import axios from 'axios'

const useExchange = () => {
  const [priceChange, setPriceUsd] = useState(0)
  const [priceBnb, setPriceBnb] = useState(0)
  
  useEffect(() => {
    async function getPrice() {
      // const address = MoniContract?.address
      axios
        .get(`https://api.coingecko.com/api/v3/coins/moniwar?tickers=false&market_data=true`)
        .then((resp) => {
          if (resp.data) {
            const marketData = resp.data?.market_data
            const _priceUsd = parseFloat(marketData?.current_price.usd)
            const  _priceBnb = parseFloat(marketData?.current_price.bnb)
            setPriceUsd(_priceUsd)
            setPriceBnb(_priceBnb)
          }
        })
        .catch((error) => {
          console.log('Detail get Price', error)
        })
    }
    getPrice()
  }, [])

  return { priceChange, priceBnb }
}


export default useExchange
