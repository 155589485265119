import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import farmsConfig from 'config/constants/nftFarms'
import isArchivedPid from 'utils/farmHelpers'
import priceHelperLpsConfig from 'config/constants/priceHelperLps'
import fetchFarms from './fetchFarms'
import fetchFarmsPrices from './fetchFarmsPrices'
import {
    fetchFarmUserAllowances,
    fetchFarmUserEarnings,
    fetchFarmUserStakedBalances,
    fetchFarmUserTokenBalances,
} from './fetchFarmUser'
import {NftFarm, NftFarmsState} from '../types'


const noAccountFarmConfig = farmsConfig.map((farm) => ({
    ...farm,
    userData: {
        allowance: '0',
        tokenBalance: '0',
        stakedBalance: '0',
        earnings: '0',
    },
}))

const initialState: NftFarmsState = {data: noAccountFarmConfig, loadArchivedFarmsData: false, userDataLoaded: false}

export const nonArchivedFarms = farmsConfig.filter(({pid}) => !isArchivedPid(pid))

// Async thunks 
export const fetchNftFarmsPublicDataAsync = createAsyncThunk<NftFarm[], number[]>(
    'nftFarms/fetchFarmsPublicDataAsync',
    async (pids) => {
        const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))

        // Add price helper farms
        const farmsWithPriceHelpers = farmsToFetch.concat(priceHelperLpsConfig)
        const farms = await fetchFarms(farmsWithPriceHelpers)

        const farmsWithPrices = await fetchFarmsPrices(farms)

        // Filter out price helper LP config farms
        const farmsWithoutHelperLps = farmsWithPrices.filter((farm: NftFarm) => {
            return farm.pid || farm.pid === 0
        })

        return farmsWithoutHelperLps
    },
)

interface FarmUserDataResponse {
    pid: number
    allowance: string
    tokenBalance: string
    stakedBalance: string
    earnings: string
}

export const fetchNftFarmUserDataAsync = createAsyncThunk<FarmUserDataResponse[], { account: string; pids: number[] }>(
    'nftFarms/fetchFarmUserDataAsync',
    async ({account, pids}) => {
        const farmsToFetch = farmsConfig.filter((farmConfig) => pids.includes(farmConfig.pid))
        const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch)
        const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch)
        const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch)
        const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch)

        return userFarmAllowances.map((farmAllowance, index) => {
            return {
                pid: farmsToFetch[index].pid,
                allowance: userFarmAllowances[index],
                tokenBalance: userFarmTokenBalances[index],
                stakedBalance: userStakedBalances[index],
                earnings: userFarmEarnings[index],
            }
     
        })
    },
)

export const farmsSlice = createSlice({
    name: 'Farms',
    initialState,
    reducers: {
        setLoadArchivedFarmsData: (state, action) => {
            state.loadArchivedFarmsData = action.payload
        },
    },
    extraReducers: (builder) => {
        // Update farms with live data
        builder.addCase(fetchNftFarmsPublicDataAsync.fulfilled, (state, action) => {
            state.data = state.data.map((farm) => {
                const liveFarmData = action.payload.find((farmData) => farmData.pid === farm.pid)
                return {...farm, ...liveFarmData}
            })
        })

        // Update farms with user data
        builder.addCase(fetchNftFarmUserDataAsync.fulfilled, (state, action) => {
            action.payload.forEach((userDataEl) => {
                const {pid} = userDataEl
                const index = state.data.findIndex((farm) => farm.pid === pid)
                state.data[index] = {...state.data[index], userData: userDataEl}
            })
            state.userDataLoaded = true
        })
    },
})

// Actions
export const {setLoadArchivedFarmsData} = farmsSlice.actions

export default farmsSlice.reducer
