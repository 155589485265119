import React from 'react';
import { Box, Text, UserMenuDivider, UserMenuItem } from '@pancakeswap/uikit'
import { UserMenu } from 'components/Pancake-uikit'
import { multichainConfig } from 'config/chains'
import { useTranslation } from 'contexts/Localization';
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { setupNetwork } from 'utils/wallet'

export const NetworkSelect = () => {
  const { t } = useTranslation()
  const isProduction = process.env.REACT_APP_ENV === 'production'
  const _renderChainList = () => {
    if (isProduction) {
      return Object.keys(multichainConfig).map((chain) =>
        !multichainConfig[chain].testnet &&
        <UserMenuItem
          key={multichainConfig[chain].id}
          style={{ justifyContent: 'flex-start' }}
          onClick={() => setupNetwork(multichainConfig[chain].id)}
        >
          <img
            width={24}
            height={24}
            src={multichainConfig[chain].image}
            alt="icon-token"
          />
          <Text pl="12px">{multichainConfig[chain].name}</Text>
        </UserMenuItem>
      )
    }
    return Object.keys(multichainConfig).map((chain) =>
      <UserMenuItem
        key={multichainConfig[chain].id}
        style={{ justifyContent: 'flex-start' }}
        onClick={() => setupNetwork(multichainConfig[chain].id)}
      >
        <img
          width={24}
          height={24}
          src={multichainConfig[chain].image}
          alt="icon-token"
        />
        <Text pl="12px">{multichainConfig[chain].name}</Text>
      </UserMenuItem>
    )
  }

  return (
    <>
      <Box px="16px" py="8px">
        <Text>{t('Select a Network')}</Text>
      </Box>
      <UserMenuDivider />
      {_renderChainList()}
    </>
  )
}

export const NetworkSwitcher = () => {
  const { chainId } = useActiveWeb3React()
  return (
    <UserMenu
      avatarSrc={multichainConfig[chainId].image}
      account={multichainConfig[chainId].name}
    >
      <NetworkSelect />
      {/* {() => } */}
    </UserMenu>
  )
}
