import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { NoBscProviderError } from '@binance-chain/bsc-connector'
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
    WalletConnectConnector,
} from '@web3-react/walletconnect-connector'
import { ConnectorNames, connectorLocalStorageKey } from '@pancakeswap/uikit'
import { connectorsByName } from 'utils/web3React'
import { setupNetwork } from 'utils/wallet'
import useToast from 'hooks/useToast'
import { profileClear } from 'state/profile'
import { useAppDispatch } from 'state'
import { useTranslation } from 'contexts/Localization'
import { removeCookiesAuth } from 'state/auth/hooks'
import firebase from "config/firebaseConfig";

const useAuth = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { activate, deactivate, chainId } = useWeb3React()
    const { toastError } = useToast()

    const login = useCallback(
        async (connectorID: ConnectorNames) => {
            const connectorOrGetConnector = connectorsByName[connectorID]
            const connector =
                typeof connectorOrGetConnector !== 'function' ? connectorsByName[connectorID] : await connectorOrGetConnector()
            if (connector) {
                activate(connector, async (error: Error) => {
                    if (error instanceof UnsupportedChainIdError) {
                        const provider = await connector.getProvider()
                        const hasSetup = await setupNetwork(56, provider)
                        if (hasSetup) {
                            activate(connector)
                        }
                    } else {
                        window.localStorage.removeItem(connectorLocalStorageKey)
                        if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
                            toastError(t('Provider Error'), t('No provider was found'))
                        } else if (
                            error instanceof UserRejectedRequestErrorInjected ||
                            error instanceof UserRejectedRequestErrorWalletConnect
                        ) {
                            if (connector instanceof WalletConnectConnector) {
                                const walletConnector = connector as WalletConnectConnector
                                walletConnector.walletConnectProvider = null
                            }
                            toastError(t('Authorization Error'), t('Please authorize to access your account'))
                        } else {
                            toastError(error.name, error.message)
                        }
                    }
                })
            } else {
                toastError(t('Unable to find connector'), t('The connector config is wrong'))
            }
        },
        [t, activate, toastError],
    )

    const logout = useCallback(async () => {
        dispatch(profileClear())
        deactivate()
        // This localStorage key is set by @web3-react/walletconnect-connector
        if (window.localStorage.getItem('walletconnect')) {
            connectorsByName.walletconnect.close()
            connectorsByName.walletconnect.walletConnectProvider = null
        }     
        window.localStorage.removeItem(connectorLocalStorageKey)

        removeCookiesAuth('auth');
        removeCookiesAuth('token_sv')
        await firebase.auth().signOut().then(resp => {
            return true
        }).catch(e => console.log('error logout', e));
        window.location.reload()
    }, [deactivate, dispatch])

    return { login, logout }
}

export default useAuth
