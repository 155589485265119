import { ChainId } from '@pancakeswap/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address, AddressMultiple } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getCakeAddress = () => {
  return getAddress(tokens.cake.address)
}
export const getKscAddress = () => {
  return getAddress(tokens.kshark.address)
}
export const getBksAddress = () => {
  return getAddress(tokens.babykshark.address)
}
export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMasterChefNftAddress = () => {
  return getAddress(addresses.masterChefNft)
}
export const getMasterChefNftV2Address = () => {
  return getAddress(addresses.masterChefNftV2)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getWbnbAddress = () => {
  return getAddress(tokens.wbnb.address)
}
export const getLotteryV2Address = () => {
  return getAddress(addresses.lotteryV2)
}
export const getPancakeProfileAddress = () => {
  return getAddress(addresses.pancakeProfile)
}
export const getPancakeRabbitsAddress = () => {
  return getAddress(addresses.pancakeRabbits)
}
export const getBunnyFactoryAddress = () => {
  return getAddress(addresses.bunnyFactory)
}
export const getClaimRefundAddress = () => {
  return getAddress(addresses.claimRefund)
}
export const getPointCenterIfoAddress = () => {
  return getAddress(addresses.pointCenterIfo)
}
export const getBunnySpecialAddress = () => {
  return getAddress(addresses.bunnySpecial)
}
export const getTradingCompetitionAddress = () => {
  return getAddress(addresses.tradingCompetition)
}
export const getEasterNftAddress = () => {
  return getAddress(addresses.easterNft)
}
export const getCakeVaultAddress = () => {
  return getAddress(addresses.cakeVault)
}
export const getPredictionsAddress = () => {
  return getAddress(addresses.predictions)
}
export const getChainlinkOracleAddress = () => {
  return getAddress(addresses.chainlinkOracle)
}
export const getBunnySpecialCakeVaultAddress = () => {
  return getAddress(addresses.bunnySpecialCakeVault)
}
export const getBunnySpecialPredictionAddress = () => {
  return getAddress(addresses.bunnySpecialPrediction)
}
export const getBunnySpecialLotteryAddress = () => {
  return getAddress(addresses.bunnySpecialLottery)
}

export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}

export const getAirdropAddress = () => {
  return getAddress(addresses.airdrop)
}

export const getMemberOfKSharkAddress = () => {
  return getAddress(addresses.memberOfShark)
}

export const getKSharkAddress = () => {
  return getAddress(tokens.kshark.address)
}

export const getBabyKSharkAddress = () => {
  return getAddress(tokens.babykshark.address)
}
export const getMowaAddress = () => {
  return getAddress(tokens.mowa.address)
}
export const getManagerAddress = () => {
  return getAddress(addresses.manager)
}
export const getBlindBoxAddress = () => {
  return getAddress(addresses.blindbox)
}
export const getMarketAddress = () => {
  return getAddress(addresses.market)
}
export const getMowaNFTAddress = () => {
  return getAddress(addresses.mowaNFT)
}
export const getMowaNFTV2Address = () => {
  return getAddress(addresses.mowaNFTV2)
}
export const getClaimNFTAddress = () => {
  return getAddress(addresses.claimNFT)
}
export const getMowaMinningNFTAddress = () => {
  return getAddress(addresses.miningNFT)
}
export const getClaimMultiNFTAddress = () => {
  return getAddress(addresses.claimMultiNFT)
}
export const getMowaTestAddress = () => {
  return getAddress(addresses.MowaTest)
}
export const getClaimTokenAddress = () => {
  return getAddress(addresses.claimToken)
}
export const getMowaMinningNFTNewAddress = () => {
  return getAddress(addresses.miningNFTNew)
}
export const getMarketNewAddress = () => {
  return getAddress(addresses.marketNew)
}
export const getMowaNFTItemsAddress = () => {
  return getAddress(addresses.nftItems)
}
export const getMowaOpenBoxAddress = () => {
  return getAddress(addresses.mowaOpenBox)
}
export const getMowaOpenBoxV2Address = () => {
  return getAddress(addresses.mowaOpenBoxV2)
}
export const getMowaNFTUpgradeGemAddress = () => {
  return getAddress(addresses.mowaUpgradeGem)
}
export const getMowaClaimTokenEventRewardAddress = () => {
  return getAddress(addresses.mowaClaimTokenEventReward)
}
export const getMowaClaimTokenPresaleAddress = () => {
  return getAddress(addresses.mowaClaimTokenPresale)
}
export const getMowaClaimTokenPresaleV2Address = () => {
  return getAddress(addresses.mowaClaimTokenPresaleV2)
}
export const getMowaGiftNFTAddress = () => {
  return getAddress(addresses.mowaGiftNFT)
}
export const getMarketV2ddress = () => {
  return getAddress(addresses.marketV2)
}
export const getPetCombineAddress = () => {
  return getAddress(addresses.petCombine)
}
export const getPetUpgradeLevelAddress = () => {
  return getAddress(addresses.petUpgradeLevel)
}
export const getMarketV3ddress = () => {
  return getAddress(addresses.marketV3)
}
export const getMarketV4ddress = () => {
  return getAddress(addresses.marketV4)
}
export const getMarketV5ddress = () => {
  return getAddress(addresses.marketV5)
}
export const getMarketV6ddress = () => {
  return getAddress(addresses.marketV6)
}
export const getWheelLuckyAddress = () => {
  return getAddress(addresses.mowaWheelLucky)
}
export const getLockedTokenAddress = () => {
  return getAddress(addresses.lockedToken)
}

export const getMowaAuctionAddress = () => {
  return getAddress(addresses.mowaAuction)
}

export const getmowaTokenAirdopAddress = () => {
  return getAddress(addresses.mowaTokenAirdrop)
}

export const getmowaSpinAddress = () => {
  return getAddress(addresses.mowaSpin)
}

export const getPetSkillUpgradeAddress = () => {
  return getAddress(addresses.petSkillUpgrade)
}
export const getShopNFTItemsAddress = () => {
  return getAddress(addresses.shopNFTItems)
}
export const getRenameUserAddress = () => {
  return getAddress(addresses.renameUser)
}
export const getWealthyGameAddress = () => {
  return getAddress(addresses.wealthyGame)
}
export const getOpenAirdropBoxAddress = () => {
  return getAddress(addresses.OpenAirdropBox)
}
export const getAddressChain = (address: AddressMultiple, chainId?: number): string => {
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getMowaAddressByChain = (chainId?: number) => {
  return getAddressChain(tokens.mowa.address, chainId)
}
export const getBridgeMowaToCsc = (chainId: number): string => {
  return getAddressChain(addresses.BridgeMowaToCSC, chainId)
}

export const getBlindboxCsc = (chainId: number): string => {
  return getAddressChain(addresses.blindbox, chainId)
}

export const getMowaNFTByChain = (chainId: number): string => {
  return getAddressChain(addresses.mowaNFTV2, chainId)
}

export const getMowaNFTItemsByChain = (chainId: number): string => {
  return getAddressChain(addresses.nftItems, chainId)
}
export const getMarketCSCByChain = (chainId: number): string => {
  return getAddressChain(addresses.marketV6, chainId)
}
export const getMagicBoxCSC = (chainId: number): string => {
  return getAddressChain(addresses.mowaOpenBoxV2, chainId)
}
export const getAirdropBoxCSC = (chainId: number): string => {
  return getAddressChain(addresses.OpenAirdropBox, chainId)
}
export const getGiftNFTCSC = (chainId: number): string => {
  return getAddressChain(addresses.mowaGiftNFT, chainId)
}
export const getMarketV7ddress = () => {
  return getAddress(addresses.marketV7)
}