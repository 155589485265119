import { Text, useMatchBreakpoints } from '@pancakeswap/uikit';
import { useWeb3React } from "@web3-react/core";
import React, { memo, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { setCookiesAuth } from 'state/auth/hooks';
import styled, { css, keyframes } from 'styled-components';
import { onGetSvApi, onGetTokenSV, onPostSvApi } from 'utils/apiHelper';
import ItemNofify from './ItemNofify';

interface Props {
  isVisible?: boolean,
  onStatus?: (val) => void,
}

const PanelNotify: React.FC<Props> = ({ isVisible, onStatus }) => {
  const { account } = useWeb3React()
  const [cookies] = useCookies(['auth']);
  const userLogin = cookies.auth
  const { isDesktop } = useMatchBreakpoints()
  const [status, setStatus] = useState(true)
  const [listNoty, setListNoty] = useState([])
  const [reFresh, setRefresh] = useState(false)

  // useEffect(() => {
  //   if (!account) return
  //   async function onGetListNoty() {
  //     const resp = await onGetApiService(`listNotify?account=${account}`)
  //     if (resp) {
  //       setListNoty(resp?.items)
  //       setRefresh(false)
  //       if(resp?.items.length > 0){
  //         onStatus(true)
  //       }else{
  //         onStatus(false)
  //       }
  //     }
  //   }
  //   onGetListNoty()
  // }, [account, reFresh, isVisible, onStatus])

  // const _onScroll = (event) => {
  //   const target = event.target
  //   const { scrollTop, scrollHeight, clientHeight } = target;
  //   if (scrollTop + clientHeight === scrollHeight) {
  //     console.log('Reached bottom')
  //   }
  // };

  const onPressItem = async (value) => {
    onPostSvApi('readNotifyById', { id: value._id }, account).then(res => {
      if (res.code) {
        setRefresh(true)
      }
    })
  }

  const onReadAll = async () => {
    if (!account) {
      return
    }
    onPostSvApi('readAllNotify', { account }, account).then(res => {
      if (res.code) {
        setRefresh(true)
      }
    })
  }

  const _renderListItem = () => {
    if (listNoty.length === 0) {
      return <Text className='no-data'>No data yet!...</Text>
    }
    return <div className="list-noti">
      {
        listNoty.map((_, index) => <ItemNofify data={_} onPress={onPressItem} key={`notify_${index.toString()}`}/>)
      }

    </div>
  }

  return <ListNotify expanded={isVisible}>
    {
      _renderListItem()
    }
    {
      listNoty.length !== 0 && <Text className="footer-notify" onClick={onReadAll}>Mark All as Read</Text>
    }

  </ListNotify>
}

export default memo(PanelNotify)

const expandAnimation = keyframes`
  from {
    right:-100%;
    opacity:0.5;
  }
  to {
    right:20px;
    opacity:1;
  }
`

const collapseAnimation = keyframes`
  from {
    right:20px;
    opacity:1;
  }
  to {
    right:-100%;
    opacity:0.5;
  }
`

const ListNotify = styled.div<{ expanded }>`   
    animation: ${({ expanded }) => expanded
    ? css` ${expandAnimation} 300ms linear forwards`
    : css`${collapseAnimation} 300ms linear forwards`};
   width: 320px;
   height: 500px;
   position:fixed;
  
   z-index: 50;
   top:55px;
   border-radius: 14px;
   background:#1B212F;
   .list-noti {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    height: 450px;
    overflow-y: auto;
   }
   .footer-notify{
    position: absolute;
    bottom: 0;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(255,255,255,0.1);
    color:rgba(255,255,255,0.6);
    font-weight:400;
    font-size: 14px;
    &:hover{
      cursor: pointer;
    }
   }
   .no-data {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
   }
`
