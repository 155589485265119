import React, { useState, useMemo, useEffect } from 'react'
import { Input } from '@pancakeswap/uikit'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { useTranslation } from 'contexts/Localization'

const StyledInput = styled(Input)`
  border:none;
  color: rgba(255,255, 255, 0.8) !important;
  box-shadow: inherit;
  padding: 0 36px 0 20px;
  width: 100%;
  max-width: 100%;
  font-weight: 400;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 10px;  
  :focus{
    box-shadow: inherit !important;
  }

  ::placeholder {
    color: #ADADAD;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #ADADAD;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
   color: #ADADAD;
  }
`

const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 15px 0;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: 260px;
    margin: 0;
    margin-left: auto;
  }

  i{
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 16px;
    color: #716C80;
    transform: translate(0,-50%);
    z-index: 2;
  }

  input[type="submit"]{
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 40px;
    border: 0;
    z-index: 3;
    background: transparent;
    cursor: pointer;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string,
  defautValue?: string
}

const SearchInput: React.FC<Props> = ({ onChange: onChangeCallback, placeholder = 'Search', defautValue }) => {
  const [searchText, setSearchText] = useState(defautValue || '')

  const { t } = useTranslation()

  useEffect(()=>{
    setSearchText(defautValue)
  },[defautValue])

  const debouncedOnChange = useMemo(
    () => debounce((e: React.ChangeEvent<HTMLInputElement>) => onChangeCallback(e), 500),
    [onChangeCallback],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    debouncedOnChange(e)
  }

  return (
    <InputWrapper>
      <StyledInput value={searchText} onChange={onChange} placeholder={t(placeholder)} />
      <input type="submit" value="" />
      <i className="fal fa-search" />
    </InputWrapper>
  )
}

export default SearchInput
