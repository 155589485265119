import { DEFAULT_GAS_NFT_LIMIT } from 'config'
import getGasPrice from 'utils/getGasPrice'

const options = {
  gasLimit: DEFAULT_GAS_NFT_LIMIT,
}

// disposit farm nft
export const depositLp = async (masterChefContract, pid, amount, version = 'v1') => {
  const gasPrice = getGasPrice()
  const tx = await masterChefContract.deposit(pid, amount, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

// disposit farm nft
export const withDrawFarmNft = async (masterChefContract, pid, amount, version = 'v1') => {
  const gasPrice = getGasPrice()
  const tx = await masterChefContract.withdraw(pid, amount, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}
// disposit farm nft
export const harvestFarmNft = async (masterChefContract, pid, version = 'v1') => {
  const gasPrice = getGasPrice()
  const tx = await masterChefContract.harvest(pid, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const depositNFT = async (masterChefContract, pid, _tokenId, version = 'v1') => {
  const gasPrice = getGasPrice()
  const tx = await masterChefContract.depositNFT(pid, _tokenId, { ...options, gasPrice })
  const receipt = await tx.wait()
  return [receipt.status, tx.hash]
}

export const unstakeNFT = async (masterChefContract, pid, version = 'v1') => {
  const gasPrice = getGasPrice()
  const tx = await masterChefContract.unStake(pid, { ...options, gasPrice })
  const receipt = await tx.wait()
  return [receipt.status, tx.hash]
}