import {
  Flex,
  LogoutIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem
} from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import ConnectWalletButton from 'components/ConnectWalletButton'
import PopupWarning from 'components/PopupWarning'
import { useTranslation } from 'contexts/Localization'
import useAuth from 'hooks/useAuth'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import history from 'routerHistory'
import { useProfile } from 'state/profile/hooks'
import styled from 'styled-components'
import ForgotPasswor from '../ProfileMenu/ForgotModal'
import SignUp from '../ProfileMenu/RegisterModal'
import WalletModal, { LOW_BNB_BALANCE, WalletView } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'


const UserMenu:React.FC = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const { isInitialized, isLoading, profile } = useProfile()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />, false)
  // const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />, false)
  const [forgotModal] = useModal(<ForgotPasswor />, false)
  const [SignUpModal] = useModal(<SignUp />, false)

  const hasProfile = isInitialized && !!profile
  const avatarSrc = profile && profile.nft ? `/images/nfts/${profile.nft.images.sm}` : undefined
  const hasLowBnbBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)
  const [cookies] = useCookies(['auth']);
  const userLogin = cookies.auth
  const [isWarning, setIsWarning] = useState(false)
  const [typeAlert, setTypeAlert] = useState(null)

  if (!account) {
    return <ConnectWalletButton scale="sm" />
  }


  const onForgot = () => {
    if(!userLogin?.data.account_verify){
      setTypeAlert(null)
      setIsWarning(true)
      return
    }
    
    if(!userLogin?.data.email_verify){
      setTypeAlert('Forgot-pass')
      setIsWarning(true)
      return
    }

    forgotModal()
  }

  return (<>
    <UIKitUserMenu account={account} avatarSrc={avatarSrc}>
      <WalletUserMenuItem hasLowBnbBalance={hasLowBnbBalance} onPresentWalletModal={onPresentWalletModal} />
      {/* <UserMenuItem as="button" onClick={onPresentTransactionModal}>
        {t('Transactions')}
      </UserMenuItem> */}
      <UserMenuDivider />
      {
        userLogin ? <>
          <UserMenuItem as="button" onClick={() => history.push(`/bag`)}>
            {t('Your NFTs')}
          </UserMenuItem>
          <UserMenuItem as="button" onClick={() => history.push(`/profile`)}>
            {t('My Account')}
          </UserMenuItem>
          <UserMenuItem as="button" onClick={onForgot}>
            {t('Forgot Password')}
          </UserMenuItem>
        </> :
          <UserMenuItem as="button" onClick={SignUpModal}>
            {t('Sign-up')}
          </UserMenuItem>
      }
      <UserMenuDivider />
      <UserMenuItem as="button" onClick={logout}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          {t('Disconnect')}
          <LogoutIcon />
        </Flex>
      </UserMenuItem>
    </UIKitUserMenu>
    {
      isWarning && 
      <PopupWarning
        isOpen={isWarning}
        onRequestClose={() => setIsWarning(false)}
        typeAlert={typeAlert}
      />
    }
  </>
  )
}

export default UserMenu

const OutLink = styled.div`
    >a:hover{
        > div {
            background: #353547;
        }
    }
`
const TagLink = styled.div`
    height: 48px;
    padding-left: 17px;
    display: flex;
    align-items: center;
    color: rgb(184, 173, 210);
`