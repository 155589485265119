import React from "react";
import styled from "styled-components";
import { CogIcon } from "../../../components/Svg";
import IconButton from "../../../components/Button/IconButton";
import { MENU_ENTRY_HEIGHT } from "../config";
import { PanelProps, PushedProps } from "../types";
import CakePrice from "./CakePrice";
import ThemeSwitcher from "./ThemeSwitcher";
import SocialLinks from "./SocialLinks";
import LangSelector from "./LangSelector";

interface Props extends PanelProps, PushedProps {}

const Container = styled.div`
  flex: none;
  padding: 8px 4px;
  background-color: #14141B;
`;

const SettingsEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 8px;
`;

const SocialEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 16px;
`;

const SocialEntryItem = styled.div`
  flex: 1 1;
  font-size: 20px;
  text-align: center;

  svg path{
    fill: rgba(255,255,255,.6);
    transition: all .2s linear;

    &:hover{
      fill: rgba(255,255,255,1);
    }
  }

  a:hover{
    svg path{
      fill: rgba(255,255,255,1);
    }
  }
`;

const PanelFooter: React.FC<Props> = ({
  isPushed,
  pushNav,
  toggleTheme,
  isDark,
  kSharkPriceUsd,
  currentLang,
  langs,
  setLang,
}) => {
 
  if (!isPushed) {
    return (
      <Container>
        <IconButton variant="text" onClick={() => pushNav(true)}>
          <CogIcon />
        </IconButton>
      </Container>
    );
  }

  return (
    <Container>
       <SocialEntry>
        <SocialEntryItem>
          <a href="https://t.me/MoniwarAnnouncements"  target='_blank' rel="noreferrer">
            <svg width={21} height={18} viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.6651 0.71682L0.935095 7.55382C-0.274905 8.03982 -0.267905 8.71482 0.713095 9.01582L5.26509 10.4358L15.7971 3.79082C16.2951 3.48782 16.7501 3.65082 16.3761 3.98282L7.8431 11.6838H7.84109L7.8431 11.6848L7.5291 16.3768C7.9891 16.3768 8.19209 16.1658 8.45009 15.9168L10.6611 13.7668L15.2601 17.1638C16.1081 17.6308 16.7171 17.3908 16.9281 16.3788L19.9471 2.15082C20.2561 0.91182 19.4741 0.35082 18.6651 0.71682Z" />
            </svg>
          </a>
        </SocialEntryItem>
        <SocialEntryItem>
          <a href="https://www.facebook.com/MoniwarOfficial/" target='_blank' rel="noreferrer">
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 10.0558C0 15.0275 3.61083 19.1617 8.33333 20V12.7775H5.83333V10H8.33333V7.7775C8.33333 5.2775 9.94417 3.88917 12.2225 3.88917C12.9442 3.88917 13.7225 4 14.4442 4.11083V6.66667H13.1667C11.9442 6.66667 11.6667 7.2775 11.6667 8.05583V10H14.3333L13.8892 12.7775H11.6667V20C16.3892 19.1617 20 15.0283 20 10.0558C20 4.525 15.5 0 10 0C4.5 0 0 4.525 0 10.0558Z" />
            </svg>
          </a>
        </SocialEntryItem>
        <SocialEntryItem>
          <a href="https://twitter.com/moniwar_game" target='_blank' rel="noreferrer">
            <svg width={24} height={20} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M23.643 2.93708C22.808 3.30708 21.911 3.55708 20.968 3.67008C21.941 3.08787 22.669 2.17154 23.016 1.09208C22.1019 1.63507 21.1014 2.01727 20.058 2.22208C19.3564 1.47294 18.4271 0.976403 17.4143 0.809551C16.4016 0.642699 15.3621 0.814868 14.4572 1.29933C13.5524 1.78379 12.8328 2.55344 12.4102 3.48878C11.9875 4.42412 11.8855 5.47283 12.12 6.47208C10.2677 6.37907 8.45564 5.89763 6.80144 5.05898C5.14723 4.22034 3.68785 3.04324 2.51801 1.60408C2.11801 2.29408 1.88801 3.09408 1.88801 3.94608C1.88757 4.71307 2.07644 5.46832 2.43789 6.14481C2.79934 6.8213 3.32217 7.39812 3.96001 7.82408C3.22029 7.80054 2.49688 7.60066 1.85001 7.24108V7.30108C1.84994 8.37682 2.22204 9.41945 2.90319 10.2521C3.58434 11.0847 4.53258 11.656 5.58701 11.8691C4.9008 12.0548 4.18135 12.0821 3.48301 11.9491C3.78051 12.8747 4.36001 13.6841 5.14038 14.264C5.92075 14.8439 6.86293 15.1653 7.83501 15.1831C6.18484 16.4785 4.1469 17.1812 2.04901 17.1781C1.67739 17.1782 1.30609 17.1565 0.937012 17.1131C3.06649 18.4823 5.54535 19.2089 8.07701 19.2061C16.647 19.2061 21.332 12.1081 21.332 5.95208C21.332 5.75208 21.327 5.55008 21.318 5.35008C22.2293 4.69105 23.0159 3.87497 23.641 2.94008L23.643 2.93708Z" />
            </svg>
          </a>
        </SocialEntryItem>
        <SocialEntryItem>
          <a href="https://www.youtube.com/c/MoniwarOfficial" target='_blank' rel="noreferrer">
            <svg width={20} height={16} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 0C0.172 0 0 0.908052 0 8C0 15.0919 0.172 16 10 16C19.828 16 20 15.0919 20 8C20 0.908052 19.828 0 10 0ZM13.205 8.34701L8.715 10.5247C8.322 10.7138 8 10.5018 8 10.0509V5.94909C8 5.49922 8.322 5.28623 8.715 5.47533L13.205 7.65299C13.598 7.84416 13.598 8.15584 13.205 8.34701Z" />
            </svg>
          </a>
        </SocialEntryItem>
      </SocialEntry>
    </Container>
  );
};

export default PanelFooter;
