import firebase from 'config/firebaseConfig'

export const socialMediaAuth = (provider)=>{
    // return firebase.auth().signInWithRedirect(provider).then(resp =>{
    //     return resp
    // }).catch(error=>{
    //     const strErr = JSON.stringify(error)
    //     const jsonErr = JSON.parse(strErr)              
    //     return {status:'error', ...jsonErr}
    // })
    return firebase.auth().signInWithRedirect(provider)
}
