import React from 'react';
import CardPopup from 'react-modal';
import styled from 'styled-components';

interface BountyModalProps {
  isOpen?: boolean,
  onRequestClose?: (value) => void,
}

const PopupFail: React.FC<BountyModalProps> = ({ isOpen, onRequestClose }) => {
  return (
    <CardPopup
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      closeTimeoutMS={300}
      style={customStyles}
    >
      <ContentModal onClick={() => onRequestClose('ok')} />
    </CardPopup>
  )
}

export default PopupFail
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 20
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    overflow: 'initial',
    position: 'initial'
  },
};


CardPopup.setAppElement('#root');

const ContentModal = styled.div`
  background: url('/images/bg-lucky-wheel.jpg') center no-repeat ;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-size:contain;
  width: 308px;
  height:176px;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 700px;
    height:400px;
    }
  &:hover{
    cursor: pointer;
  }
`