import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Roboto', sans-serif;
  }
  body {
    /* background-color: ${({ theme }) => theme.colors.background}; */
    background-color: #14141B;
    div.body-content{
      background: #14141B url('images/bg-pageHeader-dark.png') top center no-repeat;
    }    
    img {
      height: auto;
      max-width: 100%;
    }

    .ReactModal__Overlay {
      opacity: 0;
      transition: opacity 300ms ease-in-out;
      background-color: rgba(0,0,0,0.7);
    }

    .ReactModal__Overlay--after-open{
      opacity: 1;
    }

    .ReactModal__Overlay--before-close{
      opacity: 0;
    }
  }

  #root.affix-top .fix-popup{
    top:10px ;
    transition: all 0.2s ease
  } 
  #root.affix-top-show .fix-popup{
    left:120px ;
    transition: all 0.2s ease
  } 
`

export default GlobalStyle
