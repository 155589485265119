import React from 'react'
import styled from 'styled-components'

const ComingSoon: React.FC = () => {  
 
  return (
    <WrapperHome>
      <Banner />
      <Content>
        <h1 className="coming">Coming Soon</h1>       
      </Content>
    </WrapperHome>
  )
}

export default ComingSoon

const WrapperHome = styled.div`
background-color: #14141B;
`
const Banner = styled.div`
  ${({ theme }) => theme.mediaQueries.xs} {
    background: #14141B url('/images/moniwar-banner-home-dapp.png') center no-repeat;    
    height: 230px;
  }
 ${({ theme }) => theme.mediaQueries.sm} {
  background: #14141B url('/images/banner_home.png') center no-repeat;  
  height: 500px;
  }
`
const Content = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 250px;
    
    h1.coming{
      font-weight: 700;
      font-size: 60px;
      background: #BF893D;
      background: -webkit-gradient(linear,left bottom,left top,color-stop(40%,#BF893D),to(#FFD493));
      background: linear-gradient(to top,#BF893D 40%,#FFD493 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      text-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
      text-align: center;
      line-height: 65px;
    }

    
`