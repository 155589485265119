import BigNumber from 'bignumber.js';
import { GAS_GWEI, multichainConfig } from 'config/chains';
import { ethers } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import { getBalanceNumber } from 'utils/formatBalance';
import { onPostSvApi } from "./apiHelper";

export const sleep = (ms: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

export const emailIsValid = (value: any) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
}

export const decimalIsVaild = (value: any) => {
  return /^-?\d*[.]?\d*$/.test(value)
}

export const formatDecimal = (value: any, decimals: number) => {
  const regexPattern = /^-?[0-9]+$/;
  const result = regexPattern.test(value);
  if (result) {
    return value
  }
  return parseFloat(`${value.toString().split('.')[0]}.${value.toString().split('.')[1].substr(0, decimals)}`)
}

export const onHowToUse = (url) => {
  return window.open(url, "_blank")
}


export const onLogErrorAction = (params) => {
  return true
  // const data = {
  //   account: params?.account || '',
  //   transactionHash: params?.transactionHash || '',
  //   message: params?.message || '',
  //   url: params?.url || '',
  //   action: params?.action || ''
  // }  
  // onPostSvApi('logerror', data, params?.account).then((res) => {
  //   return true
  // })

}


export const onSelectCountdown = (time) => {
  const DATE_NOW = Math.round(new Date().getTime() / 1000);
  let _timeStamp
  switch (time) {
    case 1:
      _timeStamp = DATE_NOW + (3600 * 12) // 12 giờ
      break
    case 2:
      _timeStamp = DATE_NOW + (3600 * 24) // 24 giờ
      break
    case 3:
      _timeStamp = DATE_NOW + (3600 * 24 * 3) // 3 ngày
      break
    case 4:
      _timeStamp = DATE_NOW + (3600 * 24 * 7) // 7 ngày
      break
    case 5:
      _timeStamp = DATE_NOW + (3600 * 24 * 14) // 14 ngày
      break
    case 6:
      _timeStamp = DATE_NOW + (60 * 3) // 3 phút
      break
    case 7:
      _timeStamp = DATE_NOW + (60 * 10) // 20 phút
      break
    case 8:
      _timeStamp = DATE_NOW + (60 * 20) // 20 phút
      break
    case 9:
      _timeStamp = DATE_NOW + (60 * 30) // 30 phút
      break
    default:
      break;
  }

  return _timeStamp
}

export const TYPE_PRICE = {
  mowa: 1,
  bnb: 2,
  usdt: 3
}

export const DROPDOWN_LIST = [
  {
    label: 'All NFT',
    value: 'all',
  },
  {
    label: 'Pets',
    value: 'pet',
  },
  {
    label: 'Gems',
    value: 'gem',
  },
  {
    label: 'Pet Pieces',
    value: 'pet_piece',
  },
  {
    label: 'Pet Souls',
    value: 'pet_soul',
  },
  {
    label: 'Lucky charm',
    value: 'charm',
  },
]

export const LIST_SORT = [
  {
    label: 'Default',
    value: 'default',
  },
  {
    label: 'Newest',
    value: 'newest',
  },
  {
    label: 'Oldest',
    value: 'oldest',
  },
  {
    label: 'MOWA: Low to high',
    value: 'price_asc',
  },
  {
    label: 'BNB: Low to high',
    value: 'price_chain_asc',
  },
  {
    label: 'MOWA: High to low',
    value: 'price_desc',
  },
  {
    label: 'BNB: High to low',
    value: 'price_chain_desc',
  },
]

export const LIST_SORT_AUCTION = [
  {
    label: 'Newest',
    value: 'newest',
  },
  {
    label: 'Oldest',
    value: 'oldest',
  },
  {
    label: 'MOWA: Low to high',
    value: 'price_asc',
  },
  {
    label: 'BNB: Low to high',
    value: 'price_chain_asc',
  },
  {
    label: 'USDT: Low to high',
    value: 'price_usdt_asc',
  },
  {
    label: 'MOWA: High to low',
    value: 'price_desc',
  },
  {
    label: 'BNB: High to low',
    value: 'price_chain_desc',
  },
  {
    label: 'USDT: High to low',
    value: 'price_usdt_desc',
  },
  {
    label: 'Auction ends',
    value: 'done_auction',
  },
]

/**
 * 
 * format số 
 */
export const onPrecise = (x) => {
  return x.toPrecision(6);
}

export const isInt = (n) => {
  return n % 1 === 0;
}

export const convertTodate = (time) => {
  return new Date(time * 1000).toLocaleString()
}

export const convertToTime = (duration) => {
  const milliseconds = Math.floor((duration % 1000) / 100)
  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

  const _minutes = (minutes === 0) ? '' : (minutes < 10) ? `0${minutes}m` : `${minutes}m`;
  const _seconds = (seconds < 10) ? `0${seconds}s` : `${seconds}s`;
  const _hours = hours > 0 ? `${hours}h` : '';

  return `${_hours} ${_minutes} ${_seconds} ${milliseconds}ms`
}

/**
 *  Check chỉ nhập text và số 
 * @param val : string 
 * @returns true/false
 */
export const onRegInput = (val) => {
  return /^-?[a-zA-Z0-9\s]+$/.test(val)
}

export const convertGas = (gasPrice) => {
  return parseUnits(gasPrice, 'gwei').toString()
}

export const walletBalanceToken = async (rpcUrl, account) => {
  const simpleRpcProvider = new ethers.providers.JsonRpcProvider(rpcUrl)
  const walletBalance = await simpleRpcProvider.getBalance(account)
  return new BigNumber(walletBalance.toString())
}

export const getBalanceMowa = async (mowaContract, account) => {
  mowaContract.balanceOf(account).then(res => {
    return res.toString()
  }).catch(err => {
    return '0'
  })
}

export const onEstimateGas = async (chainId, account, getEstimateGas) => {
  try {
    const RPC_URL = multichainConfig[chainId].rpcUrl
    const balanceWallet = await walletBalanceToken(RPC_URL, account) // call balance of wallet token 

    const gasPrice = GAS_GWEI[chainId].gasWei * parseInt(getEstimateGas.toString());
    const _getGasPrice = convertGas(gasPrice.toString());
    const _convertGasPrice = getBalanceNumber(new BigNumber(_getGasPrice))
    const _convertBalance = getBalanceNumber(balanceWallet)    
    
    if (+_convertBalance < +_convertGasPrice) {
      const msg = `The estimated gas fee is ${_convertGasPrice} ${GAS_GWEI[chainId].symbol}, please get more ${GAS_GWEI[chainId].symbol} to continute`
      return { code: false, msg }
    }
    return { code: true }
  } catch (error) {
    return { code: false, msg: error?.data?.message || error?.message }
  }
}
