import React, { useEffect, useState, useRef } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useMowaMinningNftContract, useMowaNFTContract } from 'hooks/useContract'
import { getMowaMinningNFTAddress, getMowaNFTAddress } from 'utils/addressHelpers'
import MowaMiningNftAbi from 'config/abi/MowaMinningNFTAbi.json'

import multicall from 'utils/multicall'

const useMiningNft = (pid) => {
  const { account } = useWeb3React() 
  const [minningInf, setMiningInf] = useState(null)
  const [isRefresh, setIsRefresh] = useState(false)
  useEffect(() => {
    if (!account) return
    async function onCheckContract() {
      const call = [
        {
          address: getMowaMinningNFTAddress(),
          name: 'userInfo',
          params: [pid, account],
        },
        {
          address: getMowaMinningNFTAddress(),
          name: 'getUserInfo',
          params: [pid, account],
        },   
       
      ]
      const [userInfo, pooluserInfo] = await multicall(MowaMiningNftAbi, call)
      setMiningInf({ userInfo, pooluserInfo, })
    }
    onCheckContract()

  }, [account, pid, isRefresh])

  return { minningInf, onRefesh: () => setIsRefresh(!isRefresh) }
}

export default useMiningNft
