import {
  Text, useMatchBreakpoints
} from '@pancakeswap/uikit';
import CardItem from 'components/CardRow/CardItemMining';
import CardItemMobile from 'components/CardRow/CardItemMiningMobile';
import FilterControl from 'components/FilterControl';
import Rows from 'components/Layout/Rows';
import SearchInput from 'components/SearchInput';
import { useTranslation } from 'contexts/Localization';
import React, { useRef, useState } from 'react';
import CardPopup from 'react-modal';
import styled from 'styled-components';
import { DataTab } from './Config';
import Loading from './Loading';

interface PopupNftModalProps {
  onSelectItem?: (value) => void,
  dataSelect?: any
  dataDefault?: any
  isOpen?: boolean
  onRequestClose?: () => void
  onAfterOpen?: () => void
}

const PopupNft: React.FC<PopupNftModalProps> = ({ onRequestClose, onSelectItem, onAfterOpen, dataDefault, isOpen }) => {
  const { t } = useTranslation()
  const [isFilter, setIsFilter] = useState('all')
  const [dataList, setDataList] = useState(dataDefault)
  const [isLoading, setIsLoading] = useState(true)
  const { isMobile } = useMatchBreakpoints()

  const [textSearch, setTextSearch] = useState('')
  const defaultSearch = useRef([])

  /**
   * format data form datalist
   * @param tabFilter 
   * @returns 
   */
  const formatDataItems = (tabFilter = "all") => {
    const items = []
    if (dataList) {
      switch (tabFilter) {
        case "pets":
          if (dataList.pets) {
            Object.keys(dataList.pets).forEach(function (key) {
              if (dataList.pets[key]) {
                items.push({ ...dataList.pets[key], type: 'pets' });
              }
            });
          }
          break;
        case "gems":
          if (dataList.gems) {
            Object.keys(dataList.gems).forEach(function (key) {
              if (dataList.gems[key]) {
                items.push({ ...dataList.gems[key], type: 'gems' });
              }
            });
          }
          break;
        case "boxs":
          if (dataList.boxs) {
            Object.keys(dataList.boxs).forEach(function (key) {
              if (dataList.boxs[key]) {
                items.push({ ...dataList.boxs[key], type: 'boxs' });
              }
            });
          }
          break;
        // case "cards":
        //   if (dataList.cards) {
        //     Object.keys(dataList.cards).forEach(function (key) {
        //       if (dataList.cards[key]) {
        //         items.push({ ...dataList.cards[key], type: 'cards' });
        //       }
        //     });
        //   }
        //   break;
        case "charms":
          if (dataList.charms) {
            Object.keys(dataList.charms).forEach(function (key) {
              if (dataList.charms[key]) {
                items.push({ ...dataList.charms[key], type: 'charms' });
              }
            });
          }
          break;
        case "pet_pieces":
          if (dataList.pet_pieces) {
            Object.keys(dataList.pet_pieces).forEach(function (key) {
              if (dataList.pet_pieces[key]) {
                items.push({ ...dataList.pet_pieces[key], type: 'pet_pieces' });
              }
            });
          }
          break;
        case "pet_souls":
          if (dataList.pet_souls) {
            Object.keys(dataList.pet_souls).forEach(function (key) {
              if (dataList.pet_souls[key]) {
                items.push({ ...dataList.pet_souls[key], type: 'pet_souls' });
              }
            });
          }
          break;
        case "tickets":
          if (dataList.tickets) {
            Object.keys(dataList.tickets).forEach(function (key) {
              if (dataList.tickets[key]) {
                items.push({ ...dataList.tickets[key], type: 'tickets' });
              }
            });
          }
          break;
        default:
          // code tab all
          // code tab all
          if (dataList.pets) {
            Object.keys(dataList.pets).forEach(function (key) {
              if (dataList.pets[key]) {
                items.push({ ...dataList.pets[key], type: 'pets' });
              }
            });
          }
          if (dataList.gems) {
            Object.keys(dataList.gems).forEach(function (key) {
              if (dataList.gems[key]) {
                items.push({ ...dataList.gems[key], type: 'gems' });
              }
            });
          }
          if (dataList.boxs) {
            Object.keys(dataList.boxs).forEach(function (key) {
              if (dataList.boxs[key]) {
                items.push({ ...dataList.boxs[key], type: 'boxs' });
              }
            });
          }
          // if (dataList.cards) {
          //   Object.keys(dataList.cards).forEach(function (key) {
          //     if (dataList.cards[key]) {
          //       items.push({ ...dataList.cards[key], type: 'cards' });
          //     }
          //   });
          // }
          if (dataList.charms) {
            Object.keys(dataList.charms).forEach(function (key) {
              if (dataList.charms[key]) {
                items.push({ ...dataList.charms[key], type: 'charms' });
              }
            });
          }
          if (dataList.pet_pieces) {
            Object.keys(dataList.pet_pieces).forEach(function (key) {
              if (dataList.pet_pieces[key]) {
                items.push({ ...dataList.pet_pieces[key], type: 'pet_pieces' });
              }
            });
          }
          if (dataList.pet_souls) {
            Object.keys(dataList.pet_souls).forEach(function (key) {
              if (dataList.pet_souls[key]) {
                items.push({ ...dataList.pet_souls[key], type: 'pet_souls' });
              }
            });
          }
          if (dataList.tickets) {
            Object.keys(dataList.tickets).forEach(function (key) {
              if (dataList.tickets[key]) {
                items.push({ ...dataList.tickets[key], type: 'tickets' });
              }
            });
          }
          break;
      }
    }
    return items
  }

  /**
   * Change tab name and reset textSearch, data search  
   * @param name 
   */
  const handleClickTab = name => {
    defaultSearch.current = [];
    setTextSearch('');
    setIsFilter(name)
  }

  const _onSelect = item => {
    onSelectItem(item)
    onRequestClose()
  }

  const _renderLoading = () => {
    return (
      [1, 2, 3, 4, 5].map((_) => <Loading />)
    )
  }

  const _renderTab = () => {
    return (
      <ul className="filterTabs">
        {DataTab.map(_ =>
          <FilterTabsLi className={isFilter === _.name ? 'active' : ''} onClick={() => handleClickTab(_.name)}>
            {t(_.label)}
          </FilterTabsLi>)}
      </ul>
    )
  }

  const renderItems = arr => {
    if (isMobile && arr?.length) {
      return arr?.map(item => {
        return <CardItemMobile data={item} onSelect={() => _onSelect(item)} />
      })
    }

    if (arr?.length && !isMobile) {
      return arr?.map(item => {
        return <CardItem data={item} onSelect={() => _onSelect(item)}/>
      })
    }
    return (
      <Text style={{ display: 'flex', width: '100%', height: '300px', justifyContent: 'center', alignItems: 'center' }}>No data yet</Text>
    )

  }

  /**
   * render content Items
   * 
   * @returns 
   */
  const _renderDefault = () => {
    const items = formatDataItems(isFilter)
    defaultSearch.current = items;
    return renderItems(items)
  }

  /**
   * onChange text search return result search
   * @param searchText 
   * @returns 
   */
  const handleSearch = searchText => {
    const dbSearch = defaultSearch.current;
    if (searchText) {
      const filerSearch = dbSearch.filter((item) => {
        const itemData = item.name ? item.name.toUpperCase() : ''.toUpperCase();
        const textData = searchText.toUpperCase();
        return itemData.indexOf(textData) > -1;
      })
      defaultSearch.current = filerSearch;
      setTextSearch(searchText)
      return
    }
    setTextSearch('')
  }

  return (
    <CardPopup
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={onAfterOpen}
      style={customStyles}
      closeTimeoutMS={300}
      shouldCloseOnOverlayClick={false}
    >
      <StyledModal>
        <div className="modal-header">
          {t('Please select NFT')}
          <button type="button" className="close" onClick={onRequestClose}>
            <img src="/images/close.png" alt="close" />
          </button>
        </div>
        <PopupWrapper>
          <FilterControl>
            {_renderTab()}
            <FilterContainer>
              <LabelWrapper style={{ width: 260 }}>
                <SearchInput onChange={(e) => handleSearch(e.target.value)} placeholder="Search name" defautValue={textSearch} />
              </LabelWrapper>
            </FilterContainer>
          </FilterControl>
          <SectionContent>
            <Rows margin="0 -15px">
              {textSearch !== '' ? renderItems(defaultSearch.current) : _renderDefault()}
            </Rows>
          </SectionContent>
        </PopupWrapper>
      </StyledModal>
    </CardPopup>
  )
}
export default PopupNft

const StyledModal = styled.div` 
  max-width: 375px;
  height: 600px;   
  ${({ theme }) => theme.mediaQueries.sm} {
    max-width: 1200px;
    height: 600px;   
  }
  border:none;
  background:#1B212F;   
  border-radius:20px;

  div.modal-header {
    background: #2C3145;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position:relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-left:30px;
    color: #fff;
    font-size:16px;
    font-weight: bold;
    button.close {
      position: absolute;
      right: 15px;
      width: 50px;
      height: 50px;
      display: block;
      justify-items: center;
      text-align: center;
      padding-top: 7px;
      border:none;
      background: transparent;
      &:hover{
        cursor: pointer;
      }
    }
  }
  div.title{
    text-align: center;
    padding-top: 15px;
  } 
`

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: 20,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    backgroundColor: 'transparent',
    border: 'none',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
  },
};

CardPopup.setAppElement('#root');


const PopupWrapper = styled.div`
  padding:30px;
  background: #1B212F;  
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: 'space-between';
  width: 100%;
  padding: 8px 0px;
  position: relative;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
}`

const LabelWrapper = styled.div`
  > ${Text} { font-size: 12px;}`

const SectionContent = styled.div`
  margin: 10px 0;
  height: 290px;  
  ${({ theme }) => theme.mediaQueries.sm} {
    margin: 30px 0;
    height: calc(600px - 180px);     
    }
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    ${({ theme }) => theme.mediaQueries.lg} {
      width: 10px;
      height: 10px;
    }
  }
    ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
    ::-webkit-scrollbar-thumb {
      background: #888; 
      border-radius:10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  padding-right: 20px; 
 `
const FilterTabsLi = styled.li`
  transition: all .2s linear;
`