import { Flex } from '@pancakeswap/uikit';
import React, { useState, memo } from 'react';
import styled from 'styled-components';
import OutsideAlerter from './OutsideElement';
import PanelNotify from './PanelNotify';

const NotificationMenu = () => {
  const [show, setShow] = useState(false)
  const onShowNotify = () => setShow(!show)  
  const [status, setStatus] = useState(false)

  return <OutsideAlerter onHide={()=>setShow(false)}><Flex>
    <NofifyIcon onClick={onShowNotify}>
      <div>
        {status && <StatusIcon />}
        <img src="/images/notifications.png" alt="bells" />
      </div>
    </NofifyIcon>   
    <PanelNotify isVisible={show} onStatus={val=>setStatus(val)} />
  </Flex>
  </OutsideAlerter>

}

export default memo(NotificationMenu)

const NofifyIcon = styled(Flex)`   
    display:flex;
    align-items:center;
    justify-content: center;
    width:50px;
    height:50px;
    >div:first-child{
         position:relative;
        img{
            width:24px;
            height:24px;
        }
    }
    &:hover{
        cursor: pointer;
    }

`
const StatusIcon = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background: #E5A95A;
    position: absolute;
    top: 4px;
    right: 3px;
`