import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function index() {
  const LinkTo = '/mowa-spin'
  const _onClick = () => {
    postMessage(LinkTo, '*')
  }
  return <ButtonSpin as={Link} to={LinkTo} className="btn-spin-home" onClick={_onClick} />
}


const ButtonSpin = styled.div`
  background: url('/images/spin-result/btn-home-spin.png') center no-repeat;
  background-size: cover;  
  position: relative;
  width: 100px;
  height: 49px;
  position: fixed;
  bottom:15px;
  right:15px;
  z-index:10;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 125px;
    height: 60px;
  }
  &:hover{
    cursor: pointer;
  }
`