export const DataTab = [
  {
    id: 1,
    label: 'All',
    name: 'all',
  },  
  {
    id: 3,
    label: 'Gems',
    name: 'gems',
  }, 
  {
    id: 4,
    label: 'Pets',
    name: 'pets',
  },
   {
    id: 5,
    label: 'Pet Pieces',
    name: 'pet_pieces',
  },
   {
    id: 6,
    label: 'Pet Souls',
    name: 'pet_souls',
  },
  // {
  //   id: 7,
  //   label: 'Cards',
  //   name: 'cards',
  // },
  {
    id: 8,
    label: 'Charms',
    name: 'charms',
  }, 
   {
    id: 9,
    label: 'Tickets',
    name: 'tickets',
  },
]
