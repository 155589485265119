import MowaMiningNftAbi from 'config/abi/MowaMinningNFTAbi.json'
import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { getMowaMinningNFTAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'


const useMowaEarn = (pid) => {
  const { account } = useWeb3React()
  const [mowaEarn, setMowaEarn] = useState(null)
  useEffect(() => {
    if (!account) return
    async function onMowaEarnContract() {
      try {        
        const call = [     
          {
            address: getMowaMinningNFTAddress(),
            name: 'pendingRewards',
            params: [pid, account],
          },       
        ]
        const [pendingRewards] = await multicall(MowaMiningNftAbi, call)
        setMowaEarn({pendingRewards})
      } catch (error) {
        console.log('error', error);
        setMowaEarn(null)
      }
    }
    onMowaEarnContract()
  },[account, pid])
  return { mowaEarn }
}

export default useMowaEarn
