import styled from 'styled-components'

const Buttons = styled.button<{
  fontSize?: string,
  color?: string,
  height?: string,
  width?: string
  disabled?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  max-width: 100%;
  border-radius: 10px;
  background: ${({ disabled }) => disabled ? '#3c3742' : 'linear-gradient(360deg, #BF893D 0%, #FFD493 137.5%)'} ;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'} ;
  font-weight: 700;
  transition: all .2s linear;
  /* opacity: ${({ disabled }) => disabled ? 0.5 : 1}; */
  &:hover{
    opacity: ${({disabled})=> disabled? 1 : .6};
  }
`
export default Buttons
