import { Button, Flex, InjectedModalProps, Modal, Text, useModal } from '@pancakeswap/uikit'
import { useWeb3React } from '@web3-react/core'
import ConfirmEmail from 'components/PopupConfirmEmail2'
import { appleProvider, googleProvider } from 'config/authMethods'
import React, { useRef, useState } from 'react'
import { setCookiesAuth } from 'state/auth/hooks'
import styled from 'styled-components'
import { emailIsValid } from 'utils/utilsFun'
import { postApi } from "../../../utils/apiHelper"
import ForgotModal from './ForgotModal'
import RegisterModal from './RegisterModal'



const LoginModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const [isShowPass, setIsShowPass] = useState(false)
  const [isShowError, setIsShowError] = useState(null)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { account } = useWeb3React()
  const [isOpen, setIsOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const typeRef = useRef('')


  const handleShowPass = () => {
    setIsShowPass(!isShowPass)
  }

  const onChagneEmail = (e) => {
    setEmail(e.target.value)
  }

  const onSubmit = () => {
    const msgErr = []
    if (email === "") {
      msgErr.push("Email not empty")
    } if (password === "") {
      msgErr.push("Password not empty")
    }
    if (msgErr.length === 0) {
      setLoading(true)
      let isEmail = ''
      if (emailIsValid(email)) {
        isEmail = email.toLowerCase()
      }
      const data = {
        "username": isEmail === '' ? email : isEmail,
        "password": password
      }
      postApi('/auth/login', data).then(resp => {
        setLoading(false)
        if (account !== resp.data.data.wallet) {
          setIsShowError("Wallet does not match");
        } else {
          setCookiesAuth('auth', resp.data);
          setIsShowError(null);
          window.location.reload();
        }
      }).catch(er => {
        setLoading(false)
        const error = { ...er }
        if (error.response.status === 401) {
          setIsShowError("Email/Password is invalid")
        }
        if (error.response.status === 403) {
          setIsShowError("Your account is locked")
        }
      })
    }
  };

  const [gotoRegister] = useModal(<RegisterModal />, false)
  const [forgotModal] = useModal(<ForgotModal />, false)


  const _onRequestClose = async (value) => {
    setIsOpen(false)
    if (value === 'ok') {
      await onSubmit()
    }
  }

  const onHandleLogin = async (provider) => {
    const _findProvider = provider === appleProvider ? 'apple.com' : provider === googleProvider ? 'google.com' : 'facebook.com'
    typeRef.current = _findProvider;
    setLoading(true)
    // const resSocial = await socialMediaAuth(provider)
    // if (resSocial?.status === 'error') {
    //   setLoading(false)
    //   typeRef.current = ''
    //   toastError('Error:', resSocial.code)
    //   return
    // }
    // const params = resSocial.uid ? {
    //   provider: _findProvider,
    //   uuid: resSocial.uid,
    //   email: resSocial.email,
    //   wallet: account || ''
    // } : {
    //   provider: _findProvider,
    //   uuid: resSocial.uid,
    //   wallet: account || ''
    // }
    // postApi('/users/login_social', params).then(resp => {
    //   setLoading(false)
    //   typeRef.current = ''
    //   setCookiesAuth('auth', resp.data);
    //   onDismiss()
    //   window.location.reload();
    // }).catch(async (error) => {
    //   console.log('error login', error.response)
    //   typeRef.current = ''
    //   toastError('Error:', error?.response?.data?.errors?.length ? error?.response?.data?.errors[0]?.code : error?.response?.data?.message)
    //   await firebase.auth().signOut().then(resp => {
    //     console.log('res');
    //   }).catch(e => console.log('error logout', e));
    //   setLoading(false)
    // })
  }

  return (<>
    <ModalPopup
      title="Login"
      headerBackground="#2C3145"
      onDismiss={loading ? null : onDismiss}
    >
      <ModalContainer>
        <RowForm>
          <p>Email</p>
          <div className="form-control-position">
            <input type="text" className="form-control" id="email" name="email"
              placeholder="Enter your email" value={email} onChange={(e) => onChagneEmail(e)} />
          </div>
        </RowForm>
        <RowForm>
          <p>Password</p>
          <div className="form-control-position">
            <input type={isShowPass ? 'text' : 'password'} className="form-control pr" id="password"
              name="password" placeholder="Enter your password"
              value={password} onChange={(e) => setPassword(e.target.value)} />
            {isShowPass ? (
              <Fal onClick={handleShowPass}><i className="fal fa-eye-slash" /></Fal>
            ) : (
              <Fal onClick={handleShowPass}><i className="fal fa-eye" /></Fal>
            )}
          </div>
        </RowForm>
        <ErrorDiv className={isShowError ? 'show' : 'hide'}>{isShowError}</ErrorDiv>
        <RowForm>
          <Button disabled={loading} onClick={onSubmit} color="primary" width="100%" style={{ borderRadius: '10px', boxShadow: 'inherit', position: 'relative' }}
            margin="10px 0 0">
            {
              loading ? <>
                <LoadingIcon src="/images/loading.gif" alt="loading" />
                Processing...
              </> : 'Login'
            }
          </Button>
        </RowForm>
        <Text textAlign='center' color='rgba(255,255,255,0.6)' className='text-login' >Or login by</Text>
        <Flex alignItems='center' justifyContent="center" style={{ gap: 15 }} pt="15px" pb="20px">
          {/* <Button
            onClick={() => onHandleLogin(facebookProvider)}
            variant="text"
            disabled={loading}
            style={{
              fontSize: 20,
              color: 'white',
              width: 50,
              height: 50,
              borderRadius: 25,
              background: '#3578E5',
              opacity: (typeRef.current === '') || (loading && typeRef.current && typeRef.current === 'facebook.com') ? 1 : 0.3
            }}><i className='fab fa-facebook-f' /></Button> */}
          <Button
            onClick={() => onHandleLogin(googleProvider)}
            variant="text"
            disabled={loading}
            style={{
              fontSize: 20,
              color: 'white',
              width: 50,
              height: 50,
              borderRadius: 25,
              background: '#ea5f54',
              opacity: (typeRef.current === '') || (loading && typeRef.current && typeRef.current === 'google.com') ? 1 : 0.3
            }}><i className='fab fa-google' /></Button>
          <Button
            disabled={loading}
            onClick={() => onHandleLogin(appleProvider)}
            variant="text"
            style={{
              fontSize: 20,
              color: 'white',
              width: 50,
              height: 50,
              borderRadius: 25,
              background: 'black',
              opacity: (typeRef.current === '') || (loading && typeRef.current && typeRef.current === 'apple.com') ? 1 : .3
            }}>
            <i className='fab fa-apple' /></Button>
        </Flex>
        <div className="gotoRegister"><Text as="span" onClick={gotoRegister}>Create a Moniwar account</Text></div>
        <div className="gotoRegister"><Text as="span" onClick={forgotModal}>Forgot Password?</Text></div>
      </ModalContainer>
    </ModalPopup>
    {isOpen &&
      <ConfirmEmail
        isOpen={isOpen}
        onRequestClose={_onRequestClose}
        data={email}
      />
    }
  </>
  )
}

export default LoginModal

const ModalPopup = styled(Modal)`
    max-width: calc(100% - 30px);
    overflow-y: 'auto';
    border-radius:20px;
    width: 380px;
    background:#1B212F;
    div:first-child{
       button svg {
            fill:#fff;
        }

    }
`
const LoadingIcon = styled('img')`
  width: 30px;
  height: 30px;
  position: absolute;
  left:15px;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0;
  .gotoRegister{
    text-align: center;
    color: #fff;
    &:hover{
        cursor: pointer;
        span{
            color: rgba(255,255,255,.6);
        }
    }
  }
  .text-login {
    position: relative ;
    &::before{
      height: 1px ;
      background-color: rgba(255,255,255,.6) ;
      content:'' ;
      top:10px;
      left:0;
      width: 100px;
      position:absolute ;
    }
    &::after{
      height: 1px ;
      background-color: rgba(255,255,255,.6) ;
      content:'' ;
      top:10px;
      right:0;
      width: 100px;
      position:absolute ;
    }
  }
`

const RowForm = styled.div`
  margin-bottom: 20px;
  p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: 700;
  }

  .form-control-position{
    position: relative;
    margin-top: 10px;

    .form-control{
      height: 40px;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 0 20px;
      color: ${({ theme }) => theme.colors.textSubtle};
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      background:rgba(0, 0, 0, 0.5);;
      &:focus{
        outline: none;
        border: 1px solid #c4c4c4 !important;
        border-radius: 10px !important;
      }

      &.pr{
        padding-right: 50px
      }
    }
  }  
`
const Fal = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  transform: translate(0,-50%);
  color:#333;
`

const ErrorDiv = styled.div`
  visibility: hidden;
  color: red;
  font-size: 12px;
  text-align: center;
  position: relative;
  top: -5px;  

  &.show{
    visibility: inherit;
  }
`

const ReCaptchaDiv = styled.div`
  transform:scale(0.97);
  transform-origin:0 0;

  ${({ theme }) => theme.mediaQueries.xs} {
    transform:scale(1);
  }
`
