import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import ConfirmEmail from 'components/PopupConfirmEmail2';
import BoxGift from 'components/PopupGiftBox';
import useToast from 'hooks/useToast';
import md5 from 'md5';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';
import history from 'routerHistory';
import { removeCookiesAuth, setCookiesAuth } from 'state/auth/hooks';
import { getApi, onGetTokenSV, postApi } from "utils/apiHelper";


const SignUpComponent: React.FC = () => {
  const { account } = useWeb3React()
  const [cookies] = useCookies(['auth']);
  // const [userRefCode] = useCookies(['referral'])
  const userLogin = cookies.auth
  const { toastSuccess, toastError } = useToast()
  const { pathname, search } = useLocation()
  const _activeUrl = pathname + search
  const [isActive, setIsActive] = useState(0)
  const [isActive2, setIsActive2] = useState(0)
  const [isGiftBox, setIsGiftbox] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [email, setEmail] = useState('')
  const isUpdateRef = useRef(false)
  const textMsgErr = useRef('')
  const [isBreak, setIsBreak] = useState(false)




  useEffect(() => {
    if (account && !userLogin) {
      if (_activeUrl === '/profile?active=false' && isActive2 === 0) {
        setIsActive2(1)
        toastError('Error', 'Your account is not verified')
        return
      }
      if (_activeUrl === '/profile?active=true' && isActive === 0) {
        setIsActive(1)
        toastSuccess('Success', 'Account verified successfully')
      }
    }
  }, [userLogin, account, toastError, toastSuccess, _activeUrl, isActive2, isActive])

 
  

  useEffect(() => {  
    if (account) {
      if (!userLogin) {
        getApi(`/users/find_by_wallet/${account}`).then(resp => { // check account exist user, login nếu có tk
          if (resp.data) {
            const signature = md5(`${account}${process.env.REACT_APP_HASH_SECRET}`)
            const params = {
              wallet: account,
              signature
            }
            postApi(`/users/wallet_login`, params).then(async (res) => {
              if (res.data.data.account_verify) {              
                setCookiesAuth('auth', res.data);
                window.location.reload();
              } else {
                removeCookiesAuth('auth')
                removeCookiesAuth('token_sv')
                isUpdateRef.current = true
                setEmail(res.data.data.email)
                setIsOpen(true)
              }
            }).catch((errors) => {
              removeCookiesAuth('auth')
              removeCookiesAuth('token_sv')
              if (errors.response?.data.status_code === 400) {
                isUpdateRef.current = true
                textMsgErr.current = errors.response.data.message
                setEmail(errors.response.data.email)
                setIsOpen(true)
              }
            })
          }
        }).catch(ep => { // nếu ko có tk thì hiện thị component Sigup now                     

          document.getElementById('RegisterModal').click();
        })

      }
    }
    if (account && userLogin && userLogin?.data.wallet !== account) { // xử lý khi user đã login mà change wallet khác
      getApi(`/users/find_by_wallet/${account}`).then(resp => { // check account exist user, login nếu có tk
        if (resp.data) {
          const signature = md5(`${account}${process.env.REACT_APP_HASH_SECRET}`)
          const params = {
            wallet: account,
            signature
          }
          postApi(`/users/wallet_login`, params).then(async (res) => {
            if (res.data.data.account_verify) {             
              setCookiesAuth('auth', res.data);
              window.location.reload();
            } else {
              removeCookiesAuth('auth')
              removeCookiesAuth('token_sv')
              isUpdateRef.current = true
              setEmail(res.data.data.email)
              setIsOpen(true)
            }
          }).catch((errors) => {
            removeCookiesAuth('auth')
            removeCookiesAuth('token_sv')
            if (errors.response?.data.status_code === 400) {
              isUpdateRef.current = true
              setEmail(errors.response.data.email)
              setIsOpen(true)
            }
          })
        }
      }).catch((e) => {
        if (userLogin?.data.wallet !== account) {
          removeCookiesAuth('auth')
          window.location.reload();
        }
      })
    }


  }, [account, userLogin])


  useEffect(() => {
    if (!isBreak && account) {
      getApi(`/users/find_by_wallet/${account}`).then(resp => { // check account exist user, login nếu có tk
        if (resp.data) {
          const signature = md5(`${account}${process.env.REACT_APP_HASH_SECRET}`)
          const params = {
            wallet: account,
            signature
          }
          postApi(`/users/wallet_login`, params).then(res => {
            return true
          }).catch((errors) => {
            removeCookiesAuth('auth')
            if (errors.response?.data.status_code === 403) {
              document.getElementById('RegisterModal').click();
              toastError('Error', errors.response?.data?.msg)
            }
          })
        }
      })
      setIsBreak(true)
    }
  }, [toastError, account, isBreak])




  const _onClosePopup = (value) => {
    if (value) {
      setIsGiftbox(true)
      setIsOpen(false)
    } else {
      setIsOpen(false)
      window.location.reload()
    }
  }

  const onCloseGiftBox = val => {
    if (val === 'Gift-ok') {
      history.push('/bag')
    }
    setIsGiftbox(false)
    window.location.reload()
  }

  return <>
    {
      isOpen && <ConfirmEmail
        isOpen={isOpen}
        onRequestClose={_onClosePopup}
        data={email}
        isUpdateEmail={isUpdateRef.current}
        msgErr={textMsgErr.current}
      />
    }
    {isGiftBox && <BoxGift isOpen={isGiftBox} onRequestClose={onCloseGiftBox} />}
  </>
}

export default SignUpComponent
