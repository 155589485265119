import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  data: [],
}

export const getListBlindBox = createSlice({
  name: 'blindBox',
  initialState,
  reducers: {
    fetchDataList: (state, action) => {
      state.data = action.payload
    },
  },
})

// Actions
export const { fetchDataList } = getListBlindBox.actions
export const getDataList = (state) => state.blindBox

export default getListBlindBox.reducer
