import { poolsConfig, poolsNFTConfig } from 'config/constants'
import { PoolCategory, AddressMultiple } from 'config/constants/types'
import { ethers } from 'ethers'
import { simpleRpcProvider } from 'utils/providers'

// Addresses
// eslint-disable-next-line import/named
import {
  getAddress,
  getPancakeProfileAddress,
  getPancakeRabbitsAddress,
  getBunnyFactoryAddress,
  getBunnySpecialAddress,
  getCakeAddress,
  getLotteryV2Address,
  getMasterChefAddress,
  getPointCenterIfoAddress,
  getClaimRefundAddress,
  getTradingCompetitionAddress,
  getEasterNftAddress,
  getCakeVaultAddress,
  getPredictionsAddress,
  getChainlinkOracleAddress,
  getMulticallAddress,
  getBunnySpecialCakeVaultAddress,
  getBunnySpecialPredictionAddress,
  getBunnySpecialLotteryAddress,
  getFarmAuctionAddress,
  getKscAddress,
  getBksAddress,
  getAirdropAddress,
  getMemberOfKSharkAddress,
  getMowaAddress,
  getManagerAddress,
  getBlindBoxAddress,
  getMarketAddress,
  getMowaNFTAddress,
  getMowaNFTV2Address,
  getMasterChefNftAddress,
  getClaimNFTAddress,
  getMowaMinningNFTAddress,
  getClaimMultiNFTAddress,
  getMowaTestAddress,
  getClaimTokenAddress,
  getMowaMinningNFTNewAddress,
  getMarketNewAddress,
  getMowaNFTItemsAddress,
  getMowaOpenBoxAddress,
  getMowaOpenBoxV2Address,
  getMowaNFTUpgradeGemAddress,
  getMowaClaimTokenEventRewardAddress,
  getMowaClaimTokenPresaleAddress,
  getMowaClaimTokenPresaleV2Address,
  getMowaGiftNFTAddress,
  getMarketV2ddress,
  getMarketV3ddress,
  getMarketV4ddress,
  getMarketV5ddress,
  getMarketV6ddress,
  getMarketV7ddress,
  getPetCombineAddress,
  getPetUpgradeLevelAddress,
  getMasterChefNftV2Address,
  getWheelLuckyAddress,
  getLockedTokenAddress,
  getMowaAuctionAddress,
  getmowaTokenAirdopAddress,
  getmowaSpinAddress,
  getPetSkillUpgradeAddress,
  getShopNFTItemsAddress,
  getRenameUserAddress,
  getWealthyGameAddress,
  getOpenAirdropBoxAddress,
  getMowaAddressByChain,
  getBridgeMowaToCsc,
  getBlindboxCsc,
  getMowaNFTByChain,
  getMowaNFTItemsByChain,
  getMarketCSCByChain,
  getGiftNFTCSC,
  getMagicBoxCSC,
  getAirdropBoxCSC
} from 'utils/addressHelpers'

// ABI
import profileABI from 'config/abi/pancakeProfile.json'
import pancakeRabbitsAbi from 'config/abi/pancakeRabbits.json'
import bunnyFactoryAbi from 'config/abi/bunnyFactory.json'
import bunnySpecialAbi from 'config/abi/bunnySpecial.json'
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import cakeAbi from 'config/abi/cake.json'
import ifoV1Abi from 'config/abi/ifoV1.json'
import ifoV2Abi from 'config/abi/ifoV2.json'
import pointCenterIfo from 'config/abi/pointCenterIfo.json'
import lotteryV2Abi from 'config/abi/lotteryV2.json'
import masterChef from 'config/abi/masterchef.json'
import sousChef from 'config/abi/sousChef.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import sousChefBnb from 'config/abi/sousChefBnb.json'
import claimRefundAbi from 'config/abi/claimRefund.json'
import tradingCompetitionAbi from 'config/abi/tradingCompetition.json'
import easterNftAbi from 'config/abi/easterNft.json'
import cakeVaultAbi from 'config/abi/cakeVault.json'
import predictionsAbi from 'config/abi/predictions.json'
import chainlinkOracleAbi from 'config/abi/chainlinkOracle.json'
import MultiCallAbi from 'config/abi/Multicall.json'
import bunnySpecialCakeVaultAbi from 'config/abi/bunnySpecialCakeVault.json'
import bunnySpecialPredictionAbi from 'config/abi/bunnySpecialPrediction.json'
import bunnySpecialLotteryAbi from 'config/abi/bunnySpecialLottery.json'
import farmAuctionAbi from 'config/abi/farmAuction.json'
import airDropAbi from 'config/abi/airdrop.json'
import MowaAbi from 'config/abi/Mowa.json'
import ManagerAbi from 'config/abi/Manager.json'
import babySharkAbi from 'config/abi/babyshark.json'
import poolNftAbi from 'config/abi/kSharkFarmingNFTs.json'
import mowaBlindBoxAbi from 'config/abi/MowaBlindbox.json'
import mowaMarketAbi from 'config/abi/MowaNFTMarket.json'
import MowaNFTAbi from 'config/abi/MowaNFT.json'
import MowaNFTV2Abi from 'config/abi/MowaNFTV2.json'
import masterChefNft from 'config/abi/masterchefNft.json'
import masterChefNftV2 from 'config/abi/masterchefNftV2.json'
import ClaimNFTAbi from 'config/abi/MowaClaimNFT.json'
import ClaimMultiNFTAbi from 'config/abi/MowaClaimMultiNFT.json'
import MowaMiningNFTAbi from 'config/abi/MowaMinningNFTAbi.json'
import MowaMiningNFTNewAbi from 'config/abi/MowaMinningNFTNewAbi.json'
import MowaTestAbi from 'config/abi/MowaTest.json'
import ClaimTokenAbi from 'config/abi/claimToken.json'
import mowaMarketNewAbi from 'config/abi/MowaMarketNewAbi.json'
import mowaNFTItemsAbi from 'config/abi/MowaNFTItemsAbi.json'
import mowaOpenBoxAbi from 'config/abi/MowaOpenBoxAbi.json'
import mowaOpenBoxAbiV2 from 'config/abi/MowaOpenBoxAbiV2.json'
import mowaNFTUpgradeGemAbi from 'config/abi/MowaNFTUpgradeGemAbi.json'
import mowaClaimTokenEventRewardAbi from 'config/abi/MowaClaimTokenEventRewardAbi.json'
import mowaClaimTokenPresaleAbi from 'config/abi/MowaClaimTokenPresaleAbi.json'
import mowaClaimTokenPresaleAbiV2 from 'config/abi/MowaClaimTokenPresaleAbiV2.json'
import mowaGiftNFTAbi from 'config/abi/mowaGiftNFTAbi.json'
import marketV2Abi from 'config/abi/marketV2Abi.json'
import marketV3Abi from 'config/abi/marketV3Abi.json'
import marketV4Abi from 'config/abi/marketV4Abi.json'
import marketV5Abi from 'config/abi/marketV5Abi.json'
import marketV6Abi from 'config/abi/marketV6Abi.json'
import petCombineAbi from 'config/abi/petCombineAbi.json'
import upgradeLevelAbi from 'config/abi/UpgradeLevelAbi.json'
import wheelLuckyAbi from 'config/abi/wheelLuckyAbi.json'
import lockedTokenAbi from 'config/abi/lockedTokenAbi.json'
import mowaAuctionAbi from 'config/abi/MowaAuctionAbi.json'
import mowaTokenAirdropAbi from 'config/abi/MowaTokenAirdrop.json'
import mowaSpinAbi from 'config/abi/MowaSpinAbi.json'
import petSkillUpgradeAbi from 'config/abi/petSkillUpgradeAbi.json'
import shopNFTItemsAbi from 'config/abi/shopNFTItemsAbi.json'
import renameUserAbi from 'config/abi/renameUserAbi.json'
import wealthyGameAbi from 'config/abi/wealthyGameAbi.json'
import OpenAirdropBoxABI from 'config/abi/openAirdropBox.json'
import BridgeMowaToCscABI from 'config/abi/BridgeMowaToCSC.json'
import BlindboxCscAbi from 'config/abi/BlindboxAbiCSC.json'
import MowaNFTItemsAbiCsc from 'config/abi/MoniwarNFTItemsAbiCSC.json'
import MowaMarketAbiCsc from 'config/abi/MowaMarketAbiCSC.json'
import MowaMarketV7 from 'config/abi/MowaMarketV7.json'


import { ChainLinkOracleContract, FarmAuctionContract, PredictionsContract } from './types'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getErc721Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(erc721Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoV1Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV1Abi, address, signer)
}
export const getIfoV2Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoV2Abi, address, signer)
}
export const getSouschefContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  const abi = config.poolCategory === PoolCategory.BINANCE ? sousChefBnb : sousChef
  return getContract(abi, getAddress(config.contractAddress), signer)
}
export const getPoolNftContract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsNFTConfig.find((pool) => pool.nftId === id)
  return getContract(poolNftAbi, getAddress(config.contractAddress), signer)
}
export const getSouschefV2Contract = (id: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const config = poolsConfig.find((pool) => pool.sousId === id)
  return getContract(sousChefV2, getAddress(config.contractAddress), signer)
}
export const getPointCenterIfoContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pointCenterIfo, getPointCenterIfoAddress(), signer)
}
export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getCakeAddress(), signer)
}
export const getKsharkContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeAbi, getKscAddress(), signer)
}
export const getBabysharkContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(babySharkAbi, getBksAddress(), signer)
}
export const getProfileContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(profileABI, getPancakeProfileAddress(), signer)
}
export const getPancakeRabbitContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pancakeRabbitsAbi, getPancakeRabbitsAddress(), signer)
}
export const getBunnyFactoryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnyFactoryAbi, getBunnyFactoryAddress(), signer)
}
export const getBunnySpecialContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialAbi, getBunnySpecialAddress(), signer)
}
export const getLotteryV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryV2Abi, getLotteryV2Address(), signer)
}
export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer)
}
export const getMasterchefNftContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChefNft, getMasterChefNftAddress(), signer)
}
export const getMasterchefNftV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChefNftV2, getMasterChefNftV2Address(), signer)
}
export const getClaimRefundContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(claimRefundAbi, getClaimRefundAddress(), signer)
}
export const getTradingCompetitionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(tradingCompetitionAbi, getTradingCompetitionAddress(), signer)
}
export const getEasterNftContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(easterNftAbi, getEasterNftAddress(), signer)
}
export const getCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(cakeVaultAbi, getCakeVaultAddress(), signer)
}

export const getPredictionsContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(predictionsAbi, getPredictionsAddress(), signer) as PredictionsContract
}

export const getChainlinkOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(chainlinkOracleAbi, getChainlinkOracleAddress(), signer) as ChainLinkOracleContract
}
export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer)
}
export const getBunnySpecialCakeVaultContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialCakeVaultAbi, getBunnySpecialCakeVaultAddress(), signer)
}
export const getBunnySpecialPredictionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialPredictionAbi, getBunnySpecialPredictionAddress(), signer)
}
export const getBunnySpecialLotteryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bunnySpecialLotteryAbi, getBunnySpecialLotteryAddress(), signer)
}
export const getFarmAuctionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(farmAuctionAbi, getFarmAuctionAddress(), signer) as FarmAuctionContract
}
export const getAirdropContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(airDropAbi, getAirdropAddress(), signer)
}
export const getMemberOfKSharkContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(airDropAbi, getMemberOfKSharkAddress(), signer)
}
export const getMowaContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MowaAbi, getMowaAddress(), signer)
}
export const getManagerContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ManagerAbi, getManagerAddress(), signer)
}
export const getBlindBoxContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaBlindBoxAbi, getBlindBoxAddress(), signer)
}
export const getMarketContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaMarketAbi, getMarketAddress(), signer)
}
export const getMowaNFTContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MowaNFTAbi, getMowaNFTAddress(), signer)
}
export const getMowaNFTV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MowaNFTV2Abi, getMowaNFTV2Address(), signer)
}
export const getClaimNFTContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ClaimNFTAbi, getClaimNFTAddress(), signer)
}
export const getMowaMiningNFTContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MowaMiningNFTAbi, getMowaMinningNFTAddress(), signer)
}
export const getClaimMultiNFTContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ClaimMultiNFTAbi, getClaimMultiNFTAddress(), signer)
}
export const getMowaTestContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MowaTestAbi, getMowaTestAddress(), signer)
}
export const getClaimTokenContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ClaimTokenAbi, getClaimTokenAddress(), signer)
}
export const getMowaMiningNFTNewContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MowaMiningNFTNewAbi, getMowaMinningNFTNewAddress(), signer)
}
export const getMarketNewContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaMarketNewAbi, getMarketNewAddress(), signer)
}
export const getMowaNFTItemsContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaNFTItemsAbi, getMowaNFTItemsAddress(), signer)
}
export const getMowaOpenBoxContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaOpenBoxAbi, getMowaOpenBoxAddress(), signer)
}
export const getMowaOpenBoxV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaOpenBoxAbiV2, getMowaOpenBoxV2Address(), signer)
}
export const getMowaNFTUpgradeGemContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaNFTUpgradeGemAbi, getMowaNFTUpgradeGemAddress(), signer)
}
export const getMowaClaimTokenEventRewardContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaClaimTokenEventRewardAbi, getMowaClaimTokenEventRewardAddress(), signer)
}
export const getMowaClaimTokenPresaleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaClaimTokenPresaleAbi, getMowaClaimTokenPresaleAddress(), signer)
}
export const getMowaClaimTokenPresaleV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaClaimTokenPresaleAbiV2, getMowaClaimTokenPresaleV2Address(), signer)
}
export const getMowaGiftNFTContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaGiftNFTAbi, getMowaGiftNFTAddress(), signer)
}
export const getMarketV2Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(marketV2Abi, getMarketV2ddress(), signer)
}
export const getMarketV3Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(marketV3Abi, getMarketV3ddress(), signer)
}
export const getMarketV4Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(marketV4Abi, getMarketV4ddress(), signer)
}
export const getMarketV5Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(marketV5Abi, getMarketV5ddress(), signer)
}
export const getMarketV6Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(marketV6Abi, getMarketV6ddress(), signer)
}
export const getPetCombineContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(petCombineAbi, getPetCombineAddress(), signer)
}
export const getPetUpgradeLevelContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(upgradeLevelAbi, getPetUpgradeLevelAddress(), signer)
}
export const getWheelLuckyContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(wheelLuckyAbi, getWheelLuckyAddress(), signer)
}
export const getLockedTokenContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lockedTokenAbi, getLockedTokenAddress(), signer)
}

export const getMowaAuctionContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaAuctionAbi, getMowaAuctionAddress(), signer)
}

export const getMowaTokenAirdropContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaTokenAirdropAbi, getmowaTokenAirdopAddress(), signer)
}

export const getMowaSpinContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(mowaSpinAbi, getmowaSpinAddress(), signer)
}

export const getPetSkillUpgradeContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(petSkillUpgradeAbi, getPetSkillUpgradeAddress(), signer)
}
export const getShopNFTItemsContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(shopNFTItemsAbi, getShopNFTItemsAddress(), signer)
}
export const getRenameUserContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(renameUserAbi, getRenameUserAddress(), signer)
}
export const getWealthyGameContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(wealthyGameAbi, getWealthyGameAddress(), signer)
}

export const getOpenAirdropBoxContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(OpenAirdropBoxABI, getOpenAirdropBoxAddress(), signer)
}

export const getMowaContractByChain = (signer: ethers.Signer, chainId: number) => {
  return getContract(MowaAbi, getMowaAddressByChain(chainId), signer)
}

export const getBridgeMowaToCscContract = (signer: ethers.Signer, chainId: number) => {
  return getContract(BridgeMowaToCscABI, getBridgeMowaToCsc(chainId), signer)
}
export const getBlindboxCscContract = (signer: ethers.Signer, chainId: number) => {
  return getContract(BlindboxCscAbi, getBlindboxCsc(chainId), signer)
}

export const getMowaNFTByChainContract = (signer: ethers.Signer, chainId: number) => {
  return getContract(MowaNFTAbi, getMowaNFTByChain(chainId), signer)
}

export const getMowaNFTItemsByChainContract = (signer: ethers.Signer, chainId: number) => {
  return getContract(MowaNFTItemsAbiCsc, getMowaNFTItemsByChain(chainId), signer)
}

export const getMowaMarketCscContract = (signer: ethers.Signer, chainId: number) => {
  return getContract(MowaMarketAbiCsc, getMarketCSCByChain(chainId), signer)
}
export const getGiftNFTCscContract = (signer: ethers.Signer, chainId: number) => {
  return getContract(mowaGiftNFTAbi, getGiftNFTCSC(chainId), signer)
}
export const getMowaMarketV7Contract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MowaMarketV7, getMarketV7ddress(), signer)
}
export const getMagicBoxCscContract = (signer: ethers.Signer, chainId : number) => {
  return getContract(mowaOpenBoxAbiV2, getMagicBoxCSC(chainId), signer)
}
export const getAirdropBoxCscContract = (signer: ethers.Signer, chainId : number) => {
  return getContract(OpenAirdropBoxABI, getAirdropBoxCSC(chainId), signer)
}

export const getWIDIContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0xA361C79783833524dc7838399a4862B5f47038b8", signer)
}
export const getZUKIContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0xE81257d932280AE440B17AFc5f07C8A110D21432", signer)
}
export const getREALContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0xE91cd52Bd65fe23A3EAE40E3eB87180E8306399F", signer)
}
export const getHTDContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x5E2689412Fae5c29BD575fbe1d5C1CD1e0622A8f", signer)
}
export const getMYRAContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x6ef238E9E8CD2A96740897761C18894Fc086B9d0", signer)
}
export const getMILKYContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x6fE3d0F096FC932A905accd1EB1783F6e4cEc717", signer)
}
export const getBCOINContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x00e1656e45f18ec6747F5a8496Fd39B50b38396D", signer)
}
export const getCATOContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x97667AeB10B6916001b5431f980C30fB8a9ce4B4", signer)
}
export const getNINOContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x6CAD12b3618a3C7ef1FEb6C91FdC3251f58c2a90", signer)
}
export const getFAMContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x4556A6f454f15C4cD57167a62bdA65A6be325D1F", signer)
}
export const getMRSContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x9e05e646a18bb4caB41aa7992959f019D0aac124", signer)
}
export const getCOMICContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x7aF7C313b28E29563F714453cfCD4dc641c0bC8e", signer)
}
export const getBELLYContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x3dfa90540ccdc77d543e6e61cacd5880f5c62391", signer)
}
export const getTBLContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0xc1334B0B72BB988bdf5Cd9E7520E2184B451AF00", signer)
}
export const getCALOContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0xb6b91269413b6B99242B1c0Bc611031529999999", signer)
}
export const getQZAContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x41A166EBc24ffa2CeAFDC5c61b679fFcE33c8145", signer)
}
export const getCOWSContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x33b48893b8f119fb45f431b36f830a9584804201", signer)
}
export const getROFIContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x3244B3B6030f374bAFA5F8f80Ec2F06aAf104B64", signer)
}
export const getGWTContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x552594612f935441c01c6854EDf111F343c1Ca07", signer)
}
export const getLUSContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0xde301D6a2569aEfcFe271B9d98f318BAee1D30a4", signer)
}
export const getMXYContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0x965D3704DE812F5e1E7eEf1ac22fE92174258bd9", signer)
}
export const getGENIContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, "0xc04a23149efdF9A63697f3Eb60705147e9f07FfD", signer)
}
