import React from "react";
import styled from 'styled-components';
import { SvgProps } from "../../../components/Svg/types";

const StyleDiv = styled.div`
  padding-right:5px;
`
const Icon: React.FC<SvgProps> = (_props) => {
  return (
    <StyleDiv>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
      >
        <path
          fill="#fff"
          d="M14.5 2a3.25 3.25 0 012.739 5h2.511c.69 0 1.25.56 1.25 1.25v3.5a1.25 1.25 0 01-1 1.225v5.775a3.25 3.25 0 01-3.066 3.245L16.75 22h-9.5a3.25 3.25 0 01-3.245-3.066L4 18.75v-5.775c-.57-.116-1-.62-1-1.225v-3.5C3 7.56 3.56 7 4.25 7h2.511a3.25 3.25 0 015.24-3.827A3.24 3.24 0 0114.5 2zm-3.25 10.999H5.5v5.75a1.75 1.75 0 001.606 1.745l.144.006h4v-7.501zm7.25 0h-5.75v7.5h4a1.75 1.75 0 001.744-1.606l.006-.143v-5.751zm-7.25-4.5H4.5v3h6.75v-3zm8.25 3v-3h-6.75v3h6.75zm-5-8a1.75 1.75 0 00-1.75 1.75V7h1.774l.12-.005A1.75 1.75 0 0014.5 3.5zm-5 0a1.75 1.75 0 00-.144 3.495l.12.005h1.774v-1.75l-.006-.143A1.75 1.75 0 009.5 3.5z"
        />
      </svg>
    </StyleDiv>
  );
};

export default Icon;
