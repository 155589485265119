import { Text, Button } from '@pancakeswap/uikit';
import { useWeb3React } from "@web3-react/core";
import Buttons from 'components/Layout/Buttons';
import { useTranslation } from 'contexts/Localization';
import useToast from 'hooks/useToast';
import md5 from 'md5';
import React, { useState } from 'react';
import CardPopup from 'react-modal';
import styled from 'styled-components';
import { emailIsValid, sleep } from 'utils/utilsFun';
import { postApi } from "../../utils/apiHelper";

interface BountyModalProps {
  data?: any,
  isOpen?: boolean,
  newEmail?: (value) => void,
  onRequestClose?: (value) => void,
  onAfterOpen?: () => void,
  type?: string,
}

const PopupConfirmEmail: React.FC<BountyModalProps> = ({ data, isOpen, onRequestClose, onAfterOpen, newEmail }) => {
  const { t } = useTranslation()
  const [textCode, setTextCode] = useState('')
  const [textError, setTextError] = useState(null)
  const [isShowError, setIsShowError] = useState(false)
  const [isProcess, setIsProcess] = useState(false)
  const { toastSuccess, toastError } = useToast()
  const [email, setEmail] = useState('')
  const { account } = useWeb3React()

  const onChagneEmail = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  const onShowError = () => {
    if (!isShowError) return <></>
    return (
      <ErrorDiv className='show'>
        {
          textError?.length > 0 ? textError?.map(text => {
            return (
              <Text mt="10px" as="p" fontSize="12px" color="red">{text.code}</Text>
            )
          }) :
            <Text mt="10px" as="p" fontSize="12px" color="red">{textError?.code}</Text>
        }
      </ErrorDiv>
    )
  }

  const onSubmit = () => {
    if (email === '') {
      setTextError([{ code: 'The email field is required.' }])
      setIsShowError(true)
      return
    } if (!emailIsValid(email)) {
      setTextError([{ code: 'The email is not a valid address.' }])
      setIsShowError(true)
      return
    }
    setIsProcess(true)
    const params = {
      old_email: data,
      new_email: email,
    }

    postApi('/users/change_email', params).then(resp => {
      setIsShowError(false)
      newEmail(email)
      onRequestClose('ok')
    }).catch(err => {
      console.log('erro', err.response);
      setTextError(err.response?.data?.errors ? [{ code: err.response?.data.errors[0].code }] : [{ code: err.response.data.message }])
      setIsShowError(true)
    }).finally(() => {
      setIsProcess(false)
    })
  }


  return (
    <CardPopup
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onAfterOpen={onAfterOpen}
      closeTimeoutMS={300}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
    >
      <ContentModal>
        <div className="modal-header">
          <div className="title">
            <Text ml="30px" as="span" bold fontSize="18px" color="#fff">Change Email</Text>
          </div>
          <button type="button" className="close" onClick={() => onRequestClose(null)}>
            <img src="/images/close.png" alt="close" />
          </button>
        </div>
        <div className="modal-content">
          <RowForm>
            <Text as="p" bold fontSize="12px" color="#fff">Please enter new email</Text>
            <div className="form-control-position">
              <input type="text" className="form-control" style={{ paddingRight: 100 }}
                placeholder="Enter new email" value={email} onChange={(e) => onChagneEmail(e)} />
            </div>
          </RowForm>
          {onShowError()}
          <Button onClick={!isProcess && onSubmit}
            disabled={isProcess}
            color="#fff" height="56px" width="100%"
            style={{ marginTop: 20, position: 'relative', borderRadius: 10, }}
          >
            {
              isProcess ? <>
                <LoadingIcon src="/images/loading.gif" alt="loading" />
                OK
              </> : 'OK'
            }

          </Button>

        </div>
      </ContentModal>
    </CardPopup>
  )
}

export default PopupConfirmEmail
const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 1)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  content: {
    backgroundColor: 'transparent',
    border: 'none',
    overflow: 'initial',
    position: 'initial'
  },
};


CardPopup.setAppElement('#root');

const ContentModal = styled.div`
  background: #1B212F;    
  width:380px;
  border-radius: 20px;
  div.img-card {
    margin-top: 20px;
    width: 278px;
    height: 362px;
    margin: auto;
    background: linear-gradient(360deg,#BF893D 0%,#FFD493 137.5%);
    border-radius: 5px;
    padding-left: 1px;
    padding-right: 1px;
    padding-top: 1px;
    img{
      width: 100%;
      height: auto;
    }
  } 
  div.modal-header {
    background: #2C3145;
    height: 80px;
    display: flex;
    align-items: center;
    position:relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    button.close {
      position: absolute;
      right: 15px;
      width: 50px;
      height: 50px;
      display: block;
      justify-items: center;
      text-align: center;
      padding-top: 7px;
      border:none;
      background: transparent;
      &:hover{
        cursor: pointer;
      }
    }
  }
  div.modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:30px
  }
`

const LoadingIcon = styled('img')`
  width: 30px;
  height: 30px;
  position: absolute;
  left:15px;
`

const RowForm = styled.div`
  margin-top: 10px;
  width: 100%;
  p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: 700;
  }

  .form-control-position{
    margin-top: 10px;
    position: relative;
    .form-control{
      height: 40px;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 0 20px;
      color: ${({ theme }) => theme.colors.textSubtle};
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      background:rgba(0, 0, 0, 0.5);;
      &:focus{
        outline: none;
        border: 1px solid #c4c4c4 !important;
      }

      &.pr{
        padding-right: 50px
      }
    }   
    .otp{
      position: absolute;
      right: 10px;
      top: 0;
      z-index: 2;
      height: 40px;
      padding-top: 10px;
      z-index: 2;           
      &:hover p{
        cursor: pointer;
        color:#E5A95A;
      }
      div.disabled-otp{
        color:rgba(255, 255, 255, 0.5);
        font-weight: 700;
      }
    }
  }  
`
const ErrorDiv = styled.div`
  display: none;
  color: red;
  font-size: 12px;
  position: relative;
  &.show{
    display: block;
  }
`