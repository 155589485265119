import useActiveWeb3React from 'hooks/useActiveWeb3React';
import React from 'react';
import NftMiningBNB from './BNB/NftMining';
import NftMiningCSC from './CSC/NftMining';


const InitLayout: React.FC = () => {
    const { chainId } = useActiveWeb3React()
    return [97, 56].includes(chainId) ? <NftMiningBNB /> : <NftMiningCSC />
}
export default InitLayout