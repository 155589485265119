import { Button, Flex, HelpIcon, InjectedModalProps, Modal, Text } from '@pancakeswap/uikit';
import { useWeb3React } from "@web3-react/core";
import ConfirmEmail from 'components/PopupConfirmEmail2';
import BoxGift from 'components/PopupGiftBox';
import { appleProvider, googleProvider } from 'config/authMethods';
import firebase from "config/firebaseConfig";
import useToast from 'hooks/useToast';
import React, { useEffect, useRef, useState } from 'react';
import history from 'routerHistory';
import styled from 'styled-components';
import { postApi } from "utils/apiHelper";
import { socialMediaAuth } from 'utils/authHelpers';
import { emailIsValid, onHowToUse, onRegInput } from 'utils/utilsFun';


const RegisterModal: React.FC<InjectedModalProps> = ({ onDismiss }) => {
  const { account } = useWeb3React()
  const [isShowPass, setIsShowPass] = useState(false)
  const [isShowRePass, setIsShowRePass] = useState(false)
  const [isShowError, setIsShowError] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [textError, setTextError] = useState(null)
  const [email, setEmail] = useState('')
  const [textPass, setTextPass] = useState('')
  const [txtpassW, setPassowrord] = useState('')

  const [loading, setLoading] = useState(false)
  const { toastError } = useToast()
  const [nickName, setNickName] = useState('')
  const [isGiftBox, setIsGiftbox] = useState(false)

  const paramsRefCode = new URLSearchParams(window.location.search).get("referral")
  const [textCode, setTextCode] = useState(paramsRefCode || '')

  const typeRef = useRef('')
  const isUpdateRef = useRef(false)
  const handleShowPass = () => {
    setIsShowPass(!isShowPass)
  }

  useEffect(() => {
    setLoading(true)
    firebase.auth().getRedirectResult().then(async result => {
      if (result.user !== null && result.credential) {
        const userInfo = result.user;
        const provider = result.credential.signInMethod;
        
        const params =  userInfo.email ? {
          provider,
          uuid: userInfo.uid,
          email: userInfo.email,
          wallet: account || ''
        } : {
          provider,
          uuid: userInfo.uid,
          wallet: account || ''
        }

        // const idToken = await userInfo.getIdToken()
        // const params = {
        //   firebase_token: idToken,
        //   wallet: account || ''
        // }
        const dataParams = textCode !== '' ? { ...params, ref_code: textCode } : params
        postApi('/users/login_social', dataParams).then(resp => {
          window.location.reload();
        }).catch(async (error) => {
          if (error.response?.data?.status_code === 400) { // Send otp 
            setEmail(userInfo.email || '')
            isUpdateRef.current = true
            setIsOpen(true) // show popup otp
            setLoading(false)
            return
          }
          if (error.response?.data?.status_code !== 403) {
            typeRef.current = ''
            toastError('Error:', error.response?.data?.message)
            await firebase.auth().signOut().then().catch(e => console.log('error logout', e));
          }
          // setLoading(false)
        })
      }
      setLoading(false)
    })
  }, [account, toastError, textCode])


  const handleShowRePass = () => {
    setIsShowRePass(!isShowRePass)
  }

  const onChagneEmail = (e) => {
    setEmail(e.target.value.toLowerCase())
  }

  const onChagneNickName = (val) => {
    const result = onRegInput(val)
    if (result) {
      setNickName(val.replace(/\s/g, '')) // xóa khoảng trắng nhập vào
    }
    if (!result && val.length === 0) {
      setNickName('')
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const params = new FormData(e.target);
    const password = params.get('password')
    const rePassword = params.get('rePassword')
    const _email = params.get('email')
    const _userName = params.get('nickname')

    const msgErr = []
    if (_email === '') {
      msgErr.push({ code: "The email field is required." })
    } if (!emailIsValid(_email)) {
      msgErr.push({ code: 'The email is not a valid address.' })
    } if (_userName === '') {
      msgErr.push({ code: "The username field is required." })
    } if (password === '') {
      msgErr.push({ code: "The password field is required." })
    } if (rePassword === '') {
      msgErr.push({ code: "The password confirmation field is required." })
    } if (password !== rePassword) {
      msgErr.push({ code: "Passwords do not match repeat password." })
    }

    if (msgErr.length === 0) {
      setLoading(true)

      let data
      if (textCode !== '') {
        data = {
          "email": _email,
          "username": _userName,
          "password": password,
          "wallet": account,
          "ref_code": textCode
        }
      } else {
        data = {
          "email": _email,
          "username": _userName,
          "password": password,
          "wallet": account
        }
      }
      postApi('/users/register', data).then(resp => {
        setIsOpen(true)
      }).catch(err => {
        if (err?.response?.status === 422) {
          setIsShowError(true)
          setTextError(err.response.data.errors)
        }
      }).finally(() => {
        setLoading(false)
      })
    } else {
      setIsShowError(true)
      setTextError(msgErr)
    }

  };

  const onShowError = () => {
    if (!isShowError) return <></>
    return (
      <ErrorDiv className='show'>
        {
          textError?.length > 0 ? textError?.map(text => {
            return (
              <Text mb="5px" as="p" fontSize="12px" color="red">{text.code}</Text>
            )
          }) :
            <Text mb="5px" as="p" fontSize="12px" color="red">{textError?.code}</Text>
        }
      </ErrorDiv>
    )
  }

  const _onClosePopup = (value) => {
    if (value) {
      setIsGiftbox(true)
      setIsOpen(false)
    } else {
      setIsOpen(false)
      onDismiss()
      window.location.reload()
    }
  }

  const onHandleLogin = async (provider) => {
    setLoading(true)
    await socialMediaAuth(provider)
  }

  const onPaste = () => {
    navigator.clipboard.readText().then(text => {
      setTextCode(text)
    });
  }

  const onCloseGiftBox = val => {
    if (val === 'Gift-ok') {
      history.push('/bag')
    }
    setIsGiftbox(false)
    onDismiss()
    window.location.reload()
  }

  return (<>
    <ModalPopup
      title="Register"
      headerBackground="#2C3145"
      onDismiss={loading ? null : onDismiss}
    >
      <ModalContainer>
        <form onSubmit={onSubmit}>
          <RowForm>
            <p>Email</p>
            <div className="form-control-position">
              <input type="text" className="form-control" id="email" name="email" autoComplete="off"
                placeholder="Enter your email" value={email} onChange={(e) => onChagneEmail(e)} />
            </div>
          </RowForm>
          <RowForm>
            <p>Nickname</p>
            <div className="form-control-position">
              <input type="text" className="form-control" id="nickname" name="nickname" autoComplete="off"
                placeholder="Enter nickname" maxLength={20} value={nickName} onChange={(e) => onChagneNickName(e.target.value)} />
            </div>
          </RowForm>
          <RowForm>
            <p>Password</p>
            <div className="form-control-position">
              <input type={(isShowPass || textPass === '') ? 'text' : 'password'} className="form-control pr" id="password"
                value={textPass}
                onChange={e => setTextPass(e.target.value)}
                name="password" placeholder="Enter your password" />
              {isShowPass ? (
                <Fal onClick={handleShowPass}><i className="fal fa-eye-slash" /></Fal>
              ) : (
                <Fal onClick={handleShowPass}><i className="fal fa-eye" /></Fal>
              )}
            </div>
          </RowForm>
          <RowForm>
            <p>Reapeat Password</p>
            <div className="form-control-position">
              <input type={(txtpassW === '' || isShowRePass) ? 'text' : 'password'} className="form-control pr" id="rePassword"
                name="rePassword" placeholder="Enter your password" value={txtpassW} onChange={(e) => setPassowrord(e.target.value)} />
              {isShowRePass ? (
                <Fal onClick={handleShowRePass}><i className="fal fa-eye-slash" /></Fal>
              ) : (
                <Fal onClick={handleShowRePass}><i className="fal fa-eye" /></Fal>
              )}
            </div>
          </RowForm>
          <RowForm>
            <p>Referral code (optional)</p>
            <div className="form-control-position custom-rows">
              <input type="text" className="form-control" id="nickname" name="nickname"
                placeholder="Enter Referral code" value={textCode}
                onChange={e => setTextCode(e.target.value)}
              />
              <Text onClick={onPaste} className="btn-paste"><img src="/images/icon-paste.png" alt="icon-paste" /></Text>
            </div>
          </RowForm>
          {/* <RowForm> */}
          {/*    <ReCaptchaDiv> */}
          {/*        <ReCAPTCHA */}
          {/*            sitekey="6Le84lAcAAAAAGB65Fjube9oPpxu4hegCxMmQURu" */}
          {/*            onChange={onChange} */}
          {/*        /> */}
          {/*    </ReCaptchaDiv> */}
          {/* </RowForm> */}
          {onShowError()}
          <RowForm>
            <Button disabled={loading}
              color="primary" width="100%"
              style={{ borderRadius: '10px', boxShadow: 'inherit', position: 'relative' }}
              margin="10px 0 0">
              {
                loading ? <>
                  <LoadingIcon src="/images/loading.gif" alt="loading" />
                  Processing...
                </> : 'Register'
              }
            </Button>
          </RowForm>
          <Text textAlign='center' color='rgba(255,255,255,0.6)' className='text-login' >Or login by</Text>
          <Flex alignItems='center' justifyContent="center" style={{ gap: 15 }} pt="15px" pb="20px">
            {/* <Button
              onClick={() => onHandleLogin(facebookProvider)}
              variant="text"
              disabled={loading}
              style={{
                fontSize: 20,
                color: 'white',
                width: 50,
                height: 50,
                borderRadius: 25,
                background: '#3578E5',
                opacity: (typeRef.current === '') || (loading && typeRef.current && typeRef.current === 'facebook.com') ? 1 : 0.3
              }}><i className='fab fa-facebook-f' /></Button> */}
            <Button
              onClick={() => onHandleLogin(googleProvider)}
              variant="text"
              disabled={loading}
              style={{
                fontSize: 20,
                color: 'white',
                width: 50,
                height: 50,
                borderRadius: 25,
                background: '#ea5f54',
                opacity: (typeRef.current === '') || (loading && typeRef.current && typeRef.current === 'google.com') ? 1 : 0.3
              }}><i className='fab fa-google' /></Button>
            <Button
              disabled={loading}
              onClick={() => onHandleLogin(appleProvider)}
              variant="text"
              style={{
                fontSize: 20,
                color: 'white',
                width: 50,
                height: 50,
                borderRadius: 25,
                background: 'black',
                opacity: (typeRef.current === '') || (loading && typeRef.current && typeRef.current === 'apple.com') ? 1 : .3
              }}>
              <i className='fab fa-apple' /></Button>
          </Flex>
          {/* <div className="goto-login"><Text as="span" onClick={() => setIsLogin(true)}>Already have an account? Log in</Text></div> */}
        </form>
        <Text className="text-tutorial" onClick={() => onHowToUse('https://docs.moniwar.io/get-started/register-an-account')}>
          <Text as="span">Register an account</Text> <HelpIcon pl="5px" /></Text>
      </ModalContainer>
    </ModalPopup>
    {
      isOpen && <ConfirmEmail
        isOpen={isOpen}
        onRequestClose={_onClosePopup}
        data={email}
        isUpdateEmail={isUpdateRef.current}
      />
    }
    {isGiftBox && <BoxGift isOpen={isGiftBox} onRequestClose={onCloseGiftBox} />}
  </>)
}

export default RegisterModal

const ModalPopup = styled(Modal)`
    max-width: calc(100% - 30px);
    overflow-y: 'auto';
    border-radius:20px;
    width: 380px;
    background:#1B212F;
    div:first-child{
       button svg {
            fill:#fff;
        }

    }
`

const LoadingIcon = styled('img')`
  width: 30px;
  height: 30px;
  position: absolute;
  left:15px;
`

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0 0;
  .goto-login{
    text-align: center;
    color: #fff;
    &:hover{
        cursor: pointer;
        span{
            color: rgba(255,255,255,.6);
        }
    }
  }
  .text-tutorial{
    display: flex;
    justify-content: center;
    align-items: center; 
    &:hover{    
      cursor: pointer;
    }
    &:hover span{
      color: rgba(255,255,255,.6);
    }
  }
 
`

const RowForm = styled.div`
  margin-bottom: 20px;

  p{
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    font-weight: 700;
  }

  .form-control-position{
    position: relative;
    margin-top: 10px;

    .form-control{
      height: 40px;
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 0 20px;
      color: ${({ theme }) => theme.colors.textSubtle};
      font-size: 14px;
      font-weight: 400;
      width: 100%;
      background:rgba(0, 0, 0, 0.5);;
      &:focus{
        outline: none;
        border: 1px solid #c4c4c4 !important;
        border-radius: 10px !important;
      }

      &.pr{
        padding-right: 50px
      }
    }
    &.custom-rows{
      input {
        padding-right: 30px;
      }
      input.readOnly {
        background:transparent!important ;
      }
    }
  }
  .btn-paste {
    position: absolute ;
    bottom: 2px;
    right: 10px;
  }
 
`
const Fal = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  cursor: pointer;
  transform: translate(0,-50%);
  color:#333;
`

const ErrorDiv = styled.div`
  visibility: hidden;
  color: red;
  font-size: 12px;
  position: relative;
  top: -5px;  
  &.show{
    visibility: inherit;
  }
`
const ReCaptchaDiv = styled.div`
  transform:scale(0.97);
  transform-origin:0 0;

  ${({ theme }) => theme.mediaQueries.xs} {
    transform:scale(1);
  }
`