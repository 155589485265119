export default {
  MowaTest: {
    97: '0x1be43c1B4020a01eaE3C7A6091F19D4886a2056B',
    56: '',
  },
  masterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x562d9EcEC2802966082BdFBc2d9CCC908c9f7E0e',
  },
  masterChefNft: {
    97: '0x08dbe9e121Be13558BdD1872eC9c3E36Cd12e539',
    56: '0x738Bbd4ed4a1c823DD31f453e81dECdd913cAEE7',
  },
  masterChefNftV2: {
    97: '0x52A5a1b67D41175Af7a46324cf859FE2643Ab669',
    56: '0xa20D757943b2c78B636f0fF7BB1FbcAdD741e87C',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x390e58C934228D1839b5C1E7D86Df0eAA31c535b',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  airdrop: {
    97: '',
    56: '0x01017182a0ca83380e825e2763c50f44cc00873d',
  },
  memberOfShark: {
    97: '',
    56: '0x555aeb7f47635ec50a2a73586b002dd3880a4c4e',
  },
  manager: {
    97: '0x292DA1124bAa0193BaE489C651f6D55907346054',
    56: '0x7804A79cFd473a0C2FffFBBb56D6f972061C816d',
  },
  blindbox: {
    97: '0x2FA5800018906af2BBC38C2e150843746D4A04B8',
    56: '0x3AAb5C5c1716E386B81dfE69646655Bb35cFB34f',
    52: '',
    53: '0x7c971e8DcB6d3A923d1130733aB4A6181b7fE7F3',
    1945: "0x4c33902873E8Ccc356F2A0c26a00882F1161a180",
    1975: ""
  },
  market: {
    97: '0x940572dD0388f54ceB9E0e4342D0437293F1b74B',
    56: '0x6F8621B23b09f04F2eA96b11106469DB7c28D9d8',
  },
  mowaNFT: {
    97: '0xA1B9fE351AE6ea9467FB2d0E1aeD766716733a69',
    56: '0x2bE9dD68a11b6fE461e4C376FFf7e13A670fa6b3',
  },
  mowaNFTV2: {
    97: '0x894B151ADC540a44A47D17D7A42842109c3e5484',
    56: '0x84F36a9741220eb898D541d1A8DcCDB37e87ebeA',
    52: '',
    53: '0x287209C3aA6EaFa4A56BBa763a63c1eeF30585E9',
    1945: "0x8fa3A3A5806203706225424F5F75A79BbdBE7E02",
    1975: ""
  },
  claimNFT: {
    97: '0xA573a74B478eD9118EBe383B97eCd51948cE921C',
    56: '0xCA1b689F8EA9e2FFddCa7Fd4f3eDEE4E4f14f451',
  },
  claimMultiNFT: {
    97: '0x7B1fF19aebEfb2D1e9E8adF99cdd341Fed9a4688',
    56: '0x5a74E09f098c5CdC0ade2F41297C107d74695D30',
  },
  miningNFT: {
    97: '0x7Cb14c7E0A22A85Af19B0B9FAfE055612dde101C',
    56: '0xd23D3A45f64d58e4637933591eCaE53EC1323DA5',
  },
  miningNFTNew: {
    97: '0x7Cb14c7E0A22A85Af19B0B9FAfE055612dde101C',
    56: '0xeF46eEd79052F9f72102d69a7eFB5a75E0F6520b',
  },
  claimToken: {
    97: '0xA0C84C2c3F738e1bFfbA70B747586F5058d8B3b5',
    56: '',
  },
  marketNew: {
    97: '0xe64D1ebBb0325EF393F99DE6521F841D0E55f30B',
    56: '0x4eD37F3488a2d9802583C3f49088669B933E83aF',
  },
  nftItems: {
    97: '0xF474bD43Ba2E1D9620715b4eA5BC4bF193cb4a93',
    56: '0xde1411a0920511C744F95f1c592128F09A240c66',
    52: '',
    53: '0x481BD08c2Af617E78727fAA963Ef41cF0F34d944',
    1945: "0x1bA12962B3fa3Bd7Dd99C98F6DD07825c130341B",
    1975: ""
  },
  mowaOpenBox: {
    97: '0xBa49CF33f3a630ADe9617617F312750AE111AC6b',
    56: '0xd6ade4b18e3bde9026ea91515628c8bc66fe01b7',
  },
  mowaOpenBoxV2: {
    97: '0xfe62B3E59101d861e8fa0e8B756DDb0E53C3A367',
    56: '0xB337aEb1d7003F0a6D32Da8c611c1a99aB3922a3',
    52: "",
    53: "0x72E6b5b2287177068b50F692F57BF82263C144eB",
    1945: "0xDB85fa9e4ddbFF1C76ca654d32F634f10072e814",
    1975: ""
  },
  mowaUpgradeGem: {
    97: '0x0aBb2F64f02315A62Da995d9369c901175ACF465',
    56: '0x4f27c9f33bd4D9fB755F64c2552470885973cA5F',
    52: "",
    53: "0x4fDA47f40fe89b7c1Bf0f0Bc290F05f45114680c",
    1945: "0x0601ffD0BCaCe3076425Dac74957846dAc51E194",
    1975: ""
  },
  mowaClaimTokenEventReward: {
    97: '0x1ab9b2d1A8380DCb9e4B3c6a85c3183F28952D8D',
    56: '0x0c5e3BF06A965DEd3F25fD4b3e0bC143AB08a5fc', // '0xb1938401E80d88B00f5F086e43860478457424c9',
  },
  mowaClaimTokenPresale: {
    97: '0xc470104d1DaF75D8194d1651c714318e9845CB45',
    56: '0xE22D360816d6DDDF7aCa6F0C689Caf802D40823E',
  },
  mowaClaimTokenPresaleV2: {
    97: '0x700561228c3A88197b3ddD05127341bE463aFBF9',
    56: '0x24A9c1dA1598Cf08aFE58277582FD5a905187DC4',
  },
  mowaGiftNFT: {
    97: '0x475f516ee6e7A8027acC3eB1aa245C97f6930fC7',
    56: '0x6703220655f4a83b14df81c8e7011f31eb839481',
    52: "",
    53: "0x684D4B566F8E19ff16E1624Ec5A73416945133AB",
    1945: "0x1575c3a7607A74AE6aBE89FEf8Aff34DD6Aeca89",
    1975: ""
  },
  marketV2: {
    97: '0xEAaAaF32b13bD8D33ab71F9403D553BbEbe7a309',
    56: '0x51D4B94501406DA096cE4728B0FD2Ff54286293C',
  },
  marketV3: {
    97: '0x5696e42c389E2CF91FB32D6d59473B1f4367b13a',
    56: '0xDA3b8843880826765F89f7Fa691f6062386bF01E',
  },
  marketV4: {
    97: '0x5696e42c389E2CF91FB32D6d59473B1f4367b13a',
    56: '0xF05330f704BA7089b468c8c9c2dDdcb7D33639b5',
  },
  marketV5: {
    97: '0xaf6eB1c567620ce81C763e2e4457114f1C37EB93',
    56: '0xba2598DB432a83C8496E741DEFC14365271c28E3',
  },
  marketV6: {
    97: '0xC0d2CABdf5Bc5A2Be1f39c114960245E95bC1F54',
    56: '0xEEb247463964dB0db83abD9C796fc0D3D8d5f1B3',
    52: '',
    53: '0x09455E2b8f2238C92eA38C3726fe441bF6f4d419',
    1945: '0x0fFA06416E6bB5aBdb8797579442c7157E2e0419',
    1975: ''
  },
  marketV7: {
    97: '0xF0FF13484c58dA0B626B8468beAb235358fD143e',
    56: '',
  },
  petCombine: {
    97: '0xf34651Bb1385fACcBA1a7C793774181d560fc0D9',
    56: '0x07ef2Bdce77332DF2f61e2e4C205F9ad208A9437',
    52: "",
    53: "0x26dAFa807779E9cd817071f43e387292941DF469",
    1945: "0x394DB4d5a9ACA6cd48C78f71d123c20F0010906C",
    1975: ""
  },
  petUpgradeLevel: {
    97: '0x244A53D5Ce55B1A17a3e9773c193607FCcb60b08',
    56: '0xac5b1DF0312C68e407A414473D1a7cA7fd1A3718',
    52: "",
    53: "0x1c85660328dca4b8734542b9118053E05496e195",
    1945: "0x762683f0B01769F7d6f9D76701604A43f3504F5D",
    1975:""
  },
  mowaWheelLucky: {
    97: '0x4F88C0D0cD19D9B4278eb19f47aBf3035Fd456CD',
    56: '0xCfA730D301F8Bf30fD0aA249Fa40057c3b319A06'
  },
  lockedToken: {
    97: '0x2c8D58429B23127ce5E81727A224eCa9343EC1a7',
    56: '0x386723e0C9F5B50eE30BA1e0ec2a49b28308D2f8'
  },
  mowaAuction: {
    97: '0x66E1f71eA965048F603fB6BA2CC34cB6D61895A9',
    56: '0x8B18AfFe12A650B4370738e48179909b7cc9b048',
    52: "",
    53: "0xf1cA32559859895A38A99cf31065Db0889E12e9e",
    1945: "0x9f81E07F724fdfC042CC5384eDDC3ABD585b8470",
    1975: ""
  },
  mowaTokenAirdrop: {
    97: '0x141aF0435313ABf7b5d59302eaCA5F5F9BcDb9BE',
    56: '0xDD91e6105D27E37b9f04989Bc97fc5C4c3F3e195'
  },
  mowaSpin: {
    97: '0x8d33Bb38516139E416721f048905570b629CCc03',
    56: '0xd5F7B2db05aA3Ef9E6389E41BEE02cb526b490A2',
    52: "",
    53: "0xe3238e09D0879cf55467994963FDB99e1E6A95C6",
    1945: "0x876Fb5fEbB0e59f6Fd39719C5918fFAF8cFE7D2F",
    1975: "",    
  },
  petSkillUpgrade: {
    97: '0x46854a8b95315e8A96895d1Ec907481b88b34025',
    56: ''
  },
  shopNFTItems: {
    97: '0x83aBB0fB1e08fD016Efc0476e14413f393E3E918',
    56: '0x4fEA811C0fAC564b7899e5D44426A1c7707c1299',
    52: "",
    53: "0x25e0909CEB1751703291672D4717A9AFb0bA35Dc",
    1945: "0x9f02690371aD2211E58DcaDB66510674c22B9371",
    1975: ""
  },
  renameUser: {
    97: '0x8aF54f2AC727d6F6a86b8E46473E92bE2B4a4e2f',
    56: '0xB679B4d97FBCEE114Ce3B2f1FC3A86361466F66A',
    52: "",
    53: "0xC8E518f23827577D9b049814a2949E5c57fC7BFb",
    1945:"0xB951707C4750A55B212C34cF06f74031920A212E",
    1975:""
  },
  wealthyGame: {
    97: '0x56b0b3AC4FFd17b73f56D26482a85aF3C4D335c7',
    56: '',
    52: "",
    53: "0x989C173B8C9380d7B40cB3C6f44164574FdfBC85"
  },
  OpenAirdropBox: {
    97: '0x7D05aD51599d5f2EB81ac1bCc2adf7671eb4e99d',
    56: '0x9569D660808A68bf848D2e317B74a0142A30E0F0',
    52: "",
    53: "0x7bBa88197CaDccE98a2eC8002A4cDc32F49EdFa5",
    1945: "0x90f45c0a3b5D10494a719b2a1dD2a796295A611C",
    1975: ""
  },
  BridgeMowaToCSC: {
    97: '0x9D914AE598c34C87D9883b7570e1D470dDc0D2Dc',
    56: '',
    52: '',
    53: '0x8ffdbf7B21b790642B88a9296483Dff84D30e381',
    1945: "0xb133a75EAD4D7a3bFf4e78c6f375C165E8033E69",
    1975: ""
  }
}
