export const ITEM_CONST = [
    { item_key: 1, item_status: 0},
    { item_key: 2, item_status: 0},
    { item_key: 3, item_status: 0},
    { item_key: 4, item_status: 0},
    { item_key: 5, item_status: 0},
    { item_key: 6, item_status: 0},
    { item_key: 7, item_status: 0},
    { item_key: 8, item_status: 0},
    { item_key: 9, item_status: 0},
    { item_key: 10, item_status: 0},
]
