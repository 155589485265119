import React, { useRef } from 'react'
import styled from 'styled-components'
import { Button, ChevronUpIcon } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { Pool } from 'state/types'
import PoolRow from './PoolRow'

interface PoolsTableProps {
  pools: Pool[]
  userDataLoaded: boolean
  account: string
}

const StyledTable = styled.div`
  background: #1B212F;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  > div:not(:last-child) {
    border-bottom: 1px solid rgba(255,255,255,0.3);
  }
`

const StyledTableBorder = styled.div`
  /* border-radius: ${({ theme }) => theme.radii.card};
  background-color: ${({ theme }) => theme.colors.cardBorder};
  padding: 1px 1px 3px 1px;
  background-size: 400% 400%; */
  padding: 1px 1px 3px 1px;
`

const ScrollButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
`

const PoolsTable: React.FC<PoolsTableProps> = ({ pools, userDataLoaded, account }) => {
  const { t } = useTranslation()
  const tableWrapperEl = useRef<HTMLDivElement>(null)
  const scrollToTop = (): void => {
    tableWrapperEl.current.scrollIntoView({
      behavior: 'smooth',
    })
  }
  return (
    <StyledTableBorder>
      <StyledTable role="table" ref={tableWrapperEl}>
        {pools.map((pool) => (
          <PoolRow
            key={pool.sousId}
            pool={pool}
            account={account}
            userDataLoaded={userDataLoaded}
          />
        ))}
        <ScrollButtonContainer>
          <ButtonToTop onClick={scrollToTop}>
            {t('To Top')}
            <ChevronUpIcon color="#fff" />
          </ButtonToTop>
        </ScrollButtonContainer>
      </StyledTable>
    </StyledTableBorder>
  )
}

export default PoolsTable
const ButtonToTop = styled.button`
  font-size: 14px;
  color:#fff;
  background-color:transparent;
  font-weight: 500;
  border:none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  &:hover{
    cursor: pointer;
  }
`