import React from "react";
import styled from 'styled-components';
import { SvgProps } from "../../../components/Svg/types";

const StyleDiv = styled.div`
  padding-right:5px
`
const Icon: React.FC<SvgProps> = (props) => {
  return (
    <StyleDiv>
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M11.386 2.21021C11.5615 2.07365 11.7776 1.99951 12 1.99951C12.2224 1.99951 12.4385 2.07365 12.614 2.21021L20.842 8.61021C21.2028 8.8909 21.4948 9.25035 21.6955 9.66111C21.8962 10.0719 22.0003 10.523 22 10.9802V20.0002C22 20.5306 21.7893 21.0393 21.4142 21.4144C21.0391 21.7895 20.5304 22.0002 20 22.0002H16C15.4696 22.0002 14.9609 21.7895 14.5858 21.4144C14.2107 21.0393 14 20.5306 14 20.0002V14.0002H10V20.0002C10 20.5306 9.78929 21.0393 9.41421 21.4144C9.03914 21.7895 8.53043 22.0002 8 22.0002H4C3.46957 22.0002 2.96086 21.7895 2.58579 21.4144C2.21071 21.0393 2 20.5306 2 20.0002V10.9802C2 10.0522 2.428 9.18021 3.16 8.61021L11.386 2.21021ZM12 4.26621L4.386 10.1882C4.26545 10.282 4.16799 10.4021 4.10108 10.5394C4.03416 10.6767 3.99959 10.8275 4 10.9802V20.0002H8V14.0002C8 13.4698 8.21071 12.9611 8.58579 12.586C8.96086 12.2109 9.46957 12.0002 10 12.0002H14C14.5304 12.0002 15.0391 12.2109 15.4142 12.586C15.7893 12.9611 16 13.4698 16 14.0002V20.0002H20V10.9802C20.0004 10.8275 19.9658 10.6767 19.8989 10.5394C19.832 10.4021 19.7345 10.282 19.614 10.1882L12 4.26821V4.26621Z" fill="white"/>
		</svg>
    </StyleDiv>
  );
};

export default Icon;
