import { Text } from '@pancakeswap/uikit'
import ButtonOutline from 'components/Layout/ButtonOutline'
import { Flex } from 'components/Pancake-uikit'
import React from 'react'
import styled from 'styled-components'

interface Props {
  data?: any,
  OptionType?: string,
  onSelect?: () => void
}

const CardItemDiv = styled.div<{
  padding?: string
}>`
  padding: ${({ padding }) => padding};
  width: 100%;
  padding-bottom:15px;
  margin-bottom:15px;
  border-bottom: 1px solid rgba(255,255,255,30%) ;
`
const CardItemInner = styled.div`
  font-size:16px;
  color:#2F2E41;
  display: flex;
  justify-content: space-between;
  align-items: center ;
  :hover{
    h4,h5{
      color: #E5A95A;
    }
  }
  .select-button{
    display: flex;
    justify-content: end;
  }
`
const CardItemImage = styled.div`  
  img{
    max-width: 50px;
    width: 50px;
    height:65px;
    border-radius:5px;
  }
  position:relative;
  .mask-image{
    mask-image: url('/images/mask-piece.png');
    mask-repeat: no-repeat;
    mask-size:80% ;
    mask-position: center;   
  }
  .border-mask{
    position:absolute ;   
    width: 40px;
    height:54px;
    border-radius:0px ;
  }
`
const stylesImg = {
  borderRadius: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}

const CardItemNumber = styled.div<{
  backgroundColor?: string
}>` 
  height: 24px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color:#fff;
  font-weight: 400;
  margin-left:10px;
`


const CardInfo = styled.div`
  flex:1;
  padding-left: 15px;
 .header-inf {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4{
    font-size: 14px;
    color:#fff;
  }
  }
`

const CardItemMobile: React.FunctionComponent<Props> = ({ data, onSelect }) => {
  const { name, level, qty, star, icon, } = data
  return (
    <CardItemDiv padding="0 15px">
      <CardItemInner>
      <CardItemImage
            style={
              (data?.type === "pet_pieces" && data?.branch === 'Fire') ? {
                background: 'linear-gradient( 172deg,#8d0808 0%,#eddcdc 137.5%)',
               ...stylesImg
              } : (data?.type === "pet_pieces" && data?.branch === "Water") ?
                {
                  background: 'linear-gradient( 172deg,#2175bd 0%,#ebe6da 137.5%)',
                 ...stylesImg
                } : (data?.type === "pet_pieces" && data?.branch === "Earth") ? {
                  background: 'linear-gradient( 172deg,#996b41 0%,#ebe6da 137.5%)',
                 ...stylesImg
                } : (data?.type === "pet_pieces" && data?.branch === "Metal") ? {
                  background: 'linear-gradient( 172deg,#ffff01 0%,#ebe6da 137.5%)',
                 ...stylesImg
                } : (data?.type === "pet_pieces" && data?.branch === "Wood") ? {
                  background: 'linear-gradient( 172deg,#094228 0%,#baffde 137.5%)',
                 ...stylesImg
                } : null
            }
          >
            <div className={data?.type === 'pet_pieces' ? 'mask-image' : ''}><img src={icon} alt="img-icon" /></div>
            {data?.type === 'pet_pieces' && <img src='/images/border-piece.png' alt="pet" className='border-mask' />}
        </CardItemImage>
        <CardInfo>
          <div className="header-inf">
            <h4>{name}</h4>
            {level > 0 && <Text as="span" color="rgba(255, 255, 255, 0.6)" fontSize="16px" fontWeight="400">Level
              <Text as="span" color="#fff" fontSize="16px" fontWeight="400" ml="10px">{level}</Text></Text>}
          </div>
          <Flex justifyContent="space-between">
            <div>
              <Text as="span" color="rgba(255, 255, 255, 0.6)" fontSize="16px" fontWeight="400">Boosted </Text>
              <CardItemNumber>{data?.boosted}</CardItemNumber>
            </div>
            <div>
              <Text as="span" color="rgba(255, 255, 255, 0.6)" fontSize="16px" fontWeight="400">Hashrate</Text>
              <CardItemNumber>{data?.hashrate}</CardItemNumber>
            </div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>
              <Text as="span" color="rgba(255, 255, 255, 0.6)" fontSize="16px" fontWeight="400" mr="10px">
                Quantity
              </Text>
              <Text as="span" color="#fff" fontSize="16px" fontWeight="400">{qty}</Text>
            </div>
            <div>
              <Text as="span" color="rgba(255, 255, 255, 0.6)" fontSize="16px" fontWeight="400" mr="10px">
                Skill level
              </Text>
              <Text as="span" color="#fff" fontSize="16px" fontWeight="400">{data.skill_level}</Text>
            </div>
          </Flex>
          <Flex justifyContent="space-between">
            <div>
              <Text as="span" color="rgba(255, 255, 255, 0.6)" fontSize="16px" fontWeight="400" mr="10px">
                Star
              </Text>
              <Text as="span" color="#fff" fontSize="16px" fontWeight="400">{star}</Text>
            </div>
            <div className="select-button">
              <ButtonOutline onClick={data?.qty > 0 && !data?.airdrop && data.type === 'pets' ? onSelect : null} width="82px" fontSize="16px" height="40px" color="#fff"
                style={data?.qty > 0 && !data?.airdrop && data.type === 'pets' ? { marginTop: 10 } : { marginTop: 10, opacity: 0.5 }}
              >
                Select
              </ButtonOutline>
            </div>
          </Flex>
        </CardInfo>
      </CardItemInner>
    </CardItemDiv>
  )
}

export default CardItemMobile