import React from "react";
import styled from 'styled-components';
import { SvgProps } from "../../../components/Svg/types";

const StyleDiv = styled.div`
  padding-right:5px
`
const Icon: React.FC<SvgProps> = (props) => {
  return (
    <StyleDiv>
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 8.99994H17L15.4 10.1999C15.2949 10.2787 15.2064 10.3775 15.1395 10.4905C15.0726 10.6034 15.0286 10.7285 15.0101 10.8585C14.9915 10.9885 14.9987 11.1209 15.0313 11.2481C15.0639 11.3753 15.1212 11.4949 15.2 11.5999C15.2931 11.7241 15.4139 11.8249 15.5528 11.8944C15.6916 11.9638 15.8448 11.9999 16 11.9999C16.2164 11.9999 16.4269 11.9298 16.6 11.7999L20.6 8.79994C20.7223 8.70662 20.8214 8.58634 20.8897 8.44845C20.9579 8.31056 20.9934 8.15879 20.9934 8.00494C20.9934 7.8511 20.9579 7.69933 20.8897 7.56144C20.8214 7.42355 20.7223 7.30327 20.6 7.20994L16.74 4.20994C16.5305 4.04684 16.2647 3.97364 16.0013 4.00646C15.7378 4.03928 15.4981 4.17542 15.335 4.38495C15.1719 4.59447 15.0987 4.8602 15.1315 5.12369C15.1643 5.38718 15.3005 5.62684 15.51 5.78994L17.08 6.99994H4C3.73478 6.99994 3.48043 7.1053 3.29289 7.29284C3.10536 7.48037 3 7.73473 3 7.99994C3 8.26516 3.10536 8.51952 3.29289 8.70705C3.48043 8.89459 3.73478 8.99994 4 8.99994ZM20 15.9999H7L8.6 14.7999C8.81217 14.6408 8.95244 14.4039 8.98995 14.1414C9.02746 13.8788 8.95913 13.6121 8.8 13.3999C8.64087 13.1878 8.40397 13.0475 8.14142 13.01C7.87887 12.9725 7.61217 13.0408 7.4 13.1999L3.4 16.1999C3.27769 16.2933 3.17857 16.4136 3.11034 16.5514C3.04211 16.6893 3.00661 16.8411 3.00661 16.9949C3.00661 17.1488 3.04211 17.3006 3.11034 17.4384C3.17857 17.5763 3.27769 17.6966 3.4 17.7899L7.26 20.7899C7.43455 20.9254 7.64905 20.9993 7.87 20.9999C8.02272 20.9996 8.17332 20.9642 8.31026 20.8966C8.4472 20.829 8.56684 20.731 8.66 20.6099C8.82239 20.4015 8.89567 20.1372 8.86381 19.8749C8.83196 19.6125 8.69757 19.3735 8.49 19.2099L6.92 17.9999H20C20.2652 17.9999 20.5196 17.8946 20.7071 17.7071C20.8946 17.5195 21 17.2652 21 16.9999C21 16.7347 20.8946 16.4804 20.7071 16.2928C20.5196 16.1053 20.2652 15.9999 20 15.9999Z" fill="white"/>
</svg>
    </StyleDiv>
  );
};

export default Icon;
