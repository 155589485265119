import React, { useEffect, useRef, useState } from 'react'

const useCountdown = (start: number) => {
  const [counter, setCounter] = useState(start)

  const clearTimer = useRef(null)

  useEffect(() => {
    if (counter === 0) {
      return
    }
    clearTimer.current = setTimeout(() => {
      setCounter(counter - 1)
    }, 1000)
    
  }, [counter])

  const _minutes = Math.floor(counter / 60)
  const _seconds = Math.floor(counter % 60)

  const _onReset = (value) => {   
    clearTimeout(clearTimer.current)
    setCounter(value)
  }

  return { minutes: _minutes, seconds: _seconds, onReset: (value) => _onReset(value) }
}

export default useCountdown
