import styled from 'styled-components'

const ButtonOutline = styled.button<{
  fontSize?: string,
  color?: string,
  height?: string,
  width?: string,
  border?: string
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border: 1px solid ${({ theme, border }) => (border ? theme.colors.primary : '#fff')};
  max-width: 100%;
  border-radius: 10px;
  outline: 0;
  padding: 0;
  cursor: pointer;
  background-color: rgba(0,0,0,.5);
  transition: all .2s linear;

  &:hover{
    border: 1px solid ${({ theme, border }) => (border ? '#fff' : theme.colors.primary)};
  }
`
export default ButtonOutline
