import { useEffect, useState } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { getApi } from 'utils/apiHelper'

const useMyNFT = () => {
  const { account, chainId } = useActiveWeb3React()
  const [listItem, setListItem] = useState(null)
  const [countItem, setCountItem] = useState(0)
  const [isReload, setIsReload] = useState(false)

  useEffect(() => {
    async function getPrice() {
      getApi(`/my_items?chain_number=${chainId}`)
        .then((resp) => {
          if (resp?.data?.data?.items) {
            const _listItem = resp.data.data.items
            const _counterItems =
              (_listItem?.pets?.length > 0
                ? _listItem?.pets.reduce((acc, cur) => {
                  return acc + cur.qty
                }, 0)
                : 0) +
              (_listItem?.tickets?.length > 0
                ? _listItem?.tickets?.reduce((acc, cur) => {
                  return acc + cur.qty
                }, 0)
                : 0) +
              (_listItem?.gems?.length > 0
                ? _listItem?.gems?.reduce((acc, cur) => {
                  return acc + cur.qty
                }, 0)
                : 0) +
              (_listItem?.charms?.length > 0
                ? _listItem?.charms?.reduce((acc, cur) => {
                  return acc + cur.qty
                }, 0)
                : 0) +
              (_listItem?.pet_pieces?.length > 0
                ? _listItem?.pet_pieces?.reduce((acc, cur) => {
                  return acc + cur.qty
                }, 0)
                : 0) +
              (_listItem?.pet_souls?.length > 0
                ? _listItem?.pet_souls?.reduce((acc, cur) => {
                  return acc + cur.qty
                }, 0)
                : 0)
            setListItem(_listItem)
            setCountItem(_counterItems)
          }
        })
        .catch((error) => {
          console.log('@PopupNft--', error)
          setListItem(null)
        })
    }
    if (account) {
      getPrice()
    }
  }, [isReload, account, chainId])

  return { listItem, countItem, onReload: () => setIsReload(!isReload) }
}

export default useMyNFT
