import { DEFAULT_GAS_LIMIT, DEFAULT_GAS_NFT_LIMIT } from 'config'
import getGasPrice from 'utils/getGasPrice'

const optionsNFT = {
  gasLimit: DEFAULT_GAS_NFT_LIMIT,
}
const optionsToken = {
  gasLimit: DEFAULT_GAS_LIMIT,
}
const optionsUnstakeNFT = {
  gasLimit: 4000000,
}

const gasPrice = getGasPrice()

export const stakeMiningNFTNew = async (mowaMinningNftContract, pid, uid, _tokenId) => {
  const tx = await mowaMinningNftContract.depositNFT(pid, uid, _tokenId, { ...optionsNFT, gasPrice })
  const receipt = await tx.wait()
  return [receipt.status, tx.hash]
}

export const unstakeMinningNFTNew = async (mowaMinningNftContract, pid, uid) => {
  const tx = await mowaMinningNftContract.unStake(pid, uid, { ...optionsUnstakeNFT, gasPrice })
  const receipt = await tx.wait()
  return [receipt.status, tx.hash]
}

export const upgradeMinningNFTNew = async (mowaMinningNftContract, pid, uid, number) => {
  const tx = await mowaMinningNftContract.upgrade(pid, uid, number, { ...optionsToken, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

// disposit farm nft
export const harvestMiningNftNew = async (mowaMinningNftContract, pid) => {
  const tx = await mowaMinningNftContract.harvest(pid,{ ...optionsToken, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}
