import React, { useState } from 'react'
import styled from 'styled-components'
import { ButtonMenu, ButtonMenuItem, NotificationDot, Text, Flex, Button } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'


const Header: React.FC = () => {
  const { t } = useTranslation()
  return (<WapperHeader>
    <div className="title-l">
      <Text fontSize="60px" color="#fff" fontWeight={400} style={{ lineHeight: 1 }}>{t('NFT Mining V1')}</Text>
      <Text fontSize="14px" fontWeight={400} color="rgba(255,255,255,0.6)">{t('Stake NFT to earn MOWA.')}</Text>
    </div>
    {/* <div className="title-r">
      <Text fontWeight={400} color="#fff">MoniWar NFT Pool Amount (MOWA) ≈ $4,522,808</Text>
      <TextNumberPool>1,290,060,469,771</TextNumberPool>
    </div> */}
  </WapperHeader>
  )
}

export default Header

const WapperHeader = styled('div')`  
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  div.title-l {
    margin-bottom:15px;
  }
  div.title-r {
    margin-bottom:15px;
    ${({ theme }) => theme.mediaQueries.sm} {
      margin-bottom:15px;
      text-align:right;
    }
  }

`

const TextNumberPool = styled(Text)`
  font-size:34px;
  font-weight:700;
  background: #BF893D;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(40%, #BF893D), to(#FFD493));
  background: linear-gradient(to top, #BF893D 40%, #FFD493 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`