export const multichainConfig = {
  97: {
    id: 97,
    name: 'BNB Chain Testnet',
    network: 'bsc-testnet',
    nativeCurrency: { decimals: 18, name: 'BNB', symbol: 'BNB' },
    rpcUrl: 'https://data-seed-prebsc-1-s2.binance.org:8545/',
    blockExplorer: 'https://testnet.bscscan.com',
    testnet: true,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
    protocol_pet: 'ERC-721',
    protocol_item: 'ERC-1155'
  },
  56: {
    id: 56,
    name: 'BNB Chain',
    network: 'bsc',
    rpcUrl: 'https://binance.nodereal.io',
    blockExplorer: 'https://bscscan.com',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    testnet: false,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
    protocol_pet: 'ERC-721',
    protocol_item: 'ERC-1155'
  },
  43114: {
    id: 43114,
    name: 'Avalanche C-Chain',
    network: 'avalanche',
    rpcUrl: 'https://rpc.ankr.com/avalanche',
    nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
    blockExplorer: 'https://snowtrace.io',
    testnet: false,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
    protocol_pet: '',
    protocol_item: ''
  },
  43113: {
    id: 43113,
    name: 'Avalanche Testnet',
    network: 'avalanche-fuji',
    rpcUrl: 'https://rpc.ankr.com/avalanche_fuji',
    nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 },
    blockExplorer: 'https://testnet.snowtrace.io',
    testnet: true,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
    protocol_pet: '',
    protocol_item: ''
  },
  52: {
    id: 52,
    name: 'CSC Mainnet',
    network: 'csc-mainnet',
    rpcUrl: 'https://rpc.coinex.net',
    nativeCurrency: { name: 'CSC', symbol: 'CET', decimals: 18 },
    blockExplorer: 'https://www.coinex.net',
    testnet: false,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2941.png',
    protocol_pet: 'CRC-721',
    protocol_item: 'CRC-1155'
  },
  53: {
    id: 53,
    name: 'CSC Testnet',
    network: 'csc-testnet',
    rpcUrl: 'https://testnet-rpc.coinex.net',
    nativeCurrency: { name: 'CSC', symbol: 'tCET', decimals: 18 },
    blockExplorer: 'https://testnet.coinex.net',
    testnet: true,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2941.png',
    protocol_pet: 'CRC-721',
    protocol_item: 'CRC-1155'
  },
  137: {
    id: 137,
    name: 'Polygon Chain',
    network: 'polygon-mainnet',
    rpcUrl: 'https://polygon-rpc.com',
    nativeCurrency: { name: 'MATIC', symbol: 'MATIC', decimals: 18 },
    blockExplorer: 'https://polygonscan.com/',
    testnet: false,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
    protocol_pet: '',
    protocol_item: ''
  },
  1975: {
    id: 1975,
    name: "ONUS Chain",
    network: 'ONUS Chain',
    nativeCurrency: {
      name: "ONUS",
      symbol: "ONUS",
      decimals: 18
    },
    rpcUrl: "https://rpc.onuschain.io/",
    blockExplorer: "https://explorer.onuschain.io/",
    testnet: false,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15261.png',
    protocol_pet: 'ERC-721',
    protocol_item: 'ERC-1155'
  },
  1945: {
    id: 1945,
    name: "ONUS Testnet",
    network: 'ONUS Chain',
    nativeCurrency: {
      name: "ONUS",
      symbol: "ONUS",
      decimals: 18
    },
    rpcUrl: "https://rpc-testnet.onuschain.io/",
    blockExplorer: "https://explorer-testnet.onuschain.io/",
    testnet: true,
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/15261.png',
    protocol_pet: 'ERC-721',
    protocol_item: 'ERC-1155'
  },
}


export const GAS_GWEI = {
  56: {
    gasWei: 5,
    symbol: 'BNB'
  },
  97: {
    gasWei: 10,
    symbol: 'BNB'
  },
  52: {
    gasWei: 500,
    symbol: 'CET'
  },
  53: {
    gasWei: 500,
    symbol: 'CET'
  },
  137: {
    gasWei: 0,
    symbol: 'MATIC'
  },
  43114: {
    gasWei: 0,
    symbol: 'AVAX'
  },
  43113: {
    gasWei: 0,
    symbol: 'AVAX'
  },
  1945: {
    gasWei: 0,
    symbol: 'ONUS'
  },
  1975: {
    gasWei: 0,
    symbol: 'ONUS'
  },
}

