import React from 'react'
import styled from 'styled-components'
import { SvgProps } from '../../../components/Svg/types'

const StyleDiv = styled.div`
  padding-right: 5px;
`
const Icon: React.FC<SvgProps> = (_props) => {
  return (
    <StyleDiv>
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.5 20H6.1C4.9 20 4 19.1 4 17.9V4.1C4 2.9 4.9 2 6.16 2H17.5C17.62 2 17.74 2.06 17.8 2.18C17.86 2.3 17.8 2.42 17.74 2.48C16.9 3.32 16.9 6.02 17.74 6.86C17.74 6.86 17.8 6.92 17.8 6.98C17.8 7.04 17.8 7.04 17.8 7.1V19.7C17.8 19.88 17.68 20 17.5 20ZM4.6 6.8V17.9C4.6 18.8 5.2 19.4 6.1 19.4H17.2V7.4H6.1C5.5 7.4 4.96 7.16 4.6 6.8ZM6.16 2.6C5.26 2.6 4.6 3.26 4.6 4.1V5.3C4.6 6.2 5.2 6.8 6.1 6.8H16.9C16.3 5.66 16.3 3.74 16.9 2.6H6.16Z"
          fill="black"
        />
        <path
          d="M16.5998 4.99984H6.0998C5.9198 4.99984 5.7998 4.87984 5.7998 4.69984C5.7998 4.51984 5.9198 4.39984 6.0998 4.39984H16.5998C16.7798 4.39984 16.8998 4.51984 16.8998 4.69984C16.8998 4.87984 16.7798 4.99984 16.5998 4.99984Z"
          fill="black"
        />
        <path
          d="M13.9001 17.0002H7.9001C7.7201 17.0002 7.6001 16.8802 7.6001 16.7002C7.6001 14.9002 8.4401 12.8002 10.9001 12.8002C13.3601 12.8002 14.2001 14.9002 14.2001 16.7002C14.2001 16.8802 14.0801 17.0002 13.9001 17.0002ZM8.2001 16.4002H13.6001C13.5401 14.9002 12.7601 13.4002 10.9001 13.4002C9.0401 13.4002 8.3201 14.9002 8.2001 16.4002Z"
          fill="black"
        />
        <path
          d="M10.8998 13.3998C9.7598 13.3998 8.7998 12.4398 8.7998 11.2998C8.7998 10.1598 9.7598 9.19983 10.8998 9.19983C12.0398 9.19983 12.9998 10.1598 12.9998 11.2998C12.9998 12.4398 12.0398 13.3998 10.8998 13.3998ZM10.8998 9.79983C10.0598 9.79983 9.3998 10.4598 9.3998 11.2998C9.3998 12.1398 10.0598 12.7998 10.8998 12.7998C11.7398 12.7998 12.3998 12.1398 12.3998 11.2998C12.3998 10.4598 11.7398 9.79983 10.8998 9.79983Z"
          fill="black"
        />
      </svg>
    </StyleDiv>
  )
}

export default Icon
