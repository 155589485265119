import { createSlice } from '@reduxjs/toolkit'

const isProduction = process.env.REACT_APP_ENV === 'production'

const initialState = {
  tabSelect: 'network',
  fromToken: {
    id: 0,
    name: '',
    image: '',
    keyname: ''
  },
  toToken: {
    id: isProduction ? 52 : 53,
    name: isProduction ? 'CSC Mainnet' : 'CSC Testnet',
    image: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2941.png',
    keyname: 'csc'
  },
  selectToken: {
    id: 0,
    name: '',
    image: ''
  },
  receivingAdd: '',
  amountValue: 0
}

export const tokenBridgeSlice = createSlice({
  name: 'TokenBridge',
  initialState,
  reducers: {
    onTabSelect: (state, action) => {
      state.tabSelect = action.payload
    },
    onGetFromToken: (state, action) => {
      state.fromToken.id = action.payload.id
      state.fromToken.name = action.payload.name
      state.fromToken.image = action.payload.image
      state.fromToken.keyname = action.payload.keyname

    },
    onGetToToken: (state, action) => {
      state.toToken.id = action.payload.id
      state.toToken.name = action.payload.name
      state.toToken.image = action.payload.image
      state.toToken.keyname = action.payload.keyname
    },
    onGetSelecttoken: (state, action) => {
      state.selectToken = action.payload
    },
    onReceivingAdd: (state, action) => {
      state.receivingAdd = action.payload
    },
    onGetAmountValue: (state, action) => {
      state.amountValue = action.payload
    },

  },

})

// Actions
export const {
  onTabSelect,
  onGetFromToken,
  onGetToToken,
  onGetSelecttoken,
  onReceivingAdd,
  onGetAmountValue
} = tokenBridgeSlice.actions


export default tokenBridgeSlice.reducer
